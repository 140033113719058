.email-previewer{
    height: 80vh;
}
.rdt_TableHeader .sc-fotOHu.gIaANo {
    background: #54ab1a;
    color: #fff;
}
.rdt_TableCell {
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #454545;
    letter-spacing: 0.1px;
}
#root .rdt_Pagination {
    color: #54ab1a;
    font-size: 15px;
    font-weight: 600;
}
.empty-template h3 {
   
    font-weight: 700;
    font-size: 23px;
    color: #54ab1a;
}
input.form-group.subject::placeholder {
    color: #454545;
}
.react-responsive-modal-modal {
    border: 4px solid #54ab1a;
    border-radius: 2px;
}
.rdt_TableCell input[type="checkbox"], input[type="radio"] {
    display: block;
    z-index: 1;
}
.rdt_TableHead .rdt_TableHeadRow {
    background-color: #54ab1a;
    min-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}
.email-template .subject:focus {
    border: 1px solid hsl(0, 0%, 80%) !important;
    color: #fff !important;
    font-weight: 700;
}
.empty-template{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

*:focus-visible
{
    outline: 0 !important;
}
p.select-template {
    font-size: 18px;
    margin-bottom: 10px;
    color: #54ab1a;
    font-family: 'Open Sans';
    font-weight: 700;
}
div#emailTemplate {
    padding: 1.5em 0;
}
.spamscore {
    padding: 1em 0;
    color: #454545; 
    font-weight: 600;
}
.rdt_TableHead .rdt_TableHeadRow svg {
    fill: #fff;
    font-weight: 700;
}
.rdt_TableHeader div {
    font-weight: 700;
}
.rdt_TableBody .rdt_TableRow {
    background: #ececec36;
    padding: 0;
    min-height: 40px;
}
.nospam {
    color: #454545;
    font-weight: 600;
    font-size: 17px; 
}
input.form-group.subject::placeholder {
    color: #fff;
}
input.form-group.subject { background-color:#54ab1a; color: #fff;
    font-weight: 700; }
.nospam span {color: #54ab1a; font-weight: 700;}
.spamscore span {
    border: 5px solid #ffa844;
    padding: 15px 11px;
    border-radius: 50%;
    color: #54ab1a;
    font-size: 19px;
    font-weight: 700;
}
.sendEmail button{
    padding: 10px;
        background: #ffa844 !important;
        color: #fff;
        border: none;
        border-radius: 8px;
        font-weight: bold;
}
.contact-sendEmail{
    position: relative;
    right: -91%;
    margin-top: 30px;
}

.css-26l3qy-menu, .css-26l3qy-menu div{
    color: #000;
    background: #fff;
}
.email-template .form-group{
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 9px;
}

.sendEmail .sendEmail{
    margin-bottom: 20px !important;
}
.title-right {
    float: right;
    width: 37%;
    text-align: right;
}
.service-type, .service-type:focus {
    width: 100% !important;
    padding: 3px  !important;
    margin: 10px 0  !important;
    background: #FFF  !important;
    border: 1px solid #ccc  !important;
}
.report-group.report-lists.services .container {
    padding: 0 !important;
}


.report-group .title{
    margin: 20px 0;
}

.report-group .card-title {
    text-align: center;
    
    display: block;
    margin-bottom: 2em;
    margin-top: 1em;
}
.report-group .card-title h3 {
    font-size: 25px;
    font-weight: 300;
    display: inline-block;
    border-bottom: 2px solid #57ab1a;
}
.report-group.report-lists .container {
    max-width: 1140px; box-shadow: unset;
    padding: 15px 2em;
    
}

.report-group.report-lists tr td a {
    font-weight: 600;
    color: blue;
}

.report-group.report-lists .container {
    max-width: 1140px; box-shadow: unset;
    padding: 15px 2em;
    
}
.report-lists .cost-table tr td, .report-lists .cost-table th {
    border: 0px solid #ccc;
}
.report-lists table th {
    background: #57ab1a;
    padding: 3px 10px;
    color: #fff;
}
.report-lists table tr:nth-child(odd) {
    background: #c5dcc57a;
}
.report-lists table tr:nth-child(even) {
    background: #f0f0f0ba;
}
.report-lists .cost-table tr td, .cost-table th {
    border: 0px solid #ccc;
}
.report-lists table td {
    padding: 5px 10px;
}
.report-group.report-lists tr td a {
    font-weight: 600;
    color: blue;
}




.checkbox-report{
    position:relative;
    cursor:pointer;
    appearance:none;
    display: block !important;
    width:80px;
    height:40px;
    border-radius:20px;
    border:2px solid #ccc;
    outline:none;
    transition:0.3s;
  }
  .checkbox-report::before{
    content:"";
    position:absolute;
    height:20px;
    width:20px;
    border-radius:50%;
    background:#ccc;
    top:3px;
    left:4px;
    transition:0.3s ease-in-out;
  }
  .checkbox-report:checked::before{
    transform:translateX(37px);
    background:#3399ff;
  }
  .checkbox-report:checked{
    border-color:#3399ff;
  }


.title-right h1 {
    font-size: 20px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* .title-right span {
    font-size: 40px;
    background: #57ab1a;
      padding: 0 10px;
  } */

  .team-title {
    padding: 0 !important;
    margin: 0 !important;
  }

  .team-title h2 {
    color: #000 !important;
    font-size: 19px !important;
  }

  .team-role, .team-role:focus {
    width: 100% !important;
    padding: 7px 14px !important;
    background: #fff !important;
    border: 1px solid #c1bbbb !important;
    border-radius: 4px !important;
  }

  .team-model .MuiDialogActions-root{
    justify-content: space-between;
  }

  .team-model .MuiDialogActions-root .lp-btns{
    border-radius: 7px !important;
    padding: 5px 28px !important;
  }

  .team-role {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
#email-exists{
    display: none;
    font-size: 13px;
    color: red;
    margin-top: 5px;
    text-align: left;
}
#password-exists, #password-exists-edit{
  display: none;
  font-size: 13px;
  color: red;
  margin-top: 5px;
  text-align: left;
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #57ab1a;
    box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
    color: #fff;
}
.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}
.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}

.custom-ui p {
    color: #fff;
}
.report-options input{
  display: inline-block !important;
    z-index: 0 !important;
    position: relative !important;
    top: 11px;
    width: 18px !important;
    right: 10px;
}

.form-control-option {
    display: block;
    width: 100%;
    height: 19px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.user-form-control:focus {
  border: 1px solid #ccc !important;
}

.phone  input.form-control {
  border-radius: 0px !important;
}


.phone .flag-dropdown, .phone .selected-flag {
  border-radius: 1px !important;
}
.phone .react-tel-input {
  box-shadow: none;
}
.phone input.form-control:focus {
  border: 1px solid #CACACA !important;
}
.phone .react-tel-input .country-list {
  text-align: left;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-Thin.eot');
    src: local('Geomanist Thin'), local('Geomanist-Thin'),
        url('../fonts/Geomanist-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Thin.woff2') format('woff2'),
        url('../fonts/Geomanist-Thin.woff') format('woff'),
        url('../fonts/Geomanist-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-Black.eot');
    src: local('Geomanist Black'), local('Geomanist-Black'),
        url('../fonts/Geomanist-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Black.woff2') format('woff2'),
        url('../fonts/Geomanist-Black.woff') format('woff'),
        url('../fonts/Geomanist-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal; font-display: swap;
} 

@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-Medium.eot');
    src: local('Geomanist Medium'), local('Geomanist-Medium'),
        url('../fonts/Geomanist-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Medium.woff2') format('woff2'),
        url('../fonts/Geomanist-Medium.woff') format('woff'),
        url('../fonts/Geomanist-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-Bold.eot');
    src: local('Geomanist Bold'), local('Geomanist-Bold'),
        url('../fonts/Geomanist-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Bold.woff2') format('woff2'),
        url('../fonts/Geomanist-Bold.woff') format('woff'),
        url('../fonts/Geomanist-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist Book';
    src: url('../fonts/Geomanist-Book.eot');
    src: local('Geomanist Book'), local('Geomanist-Book'),
        url('../fonts/Geomanist-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Book.woff2') format('woff2'),
        url('../fonts/Geomanist-Book.woff') format('woff'),
        url('../fonts/Geomanist-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist Ultra';
    src: url('../fonts/Geomanist-Ultra.eot');
    src: local('Geomanist Ultra'), local('Geomanist-Ultra'),
        url('../fonts/Geomanist-Ultra.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Ultra.woff2') format('woff2'),
        url('../fonts/Geomanist-Ultra.woff') format('woff'),
        url('../fonts/Geomanist-Ultra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-Regular.eot');
    src: local('Geomanist Regular'), local('Geomanist-Regular'),
        url('../fonts/Geomanist-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Regular.woff2') format('woff2'),
        url('../fonts/Geomanist-Regular.woff') format('woff'),
        url('../fonts/Geomanist-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-ExtraLight.eot');
    src: local('Geomanist ExtraLight'), local('Geomanist-ExtraLight'),
        url('../fonts/Geomanist-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-ExtraLight.woff2') format('woff2'),
        url('../fonts/Geomanist-ExtraLight.woff') format('woff'),
        url('../fonts/Geomanist-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal; font-display: swap;
} 

@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/Geomanist-Light.eot');
    src: local('Geomanist Light'), local('Geomanist-Light'),
        url('../fonts/Geomanist-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geomanist-Light.woff2') format('woff2'),
        url('../fonts/Geomanist-Light.woff') format('woff'),
        url('../fonts/Geomanist-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal; font-display: swap;
}


body { font-weight: 500 !important; color: #000 !important;}
html {
    scroll-behavior: smooth;
  }
  .type-common{
    text-transform: uppercase;
    color: #ffa844;
        font-weight: 700;
        font-size: 30px;
    }
p { font-size: 18px; }
.logo-lp img, .logo-header img{ 
    padding: 5px 0; }
.header {background:#54ab1a;box-shadow: 0 5px 8px -2px #a7a0a0;}
.social-profiles ul li a {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 0 10px;
}
.header { padding: 5px 0; }
.social-profiles ul li a i {
    margin-right: 6px;
    font-size: 22px;
}
.social-profiles {
    padding: 0;
    text-align: right;
}
.hero-banner.banner-bg { position: relative; padding: 3em 0; text-align: center; }
.hero-banner::before {
    position: absolute;
    background: url(https://headnurture.com/banner-left.png);
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: auto;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 290px;
    z-index: -1;
    top: -41px;pointer-events: none;
    bottom: auto;
}
.banner-buttons a {
    margin-left: 10px !important;
    margin-right: 10px !important;
    
}
.hero-banner::after {
    position: absolute;
    background: url(https://headnurture.com/banner-right.png);
    content: "";
    width: 100%;
    height: 100%;
    left: auto;
    right: 0;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 400px;
    z-index: -1;
    top: -4em;
    bottom: auto;pointer-events: none;
}
h2 { color: #454545; }
.hero-banner h1 {
    font-weight: 700;
    border: 2px solid #ffaf8d;
    display: inline-block;
    padding: 5px 10px;
}
.hero-banner h2 {
    font-size: 30px;
    font-weight: 700;padding: 0.3em 0; margin: 0;border-bottom: 0;
text-transform: capitalize; color: #454545;
}
.dashboard-image {
    padding-top: 4em;
}
.social-icon ul li {
    display: inline-block;
}
.dashboard-image img {
    max-width: 700px;
    margin: 0 auto 1.5em;
    display: block; width: 100%;
}
a.lp-btns, .lp-btns {
    /*box-shadow: 0px 6px 0px #f5a27d, 0px 3px 15px rgba(0,0,0,.4);*/
    font-size: 18px;
    color: #fff;
    background:#54ab1a;
    padding: 10px 30px;
    border-radius: 30px;
    font-weight: 700;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-position: center bottom !important;
    display: inline-block;
    margin: 2em 0;border: 0 !important;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;overflow: hidden;

}
.lp-btns:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.lp-btns:hover:before{
    -webkit-transform: scaleX(1);
    transform: scaleX(1);background-color: #ffa844;
}
.dentist-issues {
    background: #fff;border-top: 1px solid #ccc;
    padding: 3em 0;
    text-align: center;box-shadow: 0 5px 8px -2px #b7b2b2;position: relative;
}
.all-issues-lists img {
    max-width: 70px; margin: 0 auto;
}
.all-issues-lists .col-md-10 {
    padding: 0;
}
.all-issues-lists h3 {
    text-align: left;
    padding: 0;
    font-weight: 700;
}
.all-issues-lists p {
    font-size: 13px;
    text-align: justify; margin: 0;
}
.all-issues-lists .col-md-2{
	align-items: center;
    justify-items: center;
    display: flex;
}
.all-issues-lists:nth-child(2n+1) {
    margin-left: 2em;
}
.all-issues-lists {
    /*flex: 1 1 48%; margin-left: 4%;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;*/
    padding: 2em 1em 2em 1em;    
    background: #fff; float: left;
    margin: 1em auto;
    border-radius: 100px; max-width: 800px;border: 0px solid #fff; background: #ececec
    
}
.all-issues-lists:nth-child(2), .all-issues-lists:nth-child(4) {
    float: right;
    display: block;padding: 2em 3em 2em 3em;
}
.all-issues {
    text-align: center; max-width: 1000px; margin: 0 auto;padding-top: 1em;
}
.all-issues i {
    color: #57ab1b;
    font-size: 45px;
}


.wrapper h2 {
	font-weight: 700;
    font-size: 34px;
    /*border-bottom: 4px solid#f9a743;*/
    display: inline-block;
    padding-bottom: 20px; color: #54ab1a
}
.dentist-issues h2 {
    color: #54ab1a;
}
.dental-business img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: right;
    float: right;
}
.dental-business p {
    font-size: 16px;
    text-align: justify;
}
.dental-business {
    padding: 4em 0; position: relative;
}
.dental-business iframe {
    border: 9px solid#54ab1a;
    float: right;
    border-radius: 7px;
}
.dentist-issues::before {
    background: url(https://headnurture.com/dentist-issues.png);
    position: absolute;
    content: none;
    width: 100%;
    height: 100%;
    top: -14em;
    background-repeat: no-repeat;
    background-position: right top;
    bottom: auto;
    background-size: 300px;
    left: auto;pointer-events: none;
    right: -50px;
}
.dental-business h3 { 
    font-size: 25px;
    font-weight: 700;
    border-bottom: 4px solid#f9a743;
    padding-bottom: 10px;    
    text-align: center;
    width: auto;
    align-items: center;
    justify-content: center;
    float: none;
    margin: 0 auto 10px;
}
.advantages-group {
    background: #f8f8f8;box-shadow: 0 5px 8px -2px #b7b2b2;
    padding: 3em 0; position: relative;text-align: center;z-index: 999;
}
.advantages-group::before{
    background: url(https://headnurture.com/choice.png);
    position: absolute;
    content: none;
    width: 100%;
    height: 100%;
    top: -4em;
    background-repeat: no-repeat;
    background-position: left top;
    bottom: auto;
    background-size: 300px;
    left: 0;
    right: auto;z-index: -1;pointer-events: none;
}
.advantages-group::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: auto;
    right: -25px;
    background: url(https://headnurture.com/dentist-issues.png);
    background-repeat: no-repeat;
    top: auto;
    bottom: -12em;
    background-position: right bottom;
    background-size: 260px;pointer-events: none;
}
.advantages-list {
    display: flex;
    flex-wrap: wrap;padding: 1.5em 0 0;
}
.advantages-lists:nth-child(3n+1) {
    margin-left: 0;
}
.advantages-lists {
    flex: 1 1 25%;
    margin-left: 4%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; margin-bottom: 3em;
}
.advantages-lists h3 {
    font-size: 20px;
    min-height: 4em;padding: 1em 0 0;
}
.advantages-lists p {
    font-size: 16px;
}

.advantages-lists img {
    
    max-width: 100px;
    
}
.features-group .col-md-2 img {
    background:#54ab1a;
    padding: 15px 10px;
    border-radius: 5px;
    width: 80px;
    height: auto;
    object-fit: cover;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.features-group h3 {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
}
.features-group p {
    font-weight: 400;
    font-size: 16px;
    text-align: left;
}
.features-group .col-md-6 .row {
    padding: 2em 0;
}
.features-group .col-md-6 .col-md-6 img {
    width: auto;
    max-width: 550px;
}
.leadnurture-features {
    text-align: center;
    padding: 2em 0 0;
}
.features-group .col-md-6:nth-child(2) .row:nth-child(2) {
    padding-top: 0;
}

.get-in-touch {
    background: linear-gradient(to bottom,rgba(242,242,242,1) 0,rgba(255,255,255,0) 100%);
    padding: 3em 0 0;
    text-align: center;position: relative; 
}
.social-icon ul li a i {
    font-size: 30px;
}
.social-icon ul li a {
    color: #fff;
    padding: 0 10px;
}
.social-icon {
    text-align: right; 
}
.social-icon ul {
    margin: 0;
}
.get-in-touch .form-group {
    margin-bottom: 1em;
}
.get-in-touch .form-control, .get-in-touch input#example-datepicker, .get-in-touch .form-group input {
    border: 1px solid #c1bbbb !important;
    height: auto;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
}
.get-in-touch form .form-group .col-md-6 input {
    border: 1px solid #c1bbbb !important;
    height: auto;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
}
.form-group .col-md-6 {
    color: #6d6d6d;
    text-align: left;
}
input.lp-btns {
    width: 24%;
    /* background-size: cover !important; */
    padding: 8px 0;
    text-align: center;cursor: pointer;
}
input#example-datepicker {
    width: auto;
    max-width: 185px;
}
.get-in-touch textarea.form-control { height: 150px; }
.get-in-touch input#cc {
    width: auto;
    /* border-radius: 0; */
    max-width: 49px;
}
.get-in-touch::before {
    background: url(https://headnurture.com/choice.png);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: -37px;
    background-repeat: no-repeat;
    background-position: left bottom;
    bottom: auto;
    background-size: 300px;
    left: -100px;
    right: auto;
    z-index: 9;
    pointer-events: none;
}


.thank-you {
    text-align: center;
    padding: 3em 0 0;
}
.thank-you h2{
    border-bottom: 0;
}
.footer-lp {
    text-align: center;
    padding: 1em 0;
    margin: 0;
    font-size: 18px;
    color: #fff;
    background:#54ab1a!important;
}
.col-md-6.copy {
    display: flex;
    align-items: center; 
    justify-content: center;
}
.all-issues-lists:hover {
    background:#54ab1a;
    transition: 0.4s ease-in-out 0s;
}
.all-issues-lists:hover h3, .all-issues-lists:hover p { color: #fff; }
.testimonial-group .carousel.carousel-slider {
    padding: 2em 1em 3em;
    max-width: 750px;
    margin: 0 auto;
}
.testimonial-group {
    padding: 4em 0 3em;
}
.testimonial-lists p { font-size: 17px; color: #fff; font-weight: 600;}
.testimonial-lists h3 { font-size: 18px; color: #fff; font-weight: 900; font-style: italic;}
.testimonial-group .carousel .control-dots .dot{ width: 14px; height: 14px; background:#54ab1a; }
.testimonial-lists {
    border: 0px solid #ffaf8d;border-radius: 4px;
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    text-align: center;
    justify-content: center;
    padding: 3em 8px 1em; background:#54ab1a !important;
}
.testimonial-group .carousel .control-next.control-arrow:before {
    border-left: 15px solid #cf7b32;
}
.testimonial-group .carousel .control-prev.control-arrow:before {
    border-right: 15px solid #cf7b32;
}
.testimonial-group .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}
.testimonial-group .carousel .control-arrow:before, .testimonial-group .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    content: '';
}
.testimonial-group .carousel .control-prev.control-arrow {
    left: -10px;
}
.testimonial-group .carousel .control-next.control-arrow {
    right: -10px;
}


button.learn-more {
    width: auto;
    height: auto;
    flex: 0 1 20%;
}
button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}
button.learn-more .circle-lp {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 5px 0 0;
    width: 3rem;box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 3rem;
    background:#54ab1a;
    border-radius: 1.625rem;
}
button.learn-more .circle-lp .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}
button.learn-more .circle-lp .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}
button.learn-more .circle-lp .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg); font-weight: 700;
}
button:hover .circle-lp .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
}
button:hover .circle-lp {
    width: 90%;
}
button:hover span.button-text a {
    color: #fff;
}
button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 30px;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: left;
    text-transform: uppercase;
}
.banner-buttons { display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.5em 0; }

span.button-text a {
    color: #454545;
    text-transform: capitalize;
    text-align: left;
}


.email-content{
  width: 80%;
  margin: auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h3.email-content-header {
  font-size: 30px;
    font-weight: 600;
    text-align: center;color: #454545;
}
.email-content-para {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0;
    color: #454545;
}
p.elementor-image-box-description {
    margin-bottom: 3em;
}
.client-image img {
    width: 100%;
}
.result-banner h2 {
    color:#57ab1a;
    font-size: 40px;
    padding: 1em 0;
    text-align: center;
}
.result-banner .row h2 {
    font-size: 20px;
}
.result-banner .row h2 strong {
    color: #fb0;
}
.lpage .ttttt {
    text-align: right;
    float: right;
    padding: 0 2em;
}
.lpage .ttttt ul li {
    display: inline-block;
    vertical-align: initial;
}
.hero-banner.banner-bg h2, .hero-banner.banner-bg p {
    color: #000;
}
/*** new lp changes **/
.wrapper .hero-banner.banner-bg h2 {
    border: 0;margin-bottom: 0.5em;color: #5eab1c;
    font-size: 34px;
}
.all-issues .col-md-4 {
    padding: 1em 10px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    border-radius: 3px;
    background: #fff;
    max-width: 32%;
    margin: 2em auto;
}
.all-issues .col-md-6{
    padding: 1em 10px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    border-radius: 3px;
    background: #fff;
    max-width: 48%;
    margin: 2em auto;
}
.form-banner .form-group {
    background: #5eab1c;
    border-radius: 5px;
}
.form-group.row {
    background: transparent;
}
.form-banner h2 {
    font-size: 21px;
    vertical-align: text-top;
    color: #57ab1b;
}
.form-banner button.lp-btns {
    background: #f9a743;
    margin: 0;
    padding: 5px 20px;
}
.form-banner textarea.form-control {
    height: 90px;
    font-size: 16px;
}
.header-sec{
    transition: all 0.5s ease; }

.header-sec.fixed {
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 9999999;
    left: 0;
    right: 0;animation: smoothScroll 1s forwards;
}
@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}
.hero-banner {
    background: #57ab1b;
    text-align: center;
    padding: 2em 0;
}
.hero-banner h2, .hero-banner p {
    color: #fff;
    padding: 0;
}
.lists-group .row h2, .customer-management .row h2, .featuress .row h2 {
    font-size: 22px;
    margin: 0  0 10px;
    padding: 0; text-align: left; color: #000;
}
.lists-group img {
    max-width: 200px;
    display: block;
    margin-bottom: 2em;min-height: 12em;
}
.lists-group p, .customer-management p{
    font-size: 15px;
    margin: 0;
    padding: 0; color: #737373;
}
.lists-group h2.heading-title, .customer-management h2, .featuress h2 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    line-height: 35px;display: block;
    letter-spacing: .4px; margin-bottom: 1em;
}
.lists-group {
    
    padding: 2em 0;
}
.lists-group .feature-image {
    min-height: 14em;
}
.customer-management img, .featuress .row .col-md-6 img {
    max-width: 200px;
}
.customer-management .feature-image {
    min-height: 11em;
}
.customer-management {
    background-color: #e7f5ff;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    padding: 50px 0;
}
.personalized-experience  {
    padding: 50px 0;
}
.personalized-experience p{
    color: #737373; font-size: 15px;
}
.personalized-experience img {
    max-width: 200px;
}
.personalized-experience .feature-image {
    min-height: 14em;
}
.flow-image img {
    width: 900px;
}
.hero-banner.banner-bg.lp {
    background: transparent;
}
li span.ftr-youtube a img {
    vertical-align: middle;
    transform: translate(0px, -4px);
}
.wrapper .hero-banner.banner-bg .main-content-new h2 {
    font-size: 30px;
    color: #454545;
    font-weight: 600;
}
.main-content-new ul li {
    list-style: disc;
    display: list-item;
    text-align: left;
}
.main-content-new {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 7em 0 0;
}

/**** New Lp Design ****/
.banner-lp {
    background: #09436C;
    padding: 5em 0 4em;
    color: #fff;
}
.button-wrapper a {
    background: #54ab1a;
    padding: 10px 20px;
    display: inline-block;
    font-weight: 600;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    font-size: 18px; border-radius: 4px;
}
.button-wrapper a:hover {
    background: orange;
    border: 1px solid #fff;
    color: #fff;
}
.banner-lp p{
    font-size: 20px;
    font-weight: 700;
    color: #fff;font-style: oblique;
    padding: 1em 0;
}
.live-dentists h2 {
    color: #000;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Geomanist';
}
.live-dentists {
    text-align: center;
    padding: 1em 0;
}
.live-dentists p{
    font-weight: 400;
    color: rgb(133, 139, 151);
    font-family: 'Geomanist';
}
.live-dentists h3 {
    color: #54ab1a;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Geomanist';
    margin: 10px 0 0;
}
.live-dentists .col-md-3 p {
    font-weight: 400;
    font-family: 'Geomanist';
    color: #000;
}
.newlpgroup .container {
    max-width: 1140px;
}
.dental-implants h2 {
    color: #09436C;
    font-size: 33px;
    text-transform: uppercase; font-weight: 900;font-family: 'Oswald';
    text-align: center;
}
.dental-implants {
    padding: 5em 0 2.5em;position: relative;box-shadow: rgb(0 0 0 / 24%) -2px 7px 4px;
}
.dental-implants p {
    font-weight: 900;
    text-align: center;
    font-size: 22px;margin-bottom: 1em; 
}
.dental-implants iframe {
    width: 100%;
    height: 400px;
}
.dental-implants .col-md-8 p {
    margin-bottom: 0;
    text-align: left;
    font-weight: 500;
    line-height: 1.5;
    padding: 1em 0;
    font-size: 16px;color: #000;
}
.form-container-group input:focus {
    border: 1px solid !important;
}
.dental-implants .col-md-8 h4{
    text-align: left;
    font-size: 22px;
    margin-bottom: 10px;
}
.dental-implants .col-md-8 h3 {
    color: #54ab1a;
    font-size: 30px;
    font-weight: 900;text-align: center;
    font-family: 'Oswald';
    margin: 1em 0 0;
}
ul.list-items li {
    font-size: 18px;
    padding: 10px 0; display: flex;
}
ul.list-items li i {
    color: #54ab1a;
    font-size: 30px;
    padding: 0 8px 0 0;
    vertical-align: middle;
}
.live-dentists .container{
    /* box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
    background: #fff;
    padding: 2em 1em;
    margin-top: 0em;
    border-radius: 5px; */
}
.form-container-group {
    background: #fff;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 6px;
}
.marketing-strategy .form-container-group h2 {
    background: #54ab1a;
    font-size: 20px;border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 15px 0;
    color: #fff; text-align: center;
}
.marketing-strategy h2 {
    color: #09436C;
    font-size: 30px;
    font-weight: 700;
}
.marketing-strategy p {
    color: #09436C;
    
}
.marketing-strategy img { width: 100%; }
.form-container-group input:nth-child(3), .form-container-group input:nth-child(2), .form-container-group input:nth-child(4), .form-container-group input:nth-child(5) {
    width: 94%;
}
.form-container-group input {
    background: #fff;
    width: 94%;
    margin: 5px 5px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #868181;
}
.form-container-group .banner_form_submit input {
    background: #09436C;
    width: 20%;
    border: 0;
    color: #fff;
    font-weight: 700;
}
.form-container-group form {
    padding: 1em 10px;
}
.lead-generation.funnel {
    padding-bottom: 4em;
}
.lead-generation { position: relative;padding: 3em 0;}
.lead-generation.funnel::before {
    background: url('http://agileads.co/formtop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 237px;
    bottom: 0;
    pointer-events: none;
    left: 0;
    z-index: -1;
    
    content: "";
}
.marketing-strategy {
    background: #f5f7fc;
    padding: 2em 0;
}
.right-img img {
    width: 55%;
    float: none;
    margin: 0 auto;
    display: block;
}
.footer-section {
    background: #09436C;
    text-align: center;
    padding: 10px 0;
}
.footer-section p { margin: 0;color: #fff; }
.left-banner h1 {
    font-family: 'Oswald' !important;
    font-size: 35px !important; margin: 0;
}
i.fa.fa-phone-square {
    
    display: inline-block;
    margin: 4px;
    vertical-align: middle;
}
.progressive-dental {
    box-shadow: rgb(0 0 0 / 24%) -2px 7px 4px;
    padding: 3em 0;
}
.progressive-dental img {
    width: 100%;
}
.progressive-dental h2 {
    font-size: 35px;
    color: #000;
    font-family: 'Oswald';
}
.progressive-dental ul {
    padding-left: 0em;
}
.progressive-dental ul li i.fa.fa-check {
    color: #54ab1a;
    padding: 3px 10px;
    vertical-align: middle;
}
.progressive-dental p {
    color: #000;
    font-size: 20px;
    margin: 10px 0;
}
.progressive-dental ul li {
    color: #000;
    font-size: 20px;
   
    display: flex;
}
.lead-generation h2 {
    font-size: 52px;
    color: #000;
    font-family: 'Oswald';
    margin-bottom: 0.5em;
}
.lead-generation p {
    font-size: 16px;
    color: #000;   
    margin-bottom: 1em;
}
.lead-generation.funnel img, .lead-generation img {
    width: 100%;
}
.button-wrapper div {
    padding: 0 25px 0 0;
}
.button-wrapper {
    display: flex;
    align-items: center;
}
.emblem-img img {
    width: 200px;
    border-radius: 50%;
}
.newlpgroup .thankyou-cont h2 {
    color: #09436C;
    font-size: 30px;
    margin: 0;
}
.newlpgroup .thankyou-cont{
    padding: 1em 0 0;
}
.banner-lp p strong {
    font-size: 30px;
    vertical-align: -5px;
    color: #57ab1b;
    font-weight: 900;
}

/** Landing Page Design 1 ******/
.landingpage1-group .container {
    max-width: 1200px;
}
.landingpage1-group .header1 .container {
    max-width: 1250px;
}
.banner-section h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
}
.banner-section {
    background: rgb(245, 246, 247);
    text-align: center;
    padding: 1.5em 0;
}
.banner-section h2 {
    text-align: center;
    font-size: 45px;
    color: rgb(32, 32, 32);
    font-weight: 700; padding: 0 20px;
    font-family: 'Geomanist'; margin-bottom: 10px;
}
.banner-section .beagi ul li {
    font-size: 14px;
    display: inline-block;
    padding: 0 8px;
    color: #54ab1a;
    border-right: 1px solid #000;
    line-height: 1.3; font-family: 'Geomanist';
    font-weight: 500;
}
.banner-section .beagi ul li:last-child {
    border-right: 0;
}
.banner-section .beagi ul {
    margin: 0;
    padding: 0em 0 2em;
}
.banner-content-full .left-img img {
    float: left;
    display: block;
    width: 290px;
    background: rgb(245, 246, 247);
    /* background-color: #54ab1a; */
}
.banner-content-full .right-content h3 {
    color: #858b97;
    font-family: 'Geomanist';
    letter-spacing: 0.4px;
    text-align: left; font-weight: 400; font-size: 15px;
}
.banner-content-full p strong { font-weight: 500;}
.banner-content-full p {
    text-align: left;font-family: 'Geomanist';
    font-size: 18px; color: #4A4A4A; font-weight: 300;
    line-height: 1.8;
    margin: 0;
}
.banner-content-full {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; display: flex;
    align-items: center; background-color: #fff; border-radius: 3px;
}
.banner-content-full .right-content {
    padding: 0 35px;
}
.header1 {
    background: #54ab1a;
    padding: 8px 0;
}
.footer-new-section p, .footer-new-section a {
    margin: 0;
    color: #fff;font-family: 'Geomanist';
}

.footer-new-section {
    border-top:1px solid #cccccc94;
    text-align: center;
    padding: 10px 0; background-color: #54ab1a;
}
.signup-section h2 {
    font-size: 42px;
    font-weight: 500;
    color: #000;
    font-family: 'Geomanist';
}
.buttons p {
    font-family: 'Geomanist';
    font-weight: 400;
}
.signup-section {
    background: rgb(245, 246, 247);
    padding: 4em 0;
    text-align: center;
}
.live-dentists h2 span, .frequently-section h2 span, .signup-section h2 span, .implantpatient-section h2 span, .frontoffice-section h2 span {
    color: #54ab1a;
}
.buttons a {
    background: #54ab1a; color: #fff;
    padding: 10px 60px;
    display: inline-block;
    border-radius: 3px;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    font-family: 'Geomanist';
    font-weight: 500; cursor: pointer;
}
.buttons {
    padding: 1em 0;
}
.implantpatient-section {
    text-align: center;
    padding: 3em 0 3em;
}
.frontoffice-section { padding: 2em 0; text-align: center; }
.implantpatient-section h2, .frontoffice-section h2, .frequently-section h2 {
    color: #000;
    font-size: 35px;
    font-family: 'Geomanist';
    font-weight: 500;
}
.implantpatient-section p, .frontoffice-section p {
    color: rgb(133, 139, 151);
    margin-bottom: 2em;font-family: 'Geomanist';
    font-weight: 400;
}
ul.bull-points li i.fa.fa-check {
    color: #54ab1a;
    font-size: 20px;
    padding: 8px;
}
ul.bull-points li i.fa.fa-check {
    color: #54ab1a;
    font-size: 20px;
    padding: 8px;
}
ul.bull-points {
    text-align: left;
}
.frontoffice-section img {
    width: 550px;
    display: block;
    margin: 0 auto;
}
.pricing-section {
    background: rgb(245, 246, 247); padding: 2em 0;
}
.pricing-section-inner table th {
    background: #fff; text-align: center;
    padding: 1em 0;
    color: #57ab1a;
    font-size: 30px;
    font-family: 'Geomanist';
    font-weight: 500;
}
.pricing-section-inner table td {
    padding: 5px 10px;
    color: rgb(38, 40, 45);
    font-size: 18px;
    font-weight: 400;
    font-family: 'Geomanist';vertical-align: baseline;
    width: 33%;
}
.pricing-section-inner table th:nth-child(2) {
    border-right: 1px solid rgb(228, 232, 237);
    border-left: 1px solid rgb(228, 232, 237); 
}
.pricing-section-inner table td:nth-child(2) {
    border-right: 1px solid rgb(228, 232, 237);
    border-left: 1px solid rgb(228, 232, 237);
}
.landingpage1-group .thankyou-cont h2 {
    color: #000;
    font-size: 35px;
}
.landingpage1-group .thankyou-cont p {
    color: #000;
   
}
.pricing-section-inner table td a {
    background: #54ab1a;
    color: #fff !important;
    display: block;
    width: 60%;
    margin: 0 auto 0;
    padding: 8px;
    border-radius: 2px;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s !important;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px; cursor: pointer;
    font-family: Roboto, arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.trial {
    color: rgb(32, 32, 32);
    font-weight: 400;
}
.pricing-section-inner {
    background: #fff;
    text-align: center;
    padding: 0 35px;
    
}
.price-sample {
    box-shadow: rgb(0 0 0 / 12%) 0px 10px 80px;
}
.landingpage1-group {
    background: rgb(245, 246, 247);
}
.disclaimer ul li:nth-child(2) {
    border-left: 2px solid rgb(228, 232, 237);
    border-right: 2px solid rgb(228, 232, 237);
}
.disclaimer ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Geomanist';
    padding: 0 10px;
}
.disclaimer ul { margin: 0; }
.disclaimer {
    background: rgb(248, 248, 248);
    text-align: center;
    padding: 1em 0;
}
.frequently-section h2 {
    text-align: center;
    margin-bottom: 25px;
}
.faq-lists .accordion__button {
    font-size: 22px;
    background: #fff;
    padding: 35px 20px;
    font-family: 'Geomanist';
    color: rgb(38, 40, 45) !important;
    font-weight: 300; position: relative;
}
.faq-lists {
    max-width: 1000px;
    margin: 0 auto;
}
.faq-lists .accordion__item + .accordion__item {
    border-top: 1px solid rgb(228, 232, 237);
}
.faq-lists .accordion {
    
    border-top: 1px solid rgb(228, 232, 237); border-left: 0 ; border-right:0 ;
}
.frequently-section {
    padding: 3em 0; background-color: #fff;
}
.video-contents iframe {
    width: 60%;
    height: 400px;
}
.faq-lists .accordion__item.active .accordion__button {
    background: transparent;
    padding: 25px 13px 0;
    font-weight: 500;
    vertical-align: middle;
    color: #54ab1a !important;
    font-family: 'Geomanist';
}
.faq-lists .accordion__item.active {
    border-left: 3px solid #54ab1a;padding: 0 15px;
}
.lp-emblem img {
    width: auto;
    margin-bottom: 0;
}
.buttons a:hover, .pricing-section-inner table td a:hover {
    background: #ffa844;
    color: #fff;
}
.faq-lists .accordion__panel p {
    font-weight: 100;
    font-size: 20px;
}
.faq-lists .accordion__button:before {
    display: inline-block;
    content: '+';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    transform: rotate( 
-0deg
 );
    float: right;
    font-size: 60px;
    top: 10px;
    position: absolute;
    z-index: 99;
    right: 32px;
    color: rgb(155, 155, 155) !important;
    font-weight: 200;
    border-bottom: 0px solid currentColor;
    border-right: 0px solid currentColor;
}
.buttons p img {
    vertical-align: bottom;
}
.faq-lists .accordion__item.active .accordion__button[aria-expanded='true']::before, .faq-lists .accordion__item.active .accordion__button[aria-selected='true']::before {
    transform: rotate(
0deg
);
content: '_';
top: -10px;
    right: -10px; display: flex; justify-content: center;
}
.lp1-form h2 {
    display: inline-block;
    color: #000;
    padding: 0;
    margin: 0;
    font-weight: 400;
}
form.lp1 input {
    margin-bottom: 1em;
}
form.lp1 .form-group.row {
    margin-bottom: 0;
}
form.lp1 {
    padding-bottom: 0;
}
.banner-title {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}
.banner-section .beagi h1 {
    font-size: 40px;
    font-family: 'Geomanist';
    font-weight: 500;
    border-left: 4px solid rgb(255, 168, 68);
    display: inline-block;
    padding: 0 10px;
    color: #57ab1a;
    line-height: 1;
    vertical-align: text-bottom;
    border-right: 4px solid rgb(255, 168, 68);
}
.banner-section .right-content h2 {
    text-align: left;
    padding: 0;
    font-size: 27px;
    margin: 0 0 15px; color: #54ab1a;font-family: 'Geomanist';
}
.monthly-text span {
    display: block;
}
.monthly-text {
    text-align: center;
    font-family: 'Geomanist';
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 15px;
}
.monthly-text span {
    display: block;
}
.monthly-text {
    text-align: center;
    font-family: 'Geomanist';
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 15px;
}
.lp-emblem {
    transform: rotate(
0deg
);
    position: relative;
    top: -12px;
}
.pricing-section-inner.desktop { 
    display: block;
}

.pricing-section-inner.mobile {
display: none;
}
.faq-lists .accordion__button:focus { outline: none;}
ul.bull-points li {
    list-style: none;font-family: 'Geomanist';display: block;
}

/***** new lp use it lose it ******/
.header2 {
    padding: 15px 0;
}
.banner-section-lp {
    background: #57ab1a;
    padding: 0;
    color: #fff;
}
.banner-title-lp p {
    font-family: 'Poppins';
    text-align: left;
    font-size: 30px;
    line-height: 1.3;
    padding: 20px 30px 0;
    color: #fff;
}
.hippa-img img {
    width: 90%;transform: translate(-30px, 0);
}
.our-process-left { transform: translate(0,0);}
.green-border-bg p {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
}
.header-bottom {
    display: flex;
    align-items: center;
}
.banner-title-lp h1 {
    font-size: 100px;
    font-family: 'Poppins';
    font-weight: 700;
    margin: 0;
}
.banner-title-lp {
    max-width: 1080px;
    margin: 0 auto; padding: 10px 0;
}
.use-form-section-lp h3 {
    color: #57ab1a;
    font-size: 30px; text-align: center;
    font-family: 'Poppins';
}
.use-form-section-lp h2 {
    font-size: 35px;
    font-weight: 600;
    font-family: 'Poppins'; text-align: center;
    line-height: 43px;color: #54ab1a;
}
.thankyou-cont h2 {
    color: #454545;
}
.thankyou-cont {
    color: #454545; padding-bottom: 1em;
}
.use-form-section-lp h2 span {
    font-weight: 500;
    font-size: 33px;
}
.center-sec {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 4em 0;
    flex-direction: column;
}
.use-it-lp1-form h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 25px 0 0;  line-height: 1;
    text-align: center;color: #454545;
}
.dont-lose-revenue h2 {
    border: 2px solid #57ab1a;
    display: inline-block;
    padding: 6px 25px;
    font-family: 'Poppins';
    font-size: 40px;
    border-radius: 7px; color: #454545;
}
.dont-lose-revenue {
    text-align: center;
    padding: 1em 0 2em;
}
.dont-lose-revenue .free-offer img {
    width: 100%;
    max-width: 1000px;
    margin: 15px 0;
}
.dont-lose-revenue p {
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #454545;
    padding: 0.2em 0;
}
.dont-lose-revenue span.mnth-p {
    font-size: 20px;
    margin-left: 0;
    position: absolute;
    text-align: center!important;
    left: 0;
    right: 0;
    font-family: 'Oxygen';
}
.dont-lose-revenue p.circle {
    background: #54ab1a;
    border-radius: 200px;
    color: #fff!important;
    font-weight: 600;
    width: 200px;
    font-size: 90px;
    border: 8px solid #bfb9b9fa;
    font-family: 'Geomanist';
    padding: 65px 0 0;
    height: 200px;
    vertical-align: middle;
    align-items: center;
    display: inline-block;
    line-height: 32px;
}
.dont-lose-revenue h4 {
    font-family: 'Poppins';
    font-size: 32px;
    color: #454545;
    margin: 0;
}
.dont-lose-revenue h4 span {
    color: #57ab1a;
    font-weight: 700;
}

.dont-lose-revenue h4 img {
    max-width: 85px;
    vertical-align: -30px;
}
.lady-img img {
    max-width: 250px;
    vertical-align: text-top;
    margin: 0 auto;
    display: block;
    transform: translate(10px, 77px);
}
.lady-img { position: relative; z-index: 999; }
.use-it-lp1-form {
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding: 15px;
    margin-top: 2em; border-radius: 3px;
}
.use-it-lp1-form input.form-control {
    background: #fff;
    padding: 23px 15px;
    color: #454545;
    font-family: 'Poppins';
    border: 1px solid #ccc6;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.use-it-lp1-form h2 {
    font-size: 21px;
    font-weight: 700;
    font-family: 'Poppins';
    margin: 25px 0 0;
    line-height: 1;
    text-align: center;
    color: #57ab1a;
}
.use-it-lp1-form button.lp-btns {
    background: #ffa844;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 45px;
    margin: 1em auto;
    display: block;
    font-family: 'Poppins'; border-radius: 30px;
    font-weight: 700;
}
.free-offer img {
    max-width: 500px;
    vertical-align: initial;
}
.use-form-section-lp .center-sec h2 { font-size: 40px; color: #000; font-weight: 700;}
/* .use-it-lp1-form input.form-control {
    background: #fff;
    padding: 20px 15px;
    border: 0;
    border-radius: 0;
    color: #000;font-family: 'Poppins';
}
.use-it-lp1-form button.lp-btns {
    background: #ffa844;
    color: #000;
    text-transform: uppercase;
    padding: 12px 45px;
    margin: 2em auto;
    display: block;font-family: 'Poppins';
} */
.use-it-lp1-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #454545;
  }
  .use-it-lp1-form input ::-moz-placeholder { /* Firefox 19+ */
    color: #454545;
  }
  .use-it-lp1-form input:-ms-input-placeholder { /* IE 10+ */
    color: #454545;
  }
  .use-it-lp1-form input :-moz-placeholder { /* Firefox 18- */
    color: #454545;
  }
  .footer-new-section.useit {
   padding: 15px 0; border-top: 0;
    background-color: #454545;
}
/* .use-it-form {
    background: #cacaca;
}
.use-form-section-lp {
    border-bottom: 13px solid #57ab1a;
} */
.right-ban p img {
    display: inline-block;
    max-width: 45px;
    vertical-align: -webkit-baseline-middle;
}
.green-border-bg {
    background: #54ab1a;
    padding: 25px 0;
}
.our-steps img {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 800px;
}
.our-process-gp h2 {
    color: #454545;
    font-size: 45px;
    font-family: 'Roboto';
    margin-bottom: 1em;
}
.our-process-gp {
    background: #f8f8f8;
    padding: 0.5em 0;
}
.our-process-left-right img, .our-process-left-right-2 img {
    width: 100%; transform: translate(0, 20px);
}
.our-process-left-right {
    max-width: 1000px;
    margin: 4em auto 1em;
    position: relative;
    padding: 0 20px;display: flex; align-items: center;
    
    
}
.our-process-left-right p {
    color: #54ab1a;
    font-family: 'Roboto';
    text-align: justify;
    font-size: 15px;
}
.our-process-left-right-2 p {
    color: #54ab1a;
    font-family: 'Roboto';
    text-align: justify;
    font-size: 15px;
    text-align-last: right;
}
.our-process-left, .our-process-right-2 {
    padding: 0em 0 0;
}
.our-process-left-right h3, .our-process-left-right-2 h3 {
    color: #454545;
    font-size: 35px;
    font-weight: 500;
    font-family: 'Roboto';
}
.our-process-left-right-2 h3 {
    text-align: right;
}
.our-process-left-right::before {
    position: absolute;
    content: "";
    height: 95%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: 2px solid #54ab1a;
    border-radius: 30px; display: block;
}
.our-process-left-right-2 img {
    width: auto;
    max-width: 695px; transform: translate(0, 20px);
}
.green-border-large {
    background: #54ab1a;
    padding: 40px 0;
    margin-top: -7em;
}
.our-process-left-right.bg::before {
    background-color: #f0f0f0;
}
.our-process-left-right.bg .our-process-right {
    float: right;
}
.our-process-left-right.bg .our-process-left p {
    max-width: 460px;
    line-height: 1.5;
    padding: 1em 0 0;
}
.our-process-left-right.bg, .our-process-left-right-2 {
    display: flex;
    align-items: center;
}
.our-process-left h3 img {
    max-width: 127px;
    vertical-align: -15px;
}
.our-process-left-right.bg .our-process-left{ padding: 0; }
.des, .use-desk { display: block;}
.mob, .use-mobile{ display: none;}
.use-it-lp1-form input.form-control:focus {
    border: 1px solid#ccc !important;
}
/*** Responsive Design starts ****/
@media(max-width: 991px){
	.features-group .col-md-6 .col-md-6 img {
    width: auto;
    max-width: 370px;
}
.frontoffice-section img {
    width: 450px; }
    .banner-content-full .right-content {
        padding: 20px 35px;
    }
    .use-desk { display: block;}
    .des { display: none;}
    .mob, .use-mobile { display: block; margin: 0 auto;}
.banner-title-lp h1 {
    font-size: 60px; }
.banner-title-lp p {       
        font-size: 21px;       
        padding: 20px 12px 0;
    }
    .use-desk {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    .lady-img img { transform: translate(0,0);}
.free-offer img {    
       
        margin: 0 auto;
        display: block;
    }
    .green-border-large {
   
        margin-top: 0;
    }
    .our-process-left-right.bg, .our-process-left-right-2 {
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;
    }
    .our-process-left-right-2 img {
        width: 100%; }
    .our-process-left-right h3, .our-process-left-right-2 h3 { font-size: 30px; }


}
@media(max-width: 767px){
.email-content{
width: 100%;
    height: auto;
}
    button.learn-more .button-text { margin: 15px 0 15px 30px }
    button.learn-more .circle-lp { margin: 20px 0 0 0; }
    .testimonial-group .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff;
}
.type-common{
    font-size: 20px;
}

.testimonial-group .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
}
.testimonial-lists p { font-size: 16px; }
.testimonial-group .carousel .control-arrow:before, .testimonial-group .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
}
    .testimonial-lists {
    
    margin: 0; display: block}
    .testimonial-group .carousel.carousel-slider {
    padding: 2em 0em 3em; }
	.dashboard-image img, .features-group .col-md-6 .col-md-6 img {
    
    width: 100%;
	}
	.advantages-group::after { content: none; right: 0; } 
	.features-group h3 {    
    text-align: center;padding: 15px 0 0;
	}
	.features-group .col-md-6 .row {
    padding: 10px 0;
}
.copyrights {
    margin-top: 0;
}
.advantages-lists {
    flex: 1 1 100%; margin: 1em auto; }
    .dentist-issues::before { background-size: 200px; right: 0; }
  .hero-banner::before { content: none; }
  .social-profiles {
    padding: 1em 0;
	}
	.hero-banner::after {
    background-position: center bottom;
    right: 0;
    
    background-size: 300px;
    z-index: -1;
    top: auto;
    bottom: 0;
    
}
.hero-banner {
    
    padding: 3em 0 18em;
    
}
.hero-banner h2, .hero-banner h1{
    font-size: 20px; }
    h2 {
   
    font-size: 20px; }
  .all-issues-lists {
    flex: 1 1 100%;
    padding: 1em 2em 2em; margin: 1em auto; text-align: center;
}
 .all-issues, .features-group p, .all-issues-lists h3{   text-align: center;
    display: block; }
    .dental-business h3 {
    font-size: 20px;width: auto;
}
.form-group .col-md-6, .form-group .col-md-5 { margin-bottom: 1em; }
.get-in-touch .form-group {
    margin-bottom: 0;
}
.get-in-touch form {
    padding: 10px 0px !important;
}
.form-group textarea.form-control {
    margin-bottom: 1em;
    margin-top: 1em;
}
input#example-datepicker {
    width: 100%;
    max-width: 100%;
}
input[type="time"] {
    width: 100%;
    margin-top: 1em;
}
.get-in-touch .react-datepicker-wrapper {    
    width: 100%;
}
.advantages-lists h3 {
   
    min-height: 0; }
input.lp-btns {
    width: 50%; }
.form-group .col-md-1 {
    display: none;
}
.dental-business iframe {
    margin-top: 2em;
    width: 100%;
    float: none;
    height: auto;
}
.banner-buttons a {
   
    margin: 10px auto !important;
}
.get-in-touch::before {
    z-index: -1;
}
.col-md-6.copy {
    display: block;
    
}
.footer-lp img { width: 100% !important; }
button.learn-more {
    width: 100%;
    
    display: block;
}
.banner-buttons { display: block; }
/*** lp new changes **/
.flow-image img {
    width: 100%;
}
.all-issues-lists:nth-child(2), .all-issues-lists:nth-child(4) {   
    display: block;
    padding: 3em;
    margin: 0 auto;
}
.all-issues-lists:nth-child(2n+1) {
    margin: 1em auto;
}
.header-sec.fixed {    
    position: relative; }
.form-banner .form-group {      
        padding-left: 6px;
        padding-right: 6px;
 }
 .wrapper .hero-banner.banner-bg h2, .wrapper h2 {
    
    font-size: 22px;
}
.all-issues-lists {
  
    margin: 1em auto;
    border-radius: 5px;
    max-width: 100%;
   
}
.lpage .ttttt {
    text-align: center; } 
.wrapper .hero-banner.banner-bg .main-content-new h2 {
        font-size: 22px;
      
    }
    .newlpgroup iframe {
        width: 100%;
        height: 220px;
    }
    .col-md-6.left-banner h2, .col-md-6.left-banner h1 {
        font-size: 25px !important;
        text-align: center;
    }
    .lpnew-header, .button-wrapper a, .marketing-strategy p, .lead-generation p, .banner-lp p, .dental-implants .col-md-8 p, .progressive-dental p,  { text-align: center;}
    .button-wrapper {    
        flex-direction: column-reverse;
    }
    .button-wrapper div {
        padding: 10px 0;
    }
    .live-dentists h2, .marketing-strategy h2, .lead-generation h2, .progressive-dental h2, .dental-implants h2, .dental-implants .col-md-8 h3{ text-align: center; font-size: 24px; }
    .form-container-group .banner_form_submit input {   
        width: auto; }
        .dental-implants p, .progressive-dental p, .progressive-dental ul li { font-size: 16px; }
    .banner-lp {
        
        padding: 2em 0; }
        .banner-lp p strong { vertical-align: middle;}
        .banner-lp p, .marketing-strategy .form-container-group h2 { font-size: 17px;}
        .live-dentists .container { margin-top: 0;}
        .lpnew-header a img { display: block; margin: 0 auto;} 
        .dental-implants, .lead-generation {
            padding: 2em 0; }
        .progressive-dental p, .marketing-strategy p, .dental-implants .col-md-8 p, .lead-generation p, .button-wrapper a { text-align: center;}
            .lead-generation.funnel {
                padding-bottom: 0em;
            }
        .marketing-strategy img {
                margin-bottom: 1em;
            }
        .progressive-dental img {               
                margin-bottom: 1em;
            }
        .lpnew-header h2 {
                padding: 10px 0 !important;
            }
            .pricing-section-inner {
                overflow-y: hidden;
                overflow-x: scroll;
                width: 100%; padding: 0 10px;
            }
            .frontoffice-section img { width: 100%; }
            .faq-lists .accordion__button:before { font-size: 44px;
                right: 15px;
                top: 18px; }
            .faq-lists .accordion__button, .faq-lists .accordion__panel p {
                font-size: 17px; }
                .video-contents iframe {
                    width: 100% !important;
                    height: 215px !important; 
                }
                .video-contents img {
                    width: 100%;
                }
                .signup-section h2 {
                    font-size: 20px; }
                    .implantpatient-section h2, .frontoffice-section h2, .frequently-section h2 { font-size: 25px;}
.banner-section, .frequently-section { padding: 2em 0;}
.banner-section .beagi ul li {
    font-size: 13px;
    display: inline;
    padding: 0 8px; }
    .banner-content-full { flex-direction: column; }
    .banner-content-full .right-content {
        padding: 35px 10px;
        text-align: center;
    }
.pricing-section {
       
        padding: 1em 0;
    }
    .pricing-section-inner table td {
        padding: 0 3px;
        min-width: 260px;
        font-size: 16px; }
        .pricing-section-inner table th { font-size: 26px !important; }
        .pricing-section-inner table td a { width: 92%; font-size: 16px; }
        .live-dentists p { font-size: 16px; }
        .implantpatient-section {
            
            padding: 1em 0;
        }
        .banner-section h2 {            
            font-size: 30px; 
        }
      
        .header1 img {
            display: block;
            margin: 0 auto;
        }
        .lp-emblem {
            transform: rotate(
        0deg
        ); }
        .banner-section .beagi h1 {
            font-size: 22px !important;
            line-height: 30px;
            }
            .trial {
                padding: 10px 0 0;
            }
            .banner-title { flex-direction: column ;}
            .frequently-section h2 { margin-bottom: 0;}

.pricing-section-inner.desktop {
                display: none;
            }

.pricing-section-inner.mobile {
    display: block;
}
.banner-content-full .left-img img {
    width: 100%;
}
/*** Use it lose it ****/
.our-process-left-right.bg, .our-process-left-right-2 { display: block;}
.lady-img img { margin-top: 1em; transform: translate(0,0); max-width: 150px;}
.banner-title-lp h1 { text-align: center; font-size: 70px;}
.banner-title-lp p { font-size: 16px; text-align: center;}
.center-sec { align-items: center; padding: 1em 2em 0;}
.use-form-section-lp h2, .use-form-section-lp h2 span {
    font-size: 23px; text-align: center; margin: 5px 0;
    line-height: 1.2; }
.use-form-section-lp h3 { font-size: 19px; font-weight: 600; margin: 0; text-align: center; }
.dont-lose-revenue {   
    padding: 1em 0;
}
.dont-lose-revenue h2 { font-size: 30px;}
.header2 {
    padding: 10px 0;
    text-align: center;
}
.use-it-form {
    
    padding: 10px 15px;
}
.our-process-gp h2 {    
    font-size: 35px;    
    margin-bottom: 10px;
    text-align: center;
    margin-top: 0;
}
.our-process-left-right { display: block; margin: 1em auto; text-align: center; }
.our-process-left, .our-process-right-2 {
    padding: 5px 15px 15px; text-align: center;
}
.our-process-left-right-2 img, .our-process-left-right img, .our-process-left-right-2 img {
    width: 100%;
    max-width: 100%; margin: 0 auto;
    transform: translate(0, 0px);
}

.our-process-left-right-2 h3, .our-process-left-right-2 p, .our-process-left-right.bg .our-process-left p, .our-process-left-right p { text-align: center; text-align-last: center; }

.our-process-left-right.bg .our-process-left { padding: 10px 0 0;}
.our-process-left-right h3, .our-process-left-right-2 h3 {    
    font-size: 21px;    
    padding: 16px 0 0;
    display: inline-block;
}
.our-process-gp { padding: 1em 0; }

.dont-lose-revenue h4 {    
    font-size: 17px;    
    margin: 25px 0 0;
    display: inline-block;
}
.use-form-section-lp .center-sec h2 {
    font-size: 30px;
    line-height: 25px;
    padding: 6px 0; }
.hippa-img img {
    width: 66%;transform: translate(0, 0); display: block; margin: 0 auto;
}
.use-form-section-lp {
    padding: 1em 0 0;
}
.header-bottom {
    display: block;
    
}
.free-offer img {
    max-width: unset;
    vertical-align: initial;
    width: 100%;
}


}

.advantages-lists:hover {
    transform: scale(1.1) !important;

}

.Typewriter {
    display: inline;
  }
  
  .Typewriter__wrapper {
    font-weight: 700;
      display: inline-block;
      padding: 5px 10px;
      font-size: 2.5rem;
  }
  .type-common {
    font-weight: 700;
      display: block;
      font-size: 2.5rem;
  }
  
  .Typewriter__cursor {
    font-weight: 700;
      display: inline-block;
      font-size: 2.5rem;
  }

  .circl {
      margin: auto;
    max-width: 85px;
      
  }
  .advantages-lists .img1 {
    max-width: 85px;
    background: url(http://headnurture.com/circle-lp.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 15px;
    position: relative;
    z-index: 99999999999;
}
.advantages-lists .img2 {
    max-width: 85px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 15px;
    position: relative;
    z-index: 99999999999;
}
.testimonial-group .carousel.carousel-slider::before {
    content: "";
    background: url(https://headnurture.com/quotes.png);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-position: left top;
    background-size: 75px;
    background-repeat: no-repeat;
    left: 40px;
    top: 10px;
    pointer-events: none;
}



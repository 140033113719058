@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

html {
  scroll-behavior: smooth; 
}
body
 {
    min-height: auto !important;
  overflow-x: hidden;
}
ul li {display: block;}

.nav {
    display: block;
}
   
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: #54ab1a;
    background-color: #e7e7e714;
    font-weight: 900;
    border-bottom: 3px solid #b1abab;
    margin: 0px auto;padding-bottom: 5px;
    background-color: #ffffff00;
    padding-left: 0px;
    padding-right: 0px;
}
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>span {
   color: #54ab1a;font-weight: 900;font-size: 16px; text-decoration: none; 
}
.navbar-default .navbar-nav>li>span {
    padding: 17px 15px;
    display: inline-block;
    vertical-align: middle; cursor: pointer;
}
h2,li,label{font-family: 'Raleway', sans-serif;}
p,li,button,h6,h5,h2,h3,h4{font-family: 'Oxygen', sans-serif;}
.wrapper-home h1{font-family: 'Oxygen', sans-serif; font-size: 34px; }
.set img {
    width: 100%;
}
.set1{ margin-top: 37px;}
.set1 img {
    width: 73%;  
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{}

/*** Changes */
.flt-lft-line {
    border-right: 2px solid #d1ffb6;
    height: 126px;
    margin-right: 23px;
    margin-left: -43px;
    margin-top: 27px;color:#fff;
}
.banner-bg::before {
    position: absolute;
    content: "";
    background: url(../images/light-green.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 450px;
    top: 2em;
    right: 0;
}
.banner-bg {
    position: relative;padding: 1em 0 3em;
}
.ttttt { text-align: center; }
.address {
    display: flex;
    align-items: center;
}
.footer-logo { text-align: center; }
.funnel-group::before {
   background: url(../images/green-bg-right.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: 0;
    bottom: -13em;
    background-size: 380px;
    left: auto;
    z-index: 22;
    pointer-events: none;
    top: auto;
}
div#feature {
    padding: 4em 0 0;
}
.funnel-group { position: relative; padding: 1em 0 3em;}
.price-list-one h1 {
    max-width: 144px;
    margin: 0 auto;
    /* border-bottom: 2px solid #ffb300; */
    display: block;
    padding-bottom: 10px;
    margin-bottom: 1em;
    font-size: 30px;
}
.price-list-one h1::after {
    content: '';
    display: block;
    position: relative;
    top: 10px;
    width: 45%;
    left: 26%;
    border-top: 3px solid #ffb300;
}
.price-bg-gray::before {
    position: absolute;
    background: url(../images/green-bg-left.png);
    content: "";
    width: 100%;
    height: 100%;
    top: -12em;
    left: 0;
    background-repeat: no-repeat;
    background-position: left top;
    bottom: auto;
    background-size: 250px;
}
.table.table.table-striped.feat tbody tr:last-child td {
    border-right: 0;
}
td i { font-size: 25px; }
a.btn-home {
    background: #57ab1a;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    font-family: inherit;
    display: inline-block;
    margin: 1em auto;
    text-align: center;
    width: auto;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
body a {color: #fff; }
body a:hover { color: #6a6a6a; }
.footer ul li a { color: #fff; }
.plan-1 {
    text-align: center;
}
.copy-right p { font-weight: 600; }
.copy-right p a:hover { color: #57ab1a; }
.video-content {
    display: flex;
    align-items: center;
    justify-content: center; margin-left: 1%;
}
.navbar-default .navbar-nav>li:last-child > a {
    color: #fff;
    padding: 15px 45px;
}
.navbar-default .navbar-nav>li:last-child {
    margin-left: 1em;
    margin-top: 5px;
}
ul.nav.navbar-nav li {
    
    margin-top: 1em;
}
.navbar-default .navbar-nav>li:last-child > a:hover {
    background: #ffb300;
    color: #fff;
}
.logo-menu {
    padding: 10px 0;
    float: left;
    width: 100%;
}
.navigation {
    width: 75%;
    float: right; padding: 0;
}
.navigation .navbar-nav {
    display: block;
}
nav.navbar-default.navbar-static-top.fixed {
    background: #fff;
    position: fixed; z-index: 9999999999;
    width: 100%;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out 0s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-bottom: 1px solid #ccc;
}
.navbar-default .navbar-nav>li>a.active {

    border-bottom: 3px solid #6a6a6a73;
    padding-bottom: 4px;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
}
.next button.btn.btn-primary.step-btn.company {
  width: 150px;
}
#video .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    padding: 5px !important;
    width: 100%  !important;
    height: 500px !important;
    overflow: hidden;
}

#video .MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

#video .MuiSvgIcon-root path {
    fill: #ffb300;
}
#video .MuiSvgIcon-root {
  width: 2em;
  height: 2em; background: #fff;
}
.home.get-in-touch h2 { color: #54ab1a; border-bottom: 0;
    padding-bottom: 11px; }
    .home.get-in-touch h2::after {
        content: '';
        display: block;
        position: relative;
        top: 10px;
        width: 15%;
        left: 0; right: 0; margin: 0 auto;
        border-top: 3px solid #ffb300;
    }
.home.get-in-touch::before { background: none; }
/*** Changes **/

/*Mobile Resposive*/
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    border-bottom: none;padding-left: 17px!important;
}

.header-bg-sec {
    height: 297px!important;
    }
.nav {
    padding-left: 0%!important;
}
.main-hd p {
    width: 74%!important;
    text-align: center;
    font-size: 19px;
    line-height: 28px;
    padding-top: 27px;
}
.main-hd h1 {
    font-size: 28px!important;
    margin-top: -61px;width: 71%!important;
    padding-left: 32px!important;
}
.center-box {
    margin-top: 0%!important;
    padding: 18px 3px 181px 3px!important;
}

button.menubtn{margin-left: 18px!important;}

}

/*Mobile Resposive*/


.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #54ab1a;
}
.navbar-default .navbar-toggle {
    border-color: #000;
}
.navbar-toggle{background-color: #54ab1a;}
.header-bg-sec {
    height: 952px;
    background-image: url("../images/bg-header.jpg");
    width: 100%!important;
    background-size: cover;
    background-repeat: no-repeat;margin-top: -7%;
    
}
.set{
    width:100%;
    padding-right: 0px!important;
    padding-left: 0px!important;
    
}
   .main-hd h1 {
    color: #54ab1a;
    font-weight: 900;
    font-size: 30px;
    border-left: 4px solid #ffa844;
    margin-right: 27px;
    padding-left: 5px;
    border-right: 4px solid #ffa844;
    width: 63%;
}
.main-hd p {
    width: 66%;
    text-align: center;
    font-size: 19px;
    line-height: 28px;
}
.main-hd-form {
    margin: auto;
    width: 81%;
    margin-top: 23px;
}
.main-hd {
    padding-left: 58px;
}
.main-hd-form .btn-success {
    margin-right: 20px;
    min-width: 180px;
    min-height: 65px;
    padding-top: 22px;
    padding-bottom: 22px;
}


.center-box {
    margin: auto;
    width: 54%;
    text-align: justify;
    line-height: 26px;
    color: #fff;
    padding: 35px 3px 116px 3px;
    background: linear-gradient(
81deg
, #2c9e08, #7bdf5b);
    border-radius: 20px;
    font-weight: 600;
     margin-top: -19%;
}

.box-two img {
    margin: auto;
    width: 100%;
}
.pd-lft-rght {
    width: 100%;
    padding-right: 0px!important;
    padding-left: 0px!important;
}
.box-two {
    margin-top: 44px;
}
.btn-three {
    color: #000;
    background-color: #ffffff;
    border-color: #ffffff;
    float: left;
    border-radius: 24px;
    
}
button.btn-three {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 18px;
}
.center-box h6 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    font-size: 14px;
}
.main-box-2 p {
    text-align: center;
    background-color: #54ab1a;
    padding-top: 18px;
    padding-bottom: 18px; box-shadow: 0 5px 8px -2px #a7a0a0;
    color: #fff;
    font-size: 17px;
    font-weight: 900;
}
.main-box-3 {
    text-align: center;
}
 .icon-box p {
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 8px;
    padding-top: 8px;
}
.box-sec {
    border-radius: 16px;
    background-color: #53ab19;
    padding: 43px 20px 43px 20px;
    margin: auto;
    width: 66%;
    margin-bottom: 20px;
    
}

.navbar-default {
    background-color: #f8f8f800;
    border-color: #e7e7e700;
    border-top: none; position: relative;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
button.menubtn {
    background-color: #54ab1a;
    margin-top: 4px;
    padding: 7px 45px 7px 45px;
    border: 1px solid #54ab1a;
    border-radius: 7px;
    color: #fff;
    margin-left: 105px;
}

.beagi h1, .banner-section .beagi > h1 {
    justify-content: center;
    color: #54ab1a;
    font-weight: 900;
    font-size: 49px;
    border-left: 4px solid #ffa844;
    border-right: 4px solid #ffa844;
    margin-left: 0;
    margin-right: 0;
    display: inline-block;
    margin: 0;
    line-height: 40px;
    padding: 0 10px; font-family: 'Oxygen', sans-serif;
}
.beagi h2 {
    color: #54ab1a;
    margin-left: 26px;
    margin-bottom: 0;
    font-size: 21px;
    font-family: Arial;
    font-weight: 500;
    border: 0;
    transform: translate(-18%, 45%);
}
.beagi p {
    font-size: 15px;
    text-align: justify;
    text-align-last: justify;
    margin-left: 0%;
    margin-right: 6%;
    padding-top: 6px;
}
.new-update-home .beagi p {
    font-size: 15px;
    text-align: justify;
    text-align-last: center;
    margin-left: 0%;
    margin-right: 0; letter-spacing: 0.2px;
    padding-top: 6px;
}
ul.nine-points li {
  list-style: none;
  font-size: 15px;
  padding-top: 7px;
}
ul.nine-points li:before {
  content: '✓';
  color: #54ab1a;
  font-size: 12px;
  font-weight: 900;
  margin-right: 11px;vertical-align: 3px;
  background-color: #57aa1c;
    color: #fff;
    background: linear-gradient(#00c900, #57aa1c);
    border-radius: 5px;
    display: inline-block;
    margin-left: -41px;
    width: 36px;
    text-align: center;
     width: 2%!important;
    padding-left: 4px;
    padding-right: 15px;

}
.banner-bg img {
    margin-top: -30px !important;
}
.banner-btn a {
    margin-right: 1em;
}
a.button.bkademo-1 {
    padding: 9px 22px;
}
ul.nine-points {
    margin-top: 25px;
    margin-left: 9%;
}
button.bkademo-1 {
    padding-left: 38px;
    padding-right: 38px;
    margin-right: 15px;
}
button.bkademo {
    background-color: #249800;
    border: 2px solid #249800; 
    padding: 5px 21px 6px 21px;
    color: #fff;
    border-radius: 6px;
}
.banner-btn {
    margin-top: 60px;
    margin-left: 0; display: flex; align-items: center;
}

.video {
    margin-top: -2%;
    padding-left: 0px;
    padding-right: 0px;
    background-image: url("../images/video-sec.png");
    width: 100%!important;
    background-size: cover;
    height: 970px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 13em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}
.clms h1 {
    text-align: center;
    color: #54ab1a;
    font-weight: 900;
    padding-bottom: 0px;
}
.clms p {
    text-align: center;
    line-height: 21px;
    width: 85%;
    margin: 0 auto;
}
.price-bg {
    background-color: #f6f6f6;
    width: 100%;
    padding-top: 44px;
    padding-bottom: 93px;
}
.main-box-3 img {
    width: 36%;
}
button.paymnthly
{
    color: #ffffff;
    background-color: #54ab1a;
    border-color: #ccc;
    padding: 7px 25px 7px 25px;
    border-radius: 7px;
    margin-top: 23px;
}
.plan p {
    text-align: left;
}
.plan-bg {
    background-image: url("../images/plan-bg.png");
    width: 100%!important;
    background-size: cover;
    height: 400px;
    margin-top: -32px;
}
.plan-1 p {
    font-size: 40px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    line-height: 1;
    padding-top: 62px;
    padding-left: 20px;
    padding-right: 20px;
}

.footer {
   background-color: #54ab1a;
    padding-bottom: 102px;
    padding-top: 22px;
}
.footer p {
    color: #fff;
    text-align: justify;
    padding: 0;
    font-size: 17px;
    display: inline-block;
    font-weight: 600;
    margin: 13px 0;
}

.ftr-bx-1 i.fa.fa-phone {
    color: #fff;
    font-size: 22px;
    margin-right: 12px;
}
.footer-bx-2 p {
    color: #fff;
}
.ftr-bx-1 i.fa.fa-map-marker {
    color: #fff;
    font-size: 22px;
    margin-right: 12px;
}
span.addrs-2 {
    color: #fff;
}
.footer-bx-2 p {
    text-align: left;
}
p.addrs {
    color: #fff;
    font-size: 15px;
    padding-top: 9px;
}
 p.addrs-1 
 {  color: #fff;
    font-size: 17px;
    font-weight: bold;
}
footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
img.line {
    width: 76%;
    margin: 0 auto; text-align: center; display: block;
}
.socialicon {
    margin-left: 27px;
    padding-top: 9px;
}
.ftr-bx-1 {
    border-right: 2px solid #d1ffb6;
}

.ftr-cpy p {
    text-align: center;color:#fff;
}
.socialicon img {
    width: 43px;
    padding-left: 20px;
    padding-top: 8px;
}
.ftr-cpy {
    background-color: #6a6a6a;
    padding: 0;
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 12px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #54ab1a;
  border-radius: 12px;
}

.flip-card-front {
 background-color: #54ab1a;
    color: #fff;
}
.flip-card-back p {
    padding-top: 0;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.flip-card-back {
  background-color: #54ab1a;
  color: #fff;
  transform: rotateY(180deg);display: flex
}
th.tble-logo img {
    width: 33%;
    margin-top: 1px;
    margin-bottom: -8px;
}
.feat thead {
    background-color: #54ab1a;
    text-align: center;
}
.feat th {
    padding-top: 0px;font-size: 20px!important;
}
table { width: 100%; }
.feat.table>thead>tr>th {
    width: 17%;
    color: #fff;
    text-align: center;
    border-bottom: 2px solid #a8d39b;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    line-height: normal;
}
th.tble-logo img {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 41%;
    vertical-align: text-top;
}
table.table.table-striped.feat tbody tr td:nth-child(3) {
    border-right: 0;
}

table.table.table-striped.feat td {
    text-align: center;
    color: #54ab1a;
    font-family: 'Oxygen';
    font-weight: bold;
    border-right: 3px solid #54ab1a;
    padding: 7px 0;
    vertical-align: middle;
    border-top: 0;
    font-size: 20px;
}
/*tr.tble-1 {
    background-color: #95d0848c!important;
}
tr.tble-2 {
    background: #f0f0f0;
}*/
.table-striped tbody tr:nth-of-type(odd) {
    background: rgba(0,0,0,.05);
}
tr.tble-3{background-color: #6a6a6a!important;}
tr.tble-3 td {
    color: #fff!important;
}
.flip-card-front img {
padding-top: 57px;
}
.flip-card-front p {
    font-size: 17px;
    padding-top: 22px;
    color: #fff; font-weight: 700;
}
.space-line {
    padding-bottom: 30px;
}
.space-line-hd 
{
padding-bottom: 50px;
}
.hd-p-1 {
    padding-top: 8px;
}
@media (max-width: 480px) {
  footer {
    flex-direction: column;
  }
  
  footer i{
    order: 2;
  }
}
/*Mobile Resposive*/
@media(max-width: 767px) 
{
    .navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>span {
        font-size: 16px;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
    }
.beagi p 
{
    font-size: 15px;
    text-align: center; text-align-last: center;
    line-height: 25px; margin: 0 auto;
}

.banner-btn {
    margin-top: 20px; display: block;
    margin-left: 0%; text-align: center; margin-bottom: 3em;
}
ul.nine-points {
    margin-top: 0px;
}

.flip-card {
    width: 100%;
    padding-left: 0;
    margin-bottom: 25px; height: 265px;
}
.flip-card-front img {
    padding-top: 36px;
}
.space-line {
    padding-bottom: 0px;
}
button.bkademo-1 {
    padding-left: 41px;
    padding-right: 39px;
    margin-right: 4px;
}
.video{height: auto;
    background: no-repeat;
    padding: 0 0 1em;}
.price-circle{width: 56%!important;}
.ftr-bx-line {
    border-right: none;}
.loght-green-lf-bg{display: none;}
.loght-green-lf-bg1{display: none;}
.circle{margin-left: 0 !important;}
.banner-bg::before { content: none; }
.video-content iframe {
    width: auto;
    height: 288px;
}
.wrapper-home h1 { font-size: 26px;  }
.funnel-group::before { bottom: -10em; background-size: 180px; }
.price-bg-gray::before { background-size: 140px; }
.price-list-one h5 { font-size: 20px; }
h1.plan-one {
    font-size: 15px; }
.table-group {
    overflow-x: auto;
}
.feat th, table.table.table-striped.feat td {
    
    font-size: 15px!important;
}
th.tble-logo img { max-width: 130px; }
.plan-1 p { font-size: 25px; padding: 0; }
.flt-lft-line {
    
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.ftr-bx-line img {
    width: auto;
    
    vertical-align: top;
}
.address {
    display: block;
    
}
.footer p { margin: 8px 0; }


}
@media screen and (min-device-width: 320px) and (max-device-width: 568px)
{
    .ttttt
    {
    width: 100%;
    margin: 0 auto;
    }
    .footer-bx-2 {
    margin: 0 auto;
    padding-top: 0; text-align: center;
}
.flt-lft-line{border-right: none!important;}

.footer img {
    width: auto;
    padding-top: 20px;
    margin-left: 0;
}

}

@media screen and (min-device-width: 320px) and (max-device-width: 667px)
{
    .price-circle {
    width: 53%!important;
}
.ftr-bx-line img {
   margin-left: 0;
}
.plan-bg{height: auto !important;}
.ftr-screen img {
    width: 100%;
    position: relative!important;
    margin-top: 0px!important;
    margin-left: 0;
}
.plan-1 p{padding-top: 0px!important;}
button.planbtn-1{margin-left: 26%!important;margin-top: -4px!important;}


}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) 
{
.flip-card-inner
{
    width: 100%;
    height: 76%;
}
.flip-card-front img 
{
    width: 25%;
    padding-top: 64px;
}


}
li.active {
    padding-right: 22px;
}
button.bkademo i.fa.fa-play, .btn-home i.fa.fa-play{
    background-color: #f0f0f0;
    padding: 10px 7px 11px 14px;
    text-align: left;
    margin-left: -24px;
    margin-top: -12px;
    margin-bottom: -10px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    color: #54ac1a; vertical-align: middle;
}
span.play-btn-1 {
    padding-left: 8px;
}
button.bkademo-1 {
    padding-left: 38px;
    padding-right: 38px;
    margin-right: 15px;
}

.utb-c-btn {
    margin-top: 19%;
    margin-left: -8%;
}
img.bdr-btm-line {
    padding-top: 0px!important;
    width: 37%;
    height: 2px;
}
.trpy img{width: 100%; max-width: 700px; display: block; margin: 0 auto;}
.price-list-one h1,h2 {
   color: #fff;
}
.ftr-bx-line img {
    width: auto;
    max-width: 19px;
    margin-right: 10px;
}
.ftr-bx-line p a
 {
    color: #fff;
    text-decoration: none; font-size: 24px;
}
img.line1 {
    width: 27%;
    margin-left: 37%;
}
.price-list-one h1 {
    color: #54ab1a;
    font-weight: 600;
    text-align: center;
}
.price-list-one h5 {
    text-align: center;
    color: #595a5c;
    font-size: 23px;
    font-weight: 900;margin: 0;
}
.price-list-one p {
    color: #54ab1a;
    text-align: center;
}
.price-circle {
    border: 8px solid #acb5bc;
    width: 33%;
    margin: 0 auto;
    border-radius: 50%;
    padding: 36px 11px 34px 12px;
    text-align: left!important;
    background-color: #54ae0e;
    margin-top: 34px;
}
.price-circle h6 {
    color: #fff;
    font-size: 51px;
    font-weight: bold;
}
span.mnth {
    font-size: 22px;
    padding-left: 37px;
}
.price-circle h4 {
    margin-top: -16px;color: #fff;
}
.price-bg-gray {
    background-color: #f6f6f6;
    padding-bottom: 130px;
    margin-top: 2em;
    position: relative;
    padding: 2em 0 6em;
}
.price-list-one {
    padding-top: 35px;
    padding-bottom: 35px;
}
h1.plan-one {
    font-size: 18px;
    color: #54ab1a;
    font-weight: 800;
    padding-top: 35px;
    padding-bottom: 25px;
}
.ftr-screen img {
    width: 100%;
    position: absolute;
    margin-top: -40px;
}
span.ftr-leads {
    color: #54ab1a;
}
.main-box-3 h1 {
    color: #54ab1a;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0px; display: inline-block;
}
.main-box-3 h1::after {
    content: '';
    display: block;
    position: relative;
    top: 10px;
    width: 80%;
    left: 10%;
    border-top: 3px solid #ffb300;
}
.ttttt img {
   float: left;
    width: auto;
    max-width: 34px;
    vertical-align: middle;
}
span.ftr-phn {
    padding-top: 14px;
    margin-top: 16px;
    position: absolute;
    font-size: 18px;
    margin-left: 0;
    font-weight: 900;
}

span.ftr-phn1 {
    padding-top: 14px;
    margin-top: 16px;
    position: absolute;
    font-size: 14px;
    
}
.ttttt img {
    padding-top: 0;
    margin-left: 12px;
}
span.ftr-youtube a img {
    margin-top: 7px;
}
.trs iframe {
    margin-top: 71%;
    position: absolute;
    margin-left: -65px;
}
.footer ul li a { color: #fff; }
.loght-green-lf-bg {
    background-image: url("../images/green-bg-left.png");
    height: 943px;
    width: 495px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: -254px;
    margin-left: -7%;
}

.loght-green-lf-bg1 {
    height: 775px;
    background-image: url("../images/green-bg-right.png");
    background-size: cover;
    float: right!important;
    width: 695px;
    position: absolute;
    margin-left: 52%;
    margin-top: -18%;
}

.block{
    text-align: center;
    vertical-align: middle;
    padding-top: 29px;
}
.circle {
    background: #54ab1a;
    border-radius: 200px;
    color: #fff!important;
    font-weight: 600;
    width: 200px;
    font-size: 63px;
    border: 8px solid #bfb9b9fa;
    font-family: 'Open sans';
    padding: 40px 0 0;
    height: 200px;
    vertical-align: middle;
    align-items: center;
    display: inline-block;
    line-height: 32px;
}
span.dollar {
    font-size: 38px;font-weight: 700;
}
span.mnth-p {
    font-size: 20px;
    margin-left: -30px;
    position: absolute;
    text-align: center!important;
}
span.qutn-mrk {
    color: #fff;
}
.ftr-links {
    margin-top: 2%;
}
.nabar-header {
        width: auto;
    max-width: 72%;
}



@media(max-width: 1200px){
    .navbar-default .navbar-nav>li:last-child { margin-left: 0; }
    .navbar-default .navbar-nav>li>a { font-size: 15px; }
    .beagi h1 { font-size: 40px; }
    .banner-btn a {
    margin: 1em auto;
    }
    .banner-btn {
            flex-direction: column;
    margin-top: 0;
    text-align: left;
    }
    .beagi p { text-align: left; text-align-last: left; }
    .flt-lft-line { height: auto; }
}
@media screen and (min-device-width: 767px) and (max-device-width: 1024px) {
    .navbar-default .navbar-nav>li>a {
        font-size: 14px;
        padding: 16px 10px;
    }
   
}
@media(max-width: 991px){
    .navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>span { color: #fff;}
    .navigation .navbar-nav {
        display: none;
    }
    .flip-card-back p { font-size: 16px; }
    .flip-card-front p {
    font-size: 15px;
    padding-top: 16px; }
    .price-bg-gray {
    margin-top: 0;
    padding: 0em 0 3em;
}
.plan-1 p {
    font-size: 29px; }
    .plan-bg { height: auto; }
    .flt-lft-line {
        border-right: 0; height: 235px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    color: #fff;
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    }
    .footer { padding: 25px 0; }
    .ftr-links {
       max-width: 100%;
    flex: 0 0 100%; text-align: center;
    }
    table.table.table-striped.feat td { font-size: 15px; }
    .footer .col-xs-12.col-sm-12.col-md-4.col-lg-4 {
    display: none;
}
.ftr-bx-line img {
    width: auto;
    
    vertical-align: top;
}

.agilead-pg-set1 {
    border-bottom: 1px solid #efefef;
}
div#navbar {
    display: block;
}
.navbar a { color: red; }

a#topiclinks-text {
    color: #ffb300;
    position: absolute;
    right: 25px;
    top: 30px;
}
nav.navbar-default.navbar-static-top.fixed {
    position: relative;
}
.topics-dropdown {
    position: absolute;
    background: #57ab1a;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    color: #fff; display: none;z-index: 9999999999;
}
.navbar-default .navbar-nav>li>a {
    color: #fff; font-size: 16px;
}
ul.nav.navbar-nav li {
    margin-top: 0;
    padding: 0;border-bottom: 0px solid #ffffff8f;
}
.navbar-default .navbar-nav>li>a.active {
    padding: 15px 15px; color: #fff;border-bottom: 0;
}
.navbar-static-top {
    margin-bottom: 0; 
    border-bottom: 1px solid #ccc9;
}
.navbar-default .navbar-nav>li:last-child {
    margin-left: 0;
    margin-top: 0;
    padding: 0 1em; 
}
.navbar-default .navbar-nav>li:last-child > a {
   
    background: #ffb300;
}
table.table.table-striped.feat td { padding: 4px 28px; }
.beagi h1 { font-size: 30px; }
.banner-bg::before { background-size: 390px; }
.banner-btn {
    flex-direction: column; margin-top: 0;
}
.banner-btn a {
    margin: 1em auto;
    display: block;
}
.container {
    max-width: 100%;
    width: 100%;
}

}
@media(max-width: 767px){ 
    .side-points .col-md-6 {
    display: flex;
    align-items: center;
    justify-content: center;
}
    .form-group .col-sm-10 {
    flex: 0 0 100% !important;
    max-width: 100% !important; width: 100% !important;
}
    .video {
    background: no-repeat;
    height: auto;
    padding: 1em 0;
}
.video-content iframe { width: 100%; height: 280px; }
    .flip-card { margin: 12px 0; }
    .ftr-screen img { position: relative; margin-top: 0; }
    .navbar-default .navbar-nav>li>a.active {
    padding: 10px 15px; color: #fff;border-bottom: 0;
}
ul.nav.navbar-nav li {
    margin-top: 0;
    padding: 0;border-bottom: 1px solid #ffffff8f;
}
    .phone-top{
        float: left !important; 
        margin-left: 30px;
    }
    ul.nav.navbar-nav > li:nth-child(7){ padding: 0 0 10px;}
    .beagi {
        max-width: 285px;
        margin: 0 auto;
    }
    .new-update-home .beagi {
        max-width: 320px;
        margin: 0 auto;
    }
    .new-update-home .beagi h1 {
        text-align: center;
        padding: 0;
        font-size: 18px !important;
    }
    .beagi h1{font-size: 19px!important;display: block;}
.beagi h2{text-align: center!important; font-size: 17px; padding-bottom: 10px; }
.bullet-lists { display: flex; align-items: center; flex-direction: column; }
.banner-btn a {
    margin-right: 0;
    display: block;
}
.price-list-one h5 { font-size: 20px; }
h1.plan-one { font-size: 15px; padding-top: 20px; padding-bottom: 15px; }
.price-bg-gray { padding: 2em 0 3em; }
.footer { padding: 1em 0; }
.form-group {
    display: block;
}




}

/****** Responsive Design Ends ***/



.thankyou h2 {
    color: #454545;
    border: 0;
    padding: 0;
    font-size: 30px;
}
.thankyou {
    text-align: center;
    padding: 2em 0 0;
}
.next button.btn.btn-primary.step-btn.company {
  width: 150px;
}
.navbar-default .navbar-nav>li:last-child a.active {
    padding: 10px 45px;
}


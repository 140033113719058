body { font-family: 'Open Sans', sans-serif !important; margin: 0; padding: 0; width: 100%; background: #fff !important;
  scroll-behavior: smooth;
  background-size: cover; position: relative; color: #454545 !important; font-size: 16px !important;
}
* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
.onboarding-form .bs-stepper-content::before {
content: none;
background: none;
}
header.jsx-3979001018 {
position: relative;
position: -webkit-sticky;
position: sticky;
top: 0;
background-color: #ff4040;
width: 100%;
height: var(--header-height,auto);
padding: 0 var(--header-navigation-compact-px,7%);
padding-top: 30px;
margin: 0 auto;
z-index: 100;
}
button.step-trigger .bs-stepper-circle {

  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  padding: .5em 0;
  margin: 0;
  line-height: 1em;
  color: #fff;
  background-color: transparent;
  border-radius: 1em;
  font-size: 15px;

}


.container.jsx-3979001018 {
max-width: 1290px;
margin: 0 auto;
position: relative;
}
.container.jsx-2180139827 {
display: flex;
}

.logo-link.jsx-96647813 {
display: block;
}
a {
color: #3047ec;
-webkit-text-decoration: none;
text-decoration: none;
}
.horizontal_spacer.jsx-2180139827 {
-webkit-box-flex: 1;
flex-grow: 1;
}
.cta-wrapper.jsx-2180139827:not(:last-child) {
margin-right: 5px;
}
@media (max-width: 1439px){

.cta.jsx-4163279887 .email-form {
  max-width: 278px;
}
}

.email-form.jsx-403594908 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
span.jsx-75452321 {
-webkit-clip: rect(1px,1px,1px,1px);
clip: rect(1px,1px,1px,1px);
-webkit-clip-path: inset(50%);
clip-path: inset(50%);
height: 1px;
width: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
}
.email-form__input-wrapper.jsx-403594908 {
-webkit-flex: 999 0 0;
-ms-flex: 999 0 0;
flex: 999 0 0;
width: 100%;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
  min-width: auto !important;
}
}
.email-form__input.jsx-403594908 {
width: 100%;
outline: 0;
border: 2px solid #0a2351;
color: #181818;
border-radius: 6px 0px 0 6px;
font-size: 15px;
line-height: unset;
}
.email-form__input.jsx-403594908 {
line-height: 1.4;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
input {
font: inherit;
margin: 0;
padding: 0;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
  min-width: auto !important;
}
}
.email-form__submit--black-fill.jsx-403594908 {
background-color: #ff4040;
color: #ffffff;
}
.email-form__submit.jsx-403594908 {
display: inline-block;
border-radius: 0px 6px 6px 0px;
padding: 12px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
-webkit-transition: all 200ms;
transition: all 200ms;
cursor: pointer;
-webkit-flex-shrink: 0;
-ms-flex-negative: 0;
flex-shrink: 0;
font-weight: bold;
position: relative;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
button {
border: none;
margin: 0;
padding: 0;
text-align: inherit;
background: transparent;
color: inherit;
font: inherit;
line-height: normal;
-webkit-font-smoothing: inherit;
-moz-osx-font-smoothing: inherit;
-webkit-appearance: none;
}

span.jsx-148622701, div.jsx-148622701 {
font-size: 15px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
line-height: 1.4;
}

@media (min-width: 1024px) {
span.jsx-3289410788, div.jsx-3289410788 {
  font-size: 26px;
}
.link.jsx-277724099 {
  margin-right: -7px;
}
}
.link.jsx-277724099 {
  display: inline-block;
  padding: 7px 12px;
  position: relative;
  width: auto;
  color: rgb(24, 24, 24);
  transition: all 250ms ease 0s;
}

main {
  overflow: hidden;
  outline: 0;
}
@media (min-width: 768px) {

.cta-wrapper.jsx-360272744 {
  justify-self: unset;
}
span.jsx-3289410788, div.jsx-3289410788 {
  line-height: 1.4;
  -webkit-letter-spacing: unset;
  -moz-letter-spacing: unset;
  -ms-letter-spacing: unset;
  letter-spacing: unset;
  font-size: 22px;
}
.subheading.jsx-2340029290 {
  margin: var(--margin);
  margin-bottom: 36px;
  max-width: 800px;
}
.headline.jsx-2340029290 {
  margin-bottom: 24px;
}
.content.jsx-3751704983 {
  grid-column: left-content;
}
.two-columns.jsx-3751704983 .content.jsx-3751704983 {
  grid-column: left-content / span 3;
}
.two-columns.jsx-3751704983 .hero.jsx-3751704983 {
  grid-template-columns: 1fr [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr 1fr;
}
section.jsx-618185480 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.email-form__input.jsx-403594908 {
  min-width: 210px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  line-height: 1.4;
}

.primary.jsx-360272744 {
  margin: 0 0px 22px 0;
}
.cta.jsx-360272744 {
  display: inline-block;
}
.email-form__submit.jsx-403594908 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.hero-wrapper.two-columns.jsx-3751704983 {
  grid-template-columns: [content] 14fr;
}
  .hero-wrapper.jsx-3751704983 {
    padding: 50px 0;
  }
}

.hero-wrapper.jsx-3751704983 {
  display: grid;
  grid-template-columns: [left-gutter] 1fr [content] 12fr [right-gutter] 1fr;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 50px 0;
}
.hero-wrapper--white {
  background-color: #ffffff;
}


.hero.jsx-3751704983 {
  grid-column: content;
  position: relative;
  display: grid;
  grid-template-columns: [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr;
  grid-column-gap: 24px;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  justify-items: center;
}

.content.jsx-3751704983 {
  grid-column: left-content / span 3;
  -webkit-order: undefined;
  -ms-flex-order: undefined;
  order: undefined;
}
.content.jsx-3751704983 {
  display: grid;
  text-align: center;
  justify-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  z-index: 1;
  position: relative;
}


.headline.jsx-2340029290 {
  max-width: 1024px;
  margin-bottom: 16px;
  margin-top: 0;
}
@media (min-width: 990px) {

span.jsx-1631410664, div.jsx-1631410664 {
  font-size: 60px;
  font-weight: 600;
}
}

span.jsx-1631410664, div.jsx-1631410664 {
  font-size: 60px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}

.subheading.jsx-2340029290 {
  margin-bottom: 21px;
}


span.jsx-3289410788, div.jsx-3289410788 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
}


.cta-wrapper.jsx-360272744 {
  justify-self: stretch;
}


.cta.jsx-360272744 {
  margin: 0 0 22px 0;
}

.plan-column-group {
margin: 0 auto;
width: 100%;
max-width: 1290px;
padding-top: 24px;
}
.plan-column-group__header {
margin-bottom: 20px;
text-align: center;
font-family: Graphik,Helvetica,Arial,sans-serif;
}
.rich-text p:last-of-type {
margin-bottom: 0;
}

.rich-text p {
margin-bottom: 1em;
line-height: 1.7;
}
p {
margin: 0;
}
.plan-column-group__children {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
}
@media (min-width: 768px){
.plan-column-group__header {
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}
.plan-column-group-wrapper--tan {
  background-color: #f4f0eb;
}

main {
  padding-top: 70px;
}
}
.plan-column-group-wrapper {
padding: 0 24px 20px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper:first-child {
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}
}

.plan-column-group__children>* {
  margin: 0 10px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper {
  margin-bottom: 0px;
}
}
.plan-columns-wrapper {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid #f4f0eb;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (min-width: 1024px) {

.plan-columns {
  min-width: 400px;
}
}

.plan-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (min-width: 768px) {

.plan-columns>*:last-child {
  border-bottom-right-radius: 8px;
}
}

@media (min-width: 768px) {

.plan-columns>*:first-child {
  border-bottom-left-radius: 8px;
}
}
.pricing-tier-card.pricing-tier-card--rounded {
  border-radius: 6px;
}
@media (min-width: 665px) {

.plan-columns>* {
  border-top: 2px solid #f4f0eb;
}
}
.plan-columns>* {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.pricing-tier-card {
  background-color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 395px;
  width: 100%;
}

@media (min-width: 768px) {

.pricing-tier-card__section:first-child {
  border-bottom: 2px solid #f4f0eb;
}
}

.pricing-tier-card__section {
  padding: 22px 28px 10px 28px;
}

.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
@media (min-width: 665px) {

.pricing-tier-card__badge-wrapper--hide-on-mobile {
  display: block;
}
}
.pricing-tier-card__badge-wrapper--hide-on-mobile {
display: none;
}
.pricing-tier-card__badge-wrapper {
height: 45px;
margin-top: -5px;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

.pricing-tier-card__category {
padding-bottom: 0 !important;
}

@media (min-width: 1024px) {

span.jsx-3980862901, div.jsx-3980862901 {
  font-size: 36px;
}
}
@media (min-width: 990px) {

span.jsx-3980862901, div.jsx-3980862901 {
  font-size: 30px;
  line-height: 1.2;
}
}
span.jsx-3980862901, div.jsx-3980862901 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
}

.pricing-tier-card__title.graphik-font span {
font-family: Graphik,Helvetica,Arial,sans-serif;
}
@media (min-width: 768px) {

span.jsx-4108527491, div.jsx-4108527491 {
  font-size: 36px;
  line-height: 1.2;
}
}
span.jsx-4108527491, div.jsx-4108527491 {
font-size: 36px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
line-height: 1.2;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.pricing-tier-card__description {
height: 75px;
max-width: 520px;
}
span.jsx-166584131, div.jsx-166584131 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.cta-link--black-outline {
    border: 2px solid #0a2351;
    background-color: #0a2351;
    color: white !important;
}
.cta-link--wide {
width: 100%;
}
.cta-link {
display: inline-block;
border-radius: 6px;
padding: 12px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
-webkit-transition: all 200ms;
transition: all 200ms;
cursor: pointer;
}
span.jsx-4266488777, div.jsx-4266488777 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

@media (min-width: 768px) {

.pricing-tier-card__price-for-period {
  font-size: 20px;
}
}

@media (min-width: 1270px) {

.pricing-tier-card__show-on-desktop {
  display: block;
}
}

.pricing-tier-card__show-on-desktop {
  display: none;
}
.pricing-tier-card__price-for-period--dollar-amount {
font-family: Graphik,Helvetica,Arial,sans-serif;
font-weight: bold;
line-height: 1.4;
}
.pricing-tier-card__price-for-period-subtext {
height: 17px;
}
.copy-right {
position: relative; font-weight: 700;
width: 100%;
background: #6a6a6a;
padding: 20px 0px;
text-align: center;
color: #fff;
font-family: 'Open Sans', sans-serif;
border-top: 0px solid #ccc; font-size: 16px;
}
.copy-right ul li a {
color: #fff;
}

.copy-right ul {
margin: 0;
list-style: none;
color: #fff;
padding: 0;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}
.signup__teams__sidebar__product-box-title-text {
font-weight: 700;
}
.signup__teams__container * {
box-sizing: border-box;
}
.flex-row.gap-4>:last-child {
margin-right: 0;
}
*, ::after, ::before {
border-width: 0;
border-style: solid;
border-color: rgba(0,0,0,.1);
}
.flex-col {
flex-direction: column;
}
.flex {
display: flex;
}
.application__component {
color: #222;
background-color: #f1f1f1;
height: 100%;
}

.signup__teams__body {
font-family: 'Open Sans', sans-serif;
font-size: 15px;
font-weight: 400;
letter-spacing: .01em;
}
.justify-between {
justify-content: space-between;
}
.signup__teams__sidebar {
background-color: transparent;
width: 420px;
padding: 80px 25px 0;
justify-content: space-between;
overflow: hidden;
}
@media (min-width: 1025px){}
.signup__teams__sidebar__container {
  order: 3;
  flex: 0 0 auto;
}
@media (min-width: 768px){}
.form-box {
margin-bottom: 50px !important;
  margin-top: 50px !important;
  flex: 0 0 auto;
  width: 50%;
}

.email-not-verified {
text-align: center;
font-size: 25px;
margin: 35px;

}
.MuiPaper-root.MuiCard-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
margin: 0vh auto !important;
}
.jsx-1928597095.pricing-tier-card__price-for-period {
text-align: center !important;
font-size: 23px !important;
}

.StripeElement {
margin: 15px auto;
padding: 10px 12px;
color: #32325d;
background-color: white;
border: 1px solid #ccc;
border-radius: 4px;
}

.StripeElement--webkit-autofill {
background-color: #fefde5 !important;
}

.product {
width: 100%;
max-width: 450px;
margin: auto;
box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
border-radius: 10px;
overflow: hidden;
}

.btn-pay {
display: block;
width: 100%;
border: none;
background: linear-gradient(
  135deg,
  rgb(49, 0, 62) 0%,
  rgb(195, 40, 110) 100%
);
color: #fff;
padding: 10px;
font-size: 18px;
cursor: pointer;
}

.product-info {
padding: 0 16px;
}

h3.product-title {
font-size: 28px;
margin-bottom: 15px;
}

h4.product-price {
font-size: 24px;
margin: 0;
margin-bottom: 30px;
color: #777;
font-weight: 500;
}
.step-btn {
width: 100%;
}
.next {
margin-top: 30px;
}
label {
width: 100%;
}
.bs-stepper .step-trigger {
  display: inline-flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: #6c757d !important;
  text-align: center !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: .25rem !important;
  transition: background-color .15s ease-out,color .15s ease-out !important;
  padding-bottom: 31px !important; position: relative; z-index: 99;
}
.form-control1 {
display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}

.signup__teams__sidebar__product-box {
border-top: 10px solid #fbc916;
background-color: #fff;
padding: 24px 24px 30px;
}
.signup__teams__container * {
box-sizing: border-box;
}
p.text.o__muted {
padding: 5px 0;
}

.signup__teams__container * {
box-sizing: border-box;
}

.signup__teams__option-divider {
text-align: center;
position: relative;
z-index: 1;
font-size: 16px;
}
.signup__teams__option-divider:before {
background-color: transparent;
border-top: 1px dotted #454545;
display: block;
content: '';
position: absolute;
width: 100%;
height: 2px;
left: 0;
top: 50%;
z-index: 0;
}
.h-2 {
height: 8px;
}
.h-5 {
height: 20px;
}

body {
scroll-behavior: smooth;
overflow-x: hidden;
}
.mar-bt {
margin-bottom: 6px !important;
margin-top: 6px !important;
}

.step {
position: relative;
}
/*.step.active::before {
background: #ff4040;
}*/
.active .bs-stepper-circle {
  background-color: transparent !important;
}
.bs-stepper
.step .step-trigger {
  background: #54ab1a80 !important;
  display: inline-block !important;
  color: #fff !important;
  padding: 0 10px 8px !important;
  border-radius: 30px !important;
  margin: 0 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;opacity: 1 !important;
}
.bs-stepper .line, .bs-stepper-line { display: none; }
.bs-stepper .step.active .step-trigger::before {
  background: #54ab1a !important;
  transition: all 0.5s ease-in-out 0s;
  right: -21px; opacity: 1;
}
.bs-stepper-header .step:last-child .step-trigger::before {
  content: none;
}
.step-trigger::before {
  position: absolute;
  content: "";
  background: #ecebeb57;
  height: 5px;
  width: 18%;
  right: 0;
  z-index: -1;
  bottom: auto;
  top: 18px; opacity: 0;
  left: auto;
  margin: 0 auto;
  text-align: center;
}
.bs-stepper-header .step:nth-child(1) .step-trigger {
  margin-left: 0;
}
.bot-lp-real-box .new-pricing-plan table tr td h2 {
background: #ff4040;
}
.bs-stepper .step.active .step-trigger {
  color: #fff !important;
  opacity: 1;
  background: #54ab1a !important;
}
.new-pricing-plan table {
max-width: 1140px;
margin: 0 auto;
}
.new-pricing-plan table tr {
display: flex;
}
.new-pricing-plan table tr td:nth-child(3n+1) {
margin-left: 0;
}
.new-pricing-plan table tr td {
float: left;
width: 33%;
margin-left: 0%; box-shadow: unset;
}
.hero-content {
text-align: center;
/* background: #ff4040;*/
background-image: linear-gradient(to right, #fe9365, #feb798) !important;
padding: 5em 0;
}
.hero-content h1 {
color: #fff;
font-size: 28px;
}
.hero-content p {
color: #fff;
font-size: 22px;text-transform: capitalize;
}
.new-pricing-plan {
position: relative; margin-bottom: 3em;
}
.new-pricing-plan::before {
background-image: linear-gradient(to right, #fe9365, #feb798) !important;
content: "";
position: absolute;
left: 0;
right: 0;
width: 100%;
height: 9%;
z-index: -1;
}
.new-pricing-plan table tr td p {
color: #454545;
font-size: 16px;
letter-spacing: 0.3px;
text-align: center; padding: 0 1em;
}
.pricing-title span.price-range {
font-size: 45px;
font-weight: 500;
color: #454545;
font-family: 'Open Sans';
}
.new-pricing-plan table tr td:nth-child(3n+2) p, .new-pricing-plan table tr td:nth-child(3n+2) .pricing-title span.price-range, .new-pricing-plan table tr td:nth-child(3n+2) .starts, .new-pricing-plan table tr td:nth-child(3n+2) .month, .new-pricing-plan table tr td:nth-child(3n+2) .features-lists > p {
  color: #fff;
  border-color: #fff;
}
.new-pricing-plan table tr td:nth-child(3n+2) .cta-link {
  background: #fff !important;
  color: #fe9365 !important;
}
.new-pricing-plan table tr td:nth-child(3n+2) .cta-link:hover{
  background: transparent !important;
  color: #fff !important;
}
.main-area {
  padding-bottom: 3em;
}
.pricing-title {
margin: 0 auto;
text-align: center;
padding: 2em 0 1em;
max-width: 250px;
}
.price-range a {
padding: 7px;
display: block;
margin: 1em auto;
text-align: center;
max-width: 160px;
border-radius: 3px;
}
.new-pricing-plan table tr td h2 {
  background: hsla(0,0%,100%,.3);
  text-align: center;
  font-size: 20px;
  color: #fff;
  padding: 14px 0;
  border-right: 1px solid #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 1em;
}
.new-pricing-plan table tr td:last-child h2 {
border-right: 0;
}
.new-pricing-plan table tr td:hover { cursor: pointer; transition: 0.4s ease 0s; background: #8e8888a3; }
.new-pricing-plan table tr td .features-lists ul li i {
float: left;
vertical-align: middle;
padding: 4px;
background: linear-gradient(to right, #fe9365, #feb798) !important;
border-radius: 100%;
color: #fff;
font-size: 10px;
font-weight: 700;
margin: 4px 5px 0;
}
.price-range.__cta.pricing-tier-card__cta--bottom {
  text-align: center;
  padding: 1em 0;
}
.new-pricing-plan table tr td .features-lists ul li {
list-style: none;
padding: 4px 0; display: block;
}
.new-pricing-plan table tr td .features-lists p {
text-align: left;
}
.new-pricing-plan table tr td .features-lists ul {
padding: 0;
text-align: left;
}
.new-pricing-plan table tr td .features-lists {
padding: 1em 1.5em;
text-align: left;
}
.new-pricing-plan table tr td .features-lists > p {
text-align: left;
border-bottom: 2px solid #696464;
display: inline-block;
margin-bottom: 15px; padding: 0;
}
.starts {
font-size: 15px;
color: #454545;
line-height: 0;
font-style: italic;
text-align: left;
}
.new-pricing-plan table tr td:nth-child(3n+1), .new-pricing-plan table tr td:nth-child(3n+3) {
  margin-left: 0;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.new-pricing-plan table tr td:hover h2, .new-pricing-plan table tr td:hover .starts, .new-pricing-plan table tr td:hover .pricing-title span.price-range, .new-pricing-plan table tr td:hover .month, .new-pricing-plan table tr td:hover .features-lists > p,
.new-pricing-plan table tr td:hover p { color: #fff; }
.new-pricing-plan table tr td:hover .price-range a { background: #454545; }
.logo-wrapper {
margin: 0 auto;
text-align: center;
}
.new-pricing-plan table tr td:nth-child(3n+2) {
  box-shadow: unset;
  border: 0px solid #ccc;
  background: linear-gradient(to right, #fe9365, #feb798) !important;
}
.bs-stepper-label {

font-size: 15px;
}
table#business_hours .form-select {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important;
  padding: 8px 10px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
}
.form-group .table tr td input {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 30px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
p.error-message {
  font-size: 16px;
  padding: 10px 0 0;
  font-weight: 400;
  font-family: system-ui;
}
button.btn.btn-primary.step-btn, #add, button.btn.btn-primary, .form-select.btn-primary{
  display: block;
  width: auto;
  margin: 0 auto;
  padding: 6px 2em;
  border-radius: 20px;
  background: transparent !important;
  border: 0;
  font-size: 16px;
  font-weight: 700;position: relative;
  z-index: 999;
      color: #54ab1a; transition: all 0.5s ease-in-out 0s;
  border: 2px solid; overflow: hidden;

}
.form-group label {
  color: #454545; margin-top: 1em; font-size: 18px;
  font-weight: 400; position: relative; z-index: 999; text-transform: capitalize;
}

.bs-stepper-content .form-control1 {
  font-size: 16px;
  border: 0;
  color: #454545;
  padding: 10px 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans';
  position: relative;
  z-index: 99;
}
.bs-stepper-content input:focus, .bs-stepper-content select:focus {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important; }
button#subscribe {
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  border-radius: 30px;
  margin: 0 auto;
}
.MuiCardContent-root.jss2 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid #ccc;
}
input.jsx-403594908.email-form__input::-webkit-input-placeholder, .bs-stepper-content .form-control1::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #454545;opacity: 0.5;
}
input.jsx-403594908.email-form__input::-moz-placeholder,
.bs-stepper-content .form-control1::-moz-placeholder { /* Firefox 19+ */
color: #454545;opacity: 0.5;
}
input.jsx-403594908.email-form__input:-ms-input-placeholder,
.bs-stepper-content .form-control1:-ms-input-placeholder { /* IE 10+ */
color: #454545;opacity: 0.5;
}
input.jsx-403594908.email-form__input:-moz-placeholder,
.bs-stepper-content .form-control1:-moz-placeholder { /* Firefox 18- */
color: #454545;opacity: 0.5;
}

button.btn.btn-primary.step-btn::before, .cta-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #54ab1a;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out; border-radius: 30px;
}
button.btn.btn-primary.step-btn, #add, button.btn.btn-primary, .form-select.btn-primary, .cta-link {
 
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);    
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
button.btn.btn-primary.step-btn:focus {
border: 2px solid #54ab1a !important;
}
.input-group-append .btn-primary.active, .input-group-append .btn-primary:active, .open>.dropdown-toggle.btn-primary {
background: #54ab1a !important; color: #fff !important;
}
.btn-primary:hover { color: #fff !important; }
button.btn.btn-primary.step-btn:hover::before, .cta-link:hover::before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1); color: #fff;
}
.new-pricing-plan table tr td:nth-child(3n+2) .cta-link:hover::before { color: #fff; }
.footer-1{ background: #54ab1a; }
button#coupon_status:hover, button#integrate:hover {
  background: #54ab1a !important; color: #fff;
}
.btn-primary:hover, button.btn.btn-primary.btn-apply::hover {
  color: #fff !important;
  background:#54ab1a !important;
  border-color: transparent !important;
}

span#add:hover {
  background: #54ab1a !important;
}
/*** onboarding styles ***/
.conform-details ul li strong {
  /* background: #cccccc7a; */
  padding: 10px;
  width: 115px;
  display: inline-block;
  font-size: 15px;
  margin: 0 15px 0 0px;
  background: #54ab1a;
  color: #fff;
}
.res .conform-details ul li strong {
  width: 125px;
  color: #fff;
}
.onboarding-form .bs-stepper-header {
  display: block;
}
.onboarding-form .bs-stepper-label, .onboarding-form .bs-stepper-circle { display: none; }
.onboarding-form .bs-stepper .step.active .step-trigger, .onboarding-form .bs-stepper .step .step-trigger { margin: 0; box-shadow: none; background: transparent !important; }
.onboarding-form .step-trigger::before { content: none; }
.onboarding-form .step {
  padding: 0;opacity:1;
}
.onboarding-form .step.active {
  opacity: 1;
}
.onboarding-form .step.active .round::after {
  border: 2px solid #54ab1a;
}
/*.onboarding-form .step:last-child .round:after {
  border: 0;
}*/
.onboarding-form .bs-stepper-content { padding: 0; }

.onboarding-form {
  max-width: 900px;
  margin: 0 auto;
  display: block;
  padding: 1em 0;
}
.onboarding-form .round::before {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 0px solid #6b6969;
  position: absolute;
  content: "";
  background: #54ab1a;
  left: 0;
  top: 0;
  box-shadow: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em, rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
}
.step-content img {
  max-width: 34px;
  vertical-align: -12px;
  padding: 2px;
}
.onboarding-form form {
  padding: 0;
}
.onboarding-form button.btn.btn-primary.step-btn, .onboarding-form #add, .onboarding-form button.btn.btn-primary, .onboarding-form .form-select.btn-primary {
  display: block;
  padding: 3px 25px;
}
.onboarding-form p, .onboarding-form p.fb-noti {
  font-size: 15px; margin: 0; text-transform: none; padding: 5px 0;
}
.onboarding-form .round {
  position: relative;
  padding: 5em 0;
}

.onboarding-form .step .round::after {
  background: #54ab1a;
  position: absolute;
  content: "";
  width: 2px;
  top: 12px;
  left: 8px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #57ab1a;
  height: 100%;
  z-index: -1;
}
.onboarding-form .bs-stepper .content {
  text-align: left !important;
}
.onboarding-form .step:last-child .round::after { content:none; }
.onboarding-form .step:nth-child(5) .round {
  padding: 4em 0;
}
.onboarding-form .step:nth-child(4) .round {
padding: 3em 0;
}
.onboarding-form .step:nth-child(6) .round {
padding: 5em 0;
}
.onboarding-form .step:nth-child(2) .round {
  padding: 11em 0;
}
.onboarding-form .step:nth-child(7) .round {
padding: 11em 0;
}
.step-content {
  min-height: 130px;
  opacity: 1 !important;
  display: block !important;visibility: visible !important;
}
.step-content:nth-child(5) {
  min-height: 104px;
}
.step-content:nth-child(4) {
min-height: 90px;
}
.step-content:nth-child(2) {
  min-height: 273px;
}
.step-content .form-group label {
  font-size: 15px;
  color: #444;
}
.step-content label {
  font-weight: 400;
  padding: 0;
  margin: 0 0 8px;
  font-size: 15px;
}
.onboarding-form .col-md-1 {
  text-align: right;
}
.onboarding-form .step-content p i {
  font-size: 15px;
  padding: 0 4px;
  color: #57ab1a;
}
.onboarding-form .step-content.active {
  opacity: 1 !important;
}
.addmore #drag-text-new, .addmore #drag-text-new-1 {
  font-size: 30px;
  background: #54ab1a;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 50%;
  color: #fff; width: 27px;
  line-height: 0.8; font-weight: 700; display: none;
}
.conform-details ul {
  padding: 0.5em 0 0;
  max-width: 450px;
  margin: 0 auto;
  /* columns: 2; */
}
.conform-details ul li {
  display: block;
  text-align: left;
  padding: 0;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.step-form p {
  font-size: 16px;
}
.step-form .form-group label {font-size: 16px;}
.col-md-1.offer {
  text-align: right;
  padding: 9px 0 0;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  min-width: 400px !important;
  padding: 25px !important;
  text-align: center !important;
}


.updated .form-control1 {
height: auto;
width: 70%;
text-align: left;
float: left;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding: 13px;
  margin: -4px -13px;
  width: 75%;
  border: 0 ;
  font-size: 15px;
  border: 0px solid #ccc;
  border-left: 0;
  border-radius: 0;
}
.updated .conform-details ul li {
text-align: left;
display: inline-block;
width: 100%;
padding: 5px;
margin: 8px 0;
border: 1px solid #ccc;
border-radius: 3px;
box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.updated .conform-details ul li strong {
padding: 10px;
width: 25%;
display: inline-block;
font-size: 15px;
margin: 0 15px 0 0px;
background: #54ab1a;
color: #fff;
float: left; }
.react-datetime-picker__inputGroup {
  border: 1px solid #c1bbbb !important;
}
.form-group .react-datetime-picker__inputGroup input {
  border: 0px solid #c1bbbb !important;
}
.not-found-page .cont-sec h3 {
font-size: 85px;
color: #57ab1a;
font-weight: 700;
font-family: inherit;
margin: 0;
}
.not-found-page .cont-sec h4{
font-size: 55px;
  color: #454545;
  font-weight: 100;
}
.not-found-page .cont-sec {
padding: 5em;
text-align: left;
}
.guarantee-image {
padding-top: 0; margin-top: 2em;
}
.guarantee-image a.btn-home {
background: #ffa844;
  color: #000;
  font-weight: 700; margin-left: auto; margin-right: auto; display: block;
}
.guarantee-image a.btn-home:hover {
background: #57ab1a;
color: #fff;
text-decoration: none;
}
.guarantee-image img  { display: block; margin: 0 auto; }
.guarantee-image.mobile  { display: none; }
.guarantee-image.desktop  { display: block; padding: 0; }
.rotate {
margin: 20px;
width: auto;
height: auto;
position: relative;
  display: inline-block;
  width: 100%;
  z-index: 99;
animation-name: spin;
animation-duration: 3000ms;
animation-iteration-count: infinite;
animation-timing-function: linear;
}
.guarantee-image .static{
width: 150px;
background: #000;
border-radius: 50%;
padding: 15px;
position: absolute;
top: 19px;
left: 0;
right: 0;
margin: 0 auto;
}

@keyframes spin {
from {
    transform:rotate(0deg);
}
to {
    transform:rotate(360deg);
}
}


/*** animation starts ****/

.blink-image1 {
-moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
-webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
-ms-animation: blink normal 2s infinite ease-in-out; /* IE */
animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
@keyframes fade {
from { opacity: 1; }
50% { opacity: 0.9; border: 5px solid #54ab1a; }
to { opacity: 1.0; }
}                                                                                                                                                                                                                                  

@-webkit-keyframes fade {
from { opacity: 1.0; }
50% { opacity: 0.9; border: 5px solid #54ab1a; /*background-color: #54ab1a;*/}
to { opacity: 1.0; }
}
.hand {
display: inline-block !important;
vertical-align: -5px;
max-width: 20px;
}
.banner-bg .stamp img {
position: absolute;
top: 24px;
bottom: 0;
display: inline-block;
margin-top: 0 !important;left: 0;
right: 0;
margin: 0 auto;
}
.stamp {
position: relative;
animation:fade 1000ms infinite;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
-webkit-animation:fade 1000ms infinite; border-radius: 50%;width: 200px;
height: 200px; margin: 0 auto;
}

@-moz-keyframes blink {
0% {
    opacity:0.5;
}
50% {
    opacity:0.8;
   
}
100% {
    opacity:1;
}
}

@-webkit-keyframes blink {
0% {
    opacity:0.5;
}
50% {
    opacity:0.8;
}
100% {
    opacity:1; transform: scale(1);
}
}
/* IE */
@-ms-keyframes blink {
0% {
    opacity:0.5;
}
50% {
    opacity:0.8;
   }
100% {
    opacity:1; transform: scale(1);
}
}
/* Opera and prob css3 final iteration */
@keyframes blink {
0% {
    opacity:0.5;
}
50% {
    opacity:0.8;
}
100% {
    opacity:1; transform: scale(1);
}
}

@keyframes fadeIn {
from { opacity: 0; }
}

.hand {
animation: fadeIn 1s infinite alternate;
}


/* Firefox old*/
@-moz-keyframes blink {
0% {
  opacity:1;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
}

@-webkit-keyframes blink {
0% {
  opacity:1;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
}
/* IE */
@-ms-keyframes blink {
0% {
  opacity:1;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
}
/* Opera and prob css3 final iteration */
@keyframes blink {
0% {
  opacity:1;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
}
.blink-image2 {
-moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
-webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
-ms-animation: blink normal 2s infinite ease-in-out; /* IE */
animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
.main-content h2, .main-content h3, .main-content h4 {
text-transform: uppercase;
margin: 1.5em 0 0.4em;
letter-spacing: 0.1em;
color: #7b8a75;
}
.main-content p {
font-size: 16px !important;
}
.privacy-header {
  font-weight: 900;
  color: #54ab1a;
  text-transform: uppercase;
  margin-top: 40px;
  font-size: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 40px;
  text-align: center;
  font-weight: bolder;
}


/* Gokul */

.afflicate-banner {
background-color: #54ab1a;
text-align: center;
padding: 50px 0;
}

.afflicate-banner h6{
font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #fff;
  font-weight: bolder;
}

.afflicate-banner h2 {
font-size: 4rem;
margin-top: 20px;
font-weight: 700;
line-height: 1.2; color: #fff;
}
.afflicate-banner h2 span {
font-weight: 300 !important;
letter-spacing: -.05em;
}

.text_column{
margin: 45px 0px;
}
.text_column p {
margin-bottom: 20px;
}

.affliate-button{
margin: auto;
padding: 20px 0;
color: #fff;
border-color: #ffa844;
background-color: #ffa844;
font-size: 16px;
padding-top: 18px;
padding-bottom: 18px;
padding-left: 25px;
padding-right: 25px;
border-radius: 5px;
display: inline-block;
margin-bottom: 0;
text-align: center;
vertical-align: middle;
cursor: pointer;
}

a.affliate-button:hover{
text-decoration: none;
}
.affliate-two .row, .affliate-three, .affliate-four {
padding: 45px 0px;
}

.affliate-three h3{
font-size: 3rem;
margin-top: 20px;
font-weight: 700;
line-height: 1.2;
margin: 20px 0px;
}


.affliate-four {
text-align: center;
}
.affliate-four h2 {
line-height: 1.3;
color: #000;
font-size: 3rem;
margin-top: 20px;
font-weight: 700;
line-height: 1.2;
}
.affliate-four h2 span {
font-weight: 300 !important;
letter-spacing: -.05em;
font-size: 34px;
}
.affliate-four .row {
text-align: left;
}
.container-sml {
max-width: 700px;
margin: auto;
  width: 100%;
}

.affiliate-form .main-section {
border-radius: 8px;
border: 0px solid #e6e6e6;
box-shadow: 0 2px 15px 0 rgb(0 0 0 / 7%);
margin: 0 auto;
margin-top: 20px;
margin-bottom: 40px;
padding: 20px;
color: #656565;
}

.affiliate-form .heading:first-child {
margin-top: 20px;
font-weight: 400;
font-size: 42px;
text-align: center;
line-height: 55px;
}

.affiliate-form .heading+.affiliate-form .subheading {
margin-top: 15px;
}
.affiliate-form .subheading {
line-height: 35px;
font-size: 21px;
text-align: center;
font-weight: 300;
}
.affiliate-form .subheading p {
color:  #656565;
font-size: 16px;
  line-height: 1.4;
}
.get-in-touch .form-group {
margin-bottom: 1em;
}
.get-in-touch .form-control, .get-in-touch input#example-datepicker, .get-in-touch .form-group input {
border: 1px solid #c1bbbb !important;
height: auto;
font-size: 16px;
padding: 10px 10px;
border-radius: 5px;
}
.get-in-touch form .form-group .col-md-6 input {
border: 1px solid #c1bbbb !important;
height: auto;
font-size: 16px;
padding: 10px 10px;
border-radius: 5px;
}
.form-group .col-md-6 {
color: #6d6d6d;
text-align: left;
}
input.lp-btns {
width: 24%;
/* background-size: cover !important; */
padding: 8px 0;
text-align: center;cursor: pointer;
}
input#example-datepicker {
width: auto;
max-width: 185px;
}
.get-in-touch textarea { height: 150px !important; }
.get-in-touch input#cc {
width: auto;
/* border-radius: 0; */
max-width: 49px;
}
.form-banner button.lp-btns {
background: #f9a743;
margin: 0;
padding: 5px 20px;
}
button.lp-btns {
background: #54ab1a;
padding: 13px 30px;
display: inline-block;
margin: 1em auto;
color: #fff;
font-weight: 600;
border-radius: 3px;
}
.home.get-in-touch {
text-align: center;
padding: 2em 0;
}
.thankyou-cont {
text-align: center;
}
.event-group form {
  max-width: 400px;
  margin: 0 auto;
}
.event-group form p {
  color: #54ab1a;
  font-weight: 700;
  padding: 1em 0 3px;
}
.event-group form .form-control {
  padding: 9px 10px;
  height: auto;
  border: 1px solid #c7bebe;
  border-radius: 4px;
}
.event-group form button.lp-btns {
  margin-top: 2em !important;
}
.event-group form .form-control::placeholder {
  opacity: 1;
  color: #454545;
}
.event-group form .form-control:focus {
  border: 1px solid #ccc !important;
}
.event-group p {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 2em;
}


.navigation ul li ul {
  background: #ffa844; border: 1px solid #ccc;
  top: 40px;
  min-width: 100%;
  position: absolute !important;
  transition: all 0.5s ease;
  margin-top: 0;
  left: 0;
  display: none;
  transition: all 0.5s ease 0s;
  width: 200px;
  padding: 20px 15px;
  z-index: 9999999999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 3px;
}
.navigation ul.nav.navbar-nav li ul li span {
  color: #fff; cursor: pointer;
}
ul.nav.navbar-nav li span i.fa.fa-angle-down {
  font-size: 20px;
  color: #353434; padding: 0 5px;
  vertical-align: middle;
}
.navigation ul.nav.navbar-nav li ul li ul {
  left: 50%; top: 25px;
}
.navigation ul.nav.navbar-nav li ul li {
  margin-top: 0;
  padding: 7px 0;position: relative;
  font-weight: 700;
}

ul li:hover > ul,
ul li ul:hover {
  
  display: block;
}
ul li ul li {
  clear: both;
  width: 100%;
}
.subscrip input#plan::placeholder {
  opacity: 1;
  color: #454545;
}
.form-group .col-md-12 { position: relative; z-index: 1;}
.blog .cont-sec ul {
  padding-left: 1em;
}
.blog .cont-sec ul li {
  display: list-item;
  list-style: disc;
}
.footerbar { flex-direction: column; }
/*Responsive Design starts */

.form-group i.fa.fa-trash-alt {
display: none;
}
.rest-signup .signupbtn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; border-radius: 3px;
}

@media (max-width: 1300px) {
/* .botlp-grp {width:350px;} */
.guarantee-image a.btn-home {
font-size: 16px;
padding: 10px;
vertical-align: middle;
}
}

@media (max-width: 1024px) {


.botlp-grp {
width: 275px;
}

.new {
color: #888;
font-size: 19px;
text-align: center;
top: 0em;
font-family: 'Open Sans', sans-serif;
}
.demo {
color: #888;
font-size: 25px;
text-align: center;
line-height: 1;
font-family: 'Century Gothic';
padding-bottom: 10px;
}
.message.bot-message {
font-size: 13px;
}
.df-chips-wrapper a,
.df-chips-wrapper a:hover {
font-size: 13px;
}
p.hours strong {
font-size: 40px;
}
}



@media (max-width: 1200px) {

.demo {
font-size: 35px;
}
.ancho-btns-lp ul li {    
padding: 3px 15px; }
.ancho-btns-lp ul li a {  
font-size: 15px; }
.ancho-btns-lp ul { padding: 0; }
.next button.btn.btn-primary.step-btn.company {
  width: auto !important;
}
#business_tbody td {
  min-width: 104px !important;
}
}

@media (min-width: 992px) {

.pricing .card:hover {
margin-top: -.25rem;
margin-bottom: .25rem;
box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
}
.pricing .card:hover .btn {
opacity: 1;
}

}

@media (max-width: 992px) {

.botlp-grp {
width: 275px;
}

.new {
color: #888;
font-size: 19px;
top: 0em;
font-family: 'Open Sans', sans-serif;
}
.demo {
color: #888;
font-size: 25px;
text-align: center;
line-height: 1;
font-family: 'Century Gothic';
padding-bottom: 10px;
}
.free-try .lead {
color: rgb(33, 150, 243);
font-size: 25px;
font-weight: 600;
font-family: 'Open Sans', sans-serif;
}
.message.bot-message {
font-size: 13px;
}
.df-chips-wrapper a,
.df-chips-wrapper a:hover {
font-size: 13px;
}
p.hours strong {
font-size: 40px;
}
.guarantee-image a.btn-home {

font-size: 14px;
padding: 10px; display: inline-block;
vertical-align: middle;
}

}

@media (min-width: 767px) and (max-width: 1024px){
#add{
  display: block;
  width: 20% !important;
}
.navigation ul li ul {
  min-width: 100%;
width: 150px;
}
.stamp {
 
  width: 150px;
  height: 150px;
left: -14px;
}
.banner-bg .stamp img {

top: 18px; }
}

@media only screen and (max-width: 991px) {
  .navigation ul li ul { display: none; }
  .navigation ul.nav.navbar-nav li ul li ul { left: 0;}
#add {
  margin: 0 1em !important;
  display: inline;
 
  text-align: center; }
.col-md-6.form-box, .step-form.test .col-md-4 {
  -webkit-flex: 0 0 auto;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;margin: 1em auto !important;
}
.bs-stepper-header
{ text-align: center;
  align-items: center;
  justify-content: center;
}
#topiclinks-text {
  display: block !important;
}
.form-section {
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
}
.form-section .form-control {
width: 100%;
}
.botimage img,
.botimage {
position: relative;
}
.et_pb_et_price:after {  
font-size: 15px; }
.et_pb_sum {
font-size: 50px; }
.et_pb_pricing_content {
padding: 30px 20px; }
.et_pb_pricing li {
font-size: 15px;
}
.iframe-video {
width: 100%; }
.left-video {
text-align: center;
}
.left-video {
text-align: center;
padding-bottom: 2em;
}
.lpbanner {
padding: 6em 0;
}
.lpheader {
border-bottom: 1px solid #fff;
padding: 0;
}
.feature-video iframe {
width: 100%;
}
.ancho-btns-lp > a {
display: inline-block;
margin: 0 auto;
background: transparent;
color: #444;
font-family: 'Open Sans', sans-serif;
font-weight: 700;
font-size: 25px;
position: absolute;
border-radius: 3px;
top: -60px;
right: 35px;
}
.ancho-btns-lp ul {
display: none;
background: #67bb65;
box-shadow: 3px 1px 2px rgb(0 0 0 / 50%);
padding: 1em 0;
}
.ancho-btns-lp ul li a {
font-size: 16px;
}
.ancho-btns-lp ul li {
display: block;
text-align: left;
border-bottom: 1px solid #fff;
padding: 10px 18px !important;
}
.ancho-btns-lp ul li:last-child {
border-bottom: 0;
}
.ancho-btns-lp ul li:last-child a {
background: rgb(33, 150, 243);    
margin-top: 10px;
display: inline-block;
}
.lpheader .container {
padding: 0; max-width: 100%;
}
.ancho-btns-lp ul li a.highlight {
color: #fff;
}
.lpheader .logo {
text-align: left;
margin-bottom: 0;
padding: 10px 0;
margin-left: 2em;
}


}

@media(max-width:1200px){
.guarantee-image .static {
  width: 130px; top:17px; }
  .stamp { width: 180px; height: 180px; }
}
@media(max-width:860px){
.guarantee-image .static {
  width: 105px; top:17px; }
}


@media(max-width: 767px){
  .navigation ul li ul { transition: none; width: 100%; left: 0; right: 0; margin: 0 auto; min-width: auto;}
  .pricecol .card { max-width: 100%;}
.blog .cont-sec img {
    width: 100%;
}
body{
    -webkit-text-size-adjust: none;
}
.banner-bg .stamp img {    
  top: 20px; }
.guarantee-image.mobile  { display: block; }
.guarantee-image.desktop  { display: none; }
.guarantee-image .static{ top: 77px !important;
width: 159px !important; }
.animation-group {
  max-width: 215px;
  margin: 0 auto;
}
.guarantee-image a.btn-home {

  font-size: 16px;
  padding: 10px 50px;
  vertical-align: middle;
}
.guarantee-image {
  padding-top: 1em;margin: 0 auto;
  text-align: center;padding-bottom: 1em;
}
.guarantee-image img {
display: block;
  margin: 0 auto; width: auto;
}
.not-found-page .cont-sec {
  padding: 5em 1em;
 
}
.subscrip { max-width: 100px; }
#topiclinks-text {
display: block !important;
}
.onboarding-form .col-md-1 {
  text-align: left;
  max-width: 15%;
  padding: 0;
}
.onboarding-form .col-md-11 {
max-width: 85%;
}
.onboarding-form .step:before {
background: transparent;
}
.onboarding-form .col-md-11 {
max-width: 100%; }
.onboarding-form .col-md-1 { display: none; }
.conform-details ul li strong {
  /* background: #cccccc7a; */
  padding: 7px;
  width: 80px;
  margin: 0 8px 0 0px;
  font-size: 13px;
 
}
.conform-details ul li {
  font-size: 13px;
}
.test .form-box .bs-stepper-content {
 
  padding: 0 20px 20px; }
form {    
  padding: 25px 0px !important;
}
.form-group button.btn.btn-primary { padding: 6px 1em; }
.pricing-group-lists {
float: none;
width: 100%;
margin: 0 auto;
}

.lpbanner iframe {
width: 100%; }
.left-video p.new {  
font-size: 20px; }
.lpbanner {

text-align: center;
}
.left-video {
text-align: center; padding-bottom: 3em;
}
.lpbanner::before {  
background-image: none; }
.lpfeatures {
padding: 1em 0 0em;
}
.bot-lp-real-data { margin-top: 0; }


.lpfeatures h2 {
margin-bottom: 10px;
}
.et_pb_pricing li {
font-size: 15px;
}
.et_pb_pricing_content {
padding: 30px; }
.et_pb_pricing li {
font-size: 16px;
}
.price-table table tr td {font-size: 15px;}
.price-table {

overflow-x: auto;
}
.step-form.test {
  position: relative;overflow: hidden;
 
}
.form-box .bs-stepper .bs-stepper-header {
  padding-top: 2em;
 
}
.step.active:before {
  background: #ccc;
}
.step:before {
  position: absolute;
  content: "";
  background: hsla(0,0%,80%,.8);
  height: 5px;
  width: 100%;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.step-form.test .form-box {    
  margin-top: 0; margin-bottom: 0;}
.step-form.test .signup__teams__sidebar {
  width: 100%; }
.search-box .cta.jsx-4163279887 .email-form {
  max-width: 100%;
}
.new-pricing-plan table tr {
display: flex;
padding: 0 10px;
}
.new-pricing-plan table tr td {

width: 50%;  
display: flex;
flex: 0 0 auto;
flex-wrap: wrap;
flex-direction: column;
padding: 0;
}
.new-pricing-plan {
overflow-y: hidden;
overflow-x: auto;
}
.new-pricing-plan table tr td p {

font-size: 15px;

padding: 0 1em;
}
.hero-content h1 {
font-size: 24px;
}
.hero-content p {

font-size: 16px;
}
.new-pricing-plan table tr td h2 { background: hsla(0,0%,100%,.3); font-size: 20px !important; }
.form-box .step {
  display: flex;
  width: 50%;
  float: left;
   flex-direction: column;
  /* flex-wrap: initial; */
  /* flex: 1 1 33%; */
  /* margin: 0 auto; */
  /* text-align: center; */
  /* display: inline-block; */
  padding: 10px 0;
  margin-right: 0px;
}
.bs-stepper
.step .step-trigger {    
  padding: 10px 10px 9px!important;
  background: rgba(84,171,26,.5019607843137255) !important;
  border-radius: 0 !important; margin: 0 !important;
  box-shadow: none;

 
}
label p {
 
  font-size: 16px; }
.form-box .bs-stepper-header {
  /* margin: 0 -10px; */
  /* text-align: center; */
  /* display: initial; */
  /* float: none; */
  /* width: 100%; */
  /* flex-direction: row; */
  display: block;
  float: left;
  width: 100%;
  position: relative;
  z-index: 99999;
}
.col-md-6 {
flex: 0 0 auto;
width: 100% !important;
}
.bs-stepper-content {
padding: 0 10px;
}
.bs-stepper-label, button.step-trigger .bs-stepper-circle{
font-size: 14px;color: #fff;
}
.signup__teams__sidebar {
 
padding: 20px 25px 0 !important; }
.bs-stepper-header {
margin: 0 auto !important;
text-align: center;padding: 0 10px;
}
.sidebar__content p.text-sm { padding-bottom: 15% !important; }
span.jsx-1631410664, div.jsx-1631410664 {
  font-size: 24px; }
span.jsx-3289410788, div.jsx-3289410788 {
  font-size: 19px; }
.step-trigger::before { content: none; }
.form-group .table tr td {
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.form-box .makeStyles-content-2 {
  display: flex;
  align-content: center;
  flex-direction: column;
  position: relative;
  z-index: 999999;
  background: #fff;
  overflow: unset;
  top: 0;
  margin: 0 auto;
  text-align: center;
 
}
.form-box .MuiCardContent-root .card-body { padding: 0; }
.col-md-6 label p { font-size: 16px; }
.new-pricing-plan table tr td:last-child h2 {
 
background: hsl(18deg 97% 85%);
color: #fff;
}
.signup__teams__sidebar__product-box-title-text {
 
  font-size: 16px;
}
}

@media only screen and (max-width: 600px) {
.logo {
text-align: center;
margin-bottom: 15px;
}
.float-right {
float: none !important;
margin: 0 auto !important;
text-align: center;
}
.lpbanner .caption {
display: none;
}
.lpbanner,
.lpbanner .col-lg-4 {
height: auto !important;
}
.mobile-title {
display: none !important;
}
.mobile-title .col-lg-12 {
background: #0c2a7590;
padding: 10px 0px;
}
.mobile-title h1 {
text-align: center;
color: #fff;
font-size: 32px;
}
.lp-bot-section h1 {
font-size: 50px;
}
.lp-bot-section h3 {
font-size: 40px;
}
.lp-bot-section h2 {
font-size: 28px;
}

.mt-10 {
margin-top: 0px;
}
.left-video iframe {
width: 100%;
height: 200px;
}
.ancho-btns-lp {
text-align: center;
}
.ancho-btns-lp ul {
padding: 0;
}

.lpbanner h1,
.demo {
font-size: 26px; text-align: center;
}
p.hours strong,
.lead {
font-size: 22px;
}
.right-sec img.img-fluid.bot-img {
max-width: 40px;
}
p.hours {
font-size: 20px;
}
.new {
font-size: 16px;
}
.demo {
font-size: 26px;
padding-top: 1em;
text-align: center;
}
.ancho-btns-lp.fixed ul li:last-child {
border-right: 0;
}
.ancho-btns-lp.fixed ul li {
padding: 14px 19px;
}
.ancho-btns-lp.fixed ul li a {
font-size: 12px;
}
.lpdesign h2 {
font-size: 24px;
}
.lpdesign {
padding: 25px 0px 15px;
}
.lpwelcome p {
font-size: 15px;
font-weight: 500;
}
.lpwelcome h3 {
font-size: 16px;
}


.bot-lp-real-box .nav-tabs {
width: 100%;
}
table th h6.card-price {
font-size: 30px;
}


table tr td {
padding: 10px 5px;
font-size: 15px;
}
.bot-lp-real-box .nav-tabs {
width: 100%;
}
.lpfeatures h2,
.lpfeatures h2 span {
text-align: center;
font-size: 20px !important;
}

.lp-bot-section {
padding: 0px 0px 20px 0px;
}
table th h5.card-title {
margin: 0;
font-size: 13px;
}
.step-btn {
  width: 100% !important;
}

}


@media(max-width: 400px){
.lpbanner iframe, .feature-video iframe {
width: 100%;
height: 270px;
}
}


* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
header.jsx-3979001018 {
position: relative;
position: -webkit-sticky;
position: sticky;
top: 0;
background-color: #ff4040;
width: 100%;
height: var(--header-height,auto);
padding: 0 var(--header-navigation-compact-px,7%);
padding-top: 30px;
margin: 0 auto;
z-index: 100;
}


.container.jsx-3979001018 {
max-width: 1290px;
margin: 0 auto;
position: relative;
}
.container.jsx-2180139827 {
display: flex;
-webkit-box-align: center;
align-items: center;
}

.logo-link.jsx-96647813 {
display: block;
}
a {
color: #3047ec;
-webkit-text-decoration: none;
text-decoration: none;
}
.horizontal_spacer.jsx-2180139827 {
-webkit-box-flex: 1;
flex-grow: 1;
}
.cta-wrapper.jsx-2180139827:not(:last-child) {
margin-right: 5px;
}
@media (max-width: 1439px){

.cta.jsx-4163279887 .email-form {
max-width: 335px;
}
}

.email-form.jsx-403594908 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
position: relative;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
span.jsx-75452321 {
-webkit-clip: rect(1px,1px,1px,1px);
clip: rect(1px,1px,1px,1px);
-webkit-clip-path: inset(50%);
clip-path: inset(50%);
height: 1px;
width: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
}
.email-form__input-wrapper.jsx-403594908 {
-webkit-flex: 999 0 0;
-ms-flex: 999 0 0;
flex: 999 0 0;
width: 100%;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
min-width: auto !important;
}
}
.email-form__input.jsx-403594908 {
width: 100%;
outline: 0;
border: 2px solid #ff4040;
color: #181818;
border-radius: 6px 0px 0 6px;
font-size: 15px;
line-height: unset;
}
.email-form__input.jsx-403594908 {
line-height: 1.4;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
input {
font: inherit;
margin: 0;
padding: 0;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
min-width: auto !important;
}
}
.email-form__submit--black-fill.jsx-403594908 {
background-color: #ff4040;
border: 2px solid #ff4040;
color: #ffffff;
}
.email-form__submit.jsx-403594908 {
display: inline-block;
border-radius: 0px 6px 6px 0px;
padding: 12px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
-webkit-transition: all 200ms;
transition: all 200ms;
cursor: pointer;
-webkit-flex-shrink: 0;
-ms-flex-negative: 0;
flex-shrink: 0;
font-weight: bold;
position: relative;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
button {
border: none;
margin: 0;
padding: 0;
text-align: inherit;
background: transparent;
color: inherit;
font: inherit;
line-height: normal;
-webkit-font-smoothing: inherit;
-moz-osx-font-smoothing: inherit;
-webkit-appearance: none;
}

span.jsx-148622701, div.jsx-148622701 {
font-size: 15px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
line-height: 1.4;
}

@media (min-width: 1024px) {
span.jsx-3289410788, div.jsx-3289410788 {
font-size: 26px;
}
.link.jsx-277724099 {
margin-right: -7px;
}
}
.link.jsx-277724099 {
display: inline-block;
padding: 7px 12px;
position: relative;
width: auto;
color: rgb(24, 24, 24);
transition: all 250ms ease 0s;
}

main {
overflow: hidden;
outline: 0;
}
@media (min-width: 768px) {
.beagi h2 { transform: translate(0%, 5%); }

.cta-wrapper.jsx-360272744 {
justify-self: unset;
}
span.jsx-3289410788, div.jsx-3289410788 {
line-height: 1.4;
-webkit-letter-spacing: unset;
-moz-letter-spacing: unset;
-ms-letter-spacing: unset;
letter-spacing: unset;
font-size: 22px;
}
.subheading.jsx-2340029290 {
margin: var(--margin);
margin-bottom: 36px;
max-width: 800px;
}
.headline.jsx-2340029290 {
margin-bottom: 24px;
}
.content.jsx-3751704983 {
grid-column: left-content;
}
.two-columns.jsx-3751704983 .content.jsx-3751704983 {
grid-column: left-content / span 3;
}
.two-columns.jsx-3751704983 .hero.jsx-3751704983 {
grid-template-columns: 1fr [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr 1fr;
}
section.jsx-618185480 {
margin-top: 0px;
margin-bottom: 0px;
}
.email-form__input.jsx-403594908 {
min-width: 210px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border-right: 0;
line-height: 1.4;
}

.primary.jsx-360272744 {
margin: 0 0px 22px 0;
}
.cta.jsx-360272744 {
display: inline-block;
}
.email-form__submit.jsx-403594908 {
border-top-left-radius: 0;
border-bottom-left-radius: 0;
}
.hero-wrapper.two-columns.jsx-3751704983 {
grid-template-columns: [content] 14fr;
}
.hero-wrapper.jsx-3751704983 {
  padding: 50px 0;
}
}

.hero-wrapper.jsx-3751704983 {
display: grid;
grid-template-columns: [left-gutter] 1fr [content] 12fr [right-gutter] 1fr;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
padding: 50px 0;
}
.hero-wrapper--white {
background-color: #ffffff;
}


.hero.jsx-3751704983 {
grid-column: content;
position: relative;
display: grid;
grid-template-columns: [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr;
grid-column-gap: 24px;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
justify-items: center;
}

.content.jsx-3751704983 {
grid-column: left-content / span 3;
-webkit-order: undefined;
-ms-flex-order: undefined;
order: undefined;
}
.content.jsx-3751704983 {
display: grid;
text-align: center;
justify-items: center;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
z-index: 1;
position: relative;
}


.headline.jsx-2340029290 {
max-width: 1024px;
margin-bottom: 16px;
margin-top: 0;
}
@media (min-width: 990px) {

span.jsx-1631410664, div.jsx-1631410664 {
font-size: 60px;
font-weight: 600;
}
}

span.jsx-1631410664, div.jsx-1631410664 {
font-size: 60px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
  color: #454545;
  text-transform: capitalize;
}



.subheading.jsx-2340029290 {
margin-bottom: 21px;
}


span.jsx-3289410788, div.jsx-3289410788 {
font-size: 26px;
font-family: 'Open Sans', sans-serif;
line-height: 1.4;
}


.cta-wrapper.jsx-360272744 {
justify-self: stretch;
}


.cta.jsx-360272744 {
margin: 0 0 22px 0;
}

.plan-column-group {
margin: 0 auto;
width: 100%;
max-width: 1290px;
padding-top: 24px;
}
.plan-column-group__header {
margin-bottom: 20px;
text-align: center;
font-family: Graphik,Helvetica,Arial,sans-serif;
}
.rich-text p:last-of-type {
margin-bottom: 0;
}

.rich-text p {
margin-bottom: 1em;
line-height: 1.7;
}
p {
margin: 0;
}
.plan-column-group__children {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
}
@media (min-width: 768px){
.plan-column-group__header {
height: 60px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
margin-bottom: 0;
}
.plan-column-group-wrapper--tan {
background-color: #fff;
}

main {
padding-top: 70px;
}
}
.plan-column-group-wrapper {
padding: 0 24px 20px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper:first-child {
-webkit-align-items: flex-end;
-webkit-box-align: flex-end;
-ms-flex-align: flex-end;
align-items: flex-end;
}
}

.plan-column-group__children>* {
margin: 0 10px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper {
margin-bottom: 0px;
}
}
.plan-columns-wrapper {
margin-bottom: 20px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
border-radius: 8px;
border: 0px solid #f4f0eb;
-webkit-flex: 1;
-ms-flex: 1;
flex: 1;
}
@media (min-width: 1024px) {

.plan-columns {
min-width: 400px;
}
}

.plan-columns {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-flex: 1;
-webkit-flex-grow: 1;
-ms-flex-positive: 1;
flex-grow: 1;
}

@media (min-width: 768px) {

.plan-columns>*:last-child {
border-bottom-right-radius: 8px;
}
}

@media (min-width: 768px) {

.plan-columns>*:first-child {
border-bottom-left-radius: 8px;
}
}
.pricing-tier-card.pricing-tier-card--rounded {
border-radius: 6px;
border: 1px solid #ccc;
}
@media (min-width: 665px) {

.plan-columns>* {
border-top: 2px solid #f4f0eb;
}
}
.plan-columns>* {
-webkit-box-flex: 1;
-webkit-flex-grow: 1;
-ms-flex-positive: 1;
flex-grow: 1;
}
.pricing-tier-card {
background-color: #ffffff;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
max-width: 395px;
width: 100%;
}

@media (min-width: 768px) {

.pricing-tier-card__section:first-child {
border-bottom: 0px solid #f4f0eb;
}
}

.pricing-tier-card__section {
padding: 22px 28px 10px 28px;
}

.pricing-tier-card__section:first-child>* {
padding-bottom: 0px;
}
@media (min-width: 665px) {

.pricing-tier-card__badge-wrapper--hide-on-mobile {
display: block;
}
}
.pricing-tier-card__badge-wrapper--hide-on-mobile {
display: none;
}
.pricing-tier-card__badge-wrapper {
height: 45px;
margin-top: -5px;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

.pricing-tier-card__category {
padding-bottom: 0 !important;
}

@media (min-width: 1024px) {

span.jsx-3980862901, div.jsx-3980862901 {
font-size: 36px;
}
}
@media (min-width: 990px) {

span.jsx-3980862901, div.jsx-3980862901 {
font-size: 30px;
line-height: 1.2;
}
}
span.jsx-3980862901, div.jsx-3980862901 {
font-size: 26px;
font-family: 'Open Sans', sans-serif;
line-height: 1.4;
}

.pricing-tier-card__title.graphik-font span {
font-family: Graphik,Helvetica,Arial,sans-serif;
color:black;
}
@media (min-width: 768px) {

span.jsx-4108527491, div.jsx-4108527491 {
font-size: 36px;
line-height: 1.2;
}
}
span.jsx-4108527491, div.jsx-4108527491 {
font-size: 36px;
font-family: 'Open Sans', sans-serif;
font-weight: 500;
line-height: 1.2;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.pricing-tier-card__description {
height: 75px;
max-width: 520px;
}
span.jsx-166584131, div.jsx-166584131 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.cta-link--black-outline {
  border: 2px solid #57b555;
  background-color: #57b555;
  color: white !important;
}
.cta-link--wide {
width: 100%;
}
.cta-link {
display: inline-block; width: auto;
border-radius: 30px;
padding: 9px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
-webkit-transition: all 200ms;
transition: all 200ms;
cursor: pointer;
background: #cc7929 !important;
  border: 0;
}
span.jsx-4266488777, div.jsx-4266488777 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

@media (min-width: 768px) {

.pricing-tier-card__price-for-period {
font-size: 20px;
}
}

@media (min-width: 1270px) {

.pricing-tier-card__show-on-desktop {
display: block;
}
}

.pricing-tier-card__show-on-desktop {
display: none;
}
.pricing-tier-card__price-for-period--dollar-amount {
font-family: Graphik,Helvetica,Arial,sans-serif;
font-weight: bold;
line-height: 1.4;
}
.pricing-tier-card__price-for-period-subtext {
height: 17px;
}
.copy-right {
position: relative; font-weight: 700;
width: 100%;

padding: 15px 0px;
text-align: center;
color: #fff;
font-family: 'Open Sans', sans-serif;
border-top: 0px solid #ccc; font-size: 16px;
}
.copy-right p { margin: 0; color: #fff; }
.copy-right ul li a {
color: #fff;
}

.copy-right ul {
margin: 0;
list-style: none;
color: #fff;
padding: 0;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}
.signup__teams__sidebar__product-box-title-text {
font-weight: 700; font-size: 18px;
}
.signup__teams__container * {
box-sizing: border-box;
}
.flex-row.gap-4>:last-child {
margin-right: 0;
}
*, ::after, ::before {
border-width: 0;
border-style: solid;
border-color: rgba(0,0,0,.1);
}
.flex-col {
flex-direction: column;
}
.flex {
display: flex;
}
.application__component {
color: #222;
background-color: #f1f1f1;
height: 100%;
}

.signup__teams__body {
font-family: 'Open Sans', sans-serif;
font-size: 15px;
font-weight: 400;
letter-spacing: .01em;
}
.justify-between {
justify-content: space-between;
}
.signup__teams__sidebar {
background-color: transparent;
width: 380px;
padding: 80px 25px 0;
justify-content: space-between;
overflow: hidden;
}
@media (min-width: 1025px){}
.signup__teams__sidebar__container {
order: 3;
flex: 0 0 auto;
}
@media (min-width: 768px){}
.col-md-6 {
flex: 0 0 auto;
width: 50%;
}

.email-not-verified {
text-align: center;
font-size: 16px; padding: 1em 10px;
margin: 0px; color: #454545; position: relative; z-index: 99;

}
.MuiPaper-root.MuiCard-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
margin: 0vh auto !important;
}
.jsx-1928597095.pricing-tier-card__price-for-period {
text-align: left !important;
font-size: 23px !important;
}

.StripeElement {
margin: 15px auto;
padding: 10px 12px;
color: #32325d;
background-color: white;
border: 1px solid #ccc;
border-radius: 4px;
}

.StripeElement--webkit-autofill {
background-color: #fefde5 !important;
}

.product {
width: 100%;
max-width: 450px;
margin: auto;
box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
border-radius: 10px;
overflow: hidden;
}

.btn-pay {
display: block;
width: 100%;
border: none;
background: linear-gradient(
135deg,
rgb(49, 0, 62) 0%,
rgb(195, 40, 110) 100%
);
color: #fff;
padding: 10px;
font-size: 18px;
cursor: pointer;
}

.product-info {
padding: 0 16px;
}

h3.product-title {
font-size: 28px;
margin-bottom: 15px;
}

h4.product-price {
font-size: 24px;
margin: 0;
margin-bottom: 30px;
color: #777;
font-weight: 500;
}
.step-btn {
width: 100%;
}
.next {
margin-top: 30px;
position: relative;
  z-index: 9;
}
label {
width: 100%;
}
.bs-stepper .step-trigger {
display: inline-flex !important;
flex-wrap: wrap !important;
align-items: center !important;
justify-content: center !important;
padding: 10px !important;
font-size: 12px !important;
font-weight: 700 !important;
line-height: 1.5 !important;
color: #6c757d !important;
text-align: center !important;
text-decoration: none !important;
white-space: nowrap !important;
vertical-align: middle !important;
-webkit-user-select: none !important;
user-select: none !important;
background-color: transparent !important;
border: none !important;
border-radius: .25rem !important;
transition: background-color .15s ease-out,color .15s ease-out !important;
padding-bottom: 31px !important;
}
.form-control1 {
display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}

.signup__teams__sidebar__product-box {
  border-top: 10px solid transparent;
  background-color: #fff;
  padding: 24px 24px 30px; border-radius: 3px;
  background: #54ab1a !important
}
.signup__teams__container * {
box-sizing: border-box;
}
.sidebar__content .sidebar__heading h2 {text-transform: capitalize;
  font-weight: 600; color: #454545; font-size: 22px;
border-bottom: 0;
  padding: 1em 0 0;
}
p.text.o__muted {
padding: 5px 0;
font-size: 17px; color: #fff;
font-weight: 600;text-transform: capitalize;
}
.step-form .col-md-4 > div {
  float: right;
}
.flex.flex-row.justify-between.gap-4 {
  color: #454545;
  font-weight: 600; color: #fff;
}
.sidebar__content p.text-sm {
color: #454545; font-size: 18px;
}

.gap-4 span {

font-weight: 700;color: #fff
}

.signup__teams__container * {
box-sizing: border-box;
}

.signup__teams__option-divider {
text-align: center;
position: relative;
z-index: 1;
font-size: 16px;
}
.signup__teams__option-divider:before {
background-color: transparent;
border-top: 1px solid #fff;
display: block;
content: '';
position: absolute;
width: 100%;
height: 2px;
left: 0;
top: 50%;
z-index: 0;
}
.h-2 {
height: 8px;
}
.h-5 {
height: 20px;
}

body {
scroll-behavior: smooth;
}
header#site-header {
padding-top: 20px;
}
main#main {
padding-top: 0;
}
.pricing-tier-card__features ul {
padding-left: 20px;
}
.jsx-1928597095 .pricing-tier-card__price-for-period--dollar-amount {
font-size: 50px;
color: #241c15;

}
.pricing-tier-card__features ul li i.fa.fa-check {
color: #ff4040;
float: left;
vertical-align: middle;
padding: 3px 7px 0 0;
}
.pricing-tier-card__features ul {
padding-left: 0px;
}
.rich-text {
font-size: 17px;
}
.pricing-tier-card__features ul li div p {
color: #454545;
font-size: 16px;
font-family: 'Open Sans', sans-serif;
}
.tt{
padding:10px;
}
.jsx-1928597095.pricing-tier-card__title.graphik-font {
padding-bottom: 0;
}
header#site-header{
border-bottom: 1px solid #ffffff40;
}
.jsx-1928597095.pricing-tier-card__title.graphik-font span {
font-family: 'Open Sans', sans-serif;
}
.jsx-1928597095 > p {
color: #454545;
border-bottom: 1px solid #454545;
display: inline-block;
}
.jsx-1928597095.pricing-tier-card_section.pricing-tier-cardsection--hidden.pricing-tier-card_section--space-between {
padding-top: 0px !important;
}

.month {
line-height: .8;
font-size: 16px;
color: #454545;
font-style: italic;
}
.pricing-tier-card__description {
height: 54px;
max-width: 520px;
}
.jsx-1928597095.pricing-tier-card_section.pricing-tier-cardsection--hidden.pricing-tier-card_section--space-between {
padding-top: 0 !important;
}


/* For Imag Upload */

.file-upload {
  background-color: #fff;
  margin: 0;
}

.file-upload-btn {
width: 100%;
margin: 0;
color: #fff;
background: #ff4040;
border: none;
padding: 10px;
border-radius: 4px;
border-bottom: 4px solid #15824B;
transition: all .2s ease;
outline: none;
text-transform: uppercase;
font-weight: 700;
}

.file-upload-btn:hover {
background: #1AA059;
color: #ffffff;
transition: all .2s ease;
cursor: pointer;
}

.file-upload-btn:active {
border: 0;
transition: all .2s ease;
}

#file-upload-content {
display: none;
text-align: left;
}
.step.active .bs-stepper-label, .step.active button.step-trigger .bs-stepper-circle {
  color: #fff;
}
.file-upload-input {
position: absolute;
margin: 0;
padding: 0;
width: 100%;
height: 100%;
outline: none;
opacity: 0;
cursor: pointer;
}

#image-upload-wrap, #image-upload-wrap-1 {
margin-top: 20px;
border: 1px solid #ccc;
position: relative; z-index: 99;
}
#test-l-3 .makeStyles-root-1 {
  position: relative;
  overflow: unset;
  margin: 0 auto !important;
  background: none; box-shadow: none;
}
.image-dropping,
#image-upload-wrap:hover, #image-upload-wrap-1:hover {
background-color: transparent;
border: 1px solid #ccc;
}

.image-title-wrap {
padding: 0 15px 15px 15px;
color: #222;
}

.drag-text, .drag-text-doc, .drag-text-team{
text-align: center;
}
.drag-text h3 {
text-transform: capitalize;
color: #454545;
font-size: 20px;
padding: 50px 0;
font-weight: 500;
}
.drag-text-doc  h3, .drag-text-team h3 {
text-transform: capitalize;
color: #454545;
font-size: 20px;
padding: 35px 0;
font-weight: 500;
}


#file-upload-image, #file-upload-image-team {
max-height: 200px;
max-width: 100px;
margin: auto;
padding: 7px 5px;
}

form {
margin: 0;
padding: 25px;
}
fieldset {
margin: 0;
padding: 15px;
background-color: #fff;
z-index: 0;
}
legend {
font-size: 2em;
padding: 0 10px;
}
input[type="checkbox"], input[type="radio"] {
display: none;
z-index: -1;
position: absolute;
}
label {
display: block;
margin: 10px 0;
padding: 2px;
cursor: pointer;
}
label p {
display: inline-block;
font-size: 16px;
margin: 0;
padding: 0;color: #454545;
}
input[type=checkbox]+label .customCheckbox {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 10px -5px 0;
  padding: 0;
  border: 2px solid #57ab1a;
  border-radius: 0px;
  box-shadow: 1px -2px 6px #ccc;
  z-index: 9999;
}


input[type="checkbox"] + label .customCheckbox:after {
content: "";
position: absolute;
border-radius: 10px;
top: 50%;
left: 50%;
width: 0;
height: 0;
background-color: #454545;
transition: all 0.25s;
}

input[type=checkbox]:checked+label .customCheckbox:after {
  top: -2px;
  left: 3px;
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 0px;
  content: '✔';color: #454545;
}


input[type=radio]+label .customradio {
display: inline-block;
box-sizing: border-box;
position: relative;
width: 19px;
height: 20px;
margin: 0 10px -4px 0;
padding: 0;
border: 1px solid #fff;
border-radius: 50%;
box-shadow: 1px -2px 6px #ccc;
z-index: 9999;
}


input[type="radio"] + label .customradio:after {
content: "";
position: absolute;
border-radius: 10px;
top: 50%;
left: 50%;
width: 0;
height: 0;
background-color: #454545;
transition: all 0.25s;
}

input[type=radio]:checked+label .customradio:after {
top: 1px;
left: 1px;
width: 15px;
height: 15px;
content: '';
border-radius: 50%;
background: #54ab1a;
}




input[type=checkbox]+label .customCheckbox1 {
display: inline-block;
box-sizing: border-box;
position: relative;
width: 25px;
height: 24px;
margin: 0 10px -5px 0;
padding: 0;
border: 1px solid #fff;
border-radius: 0px;
box-shadow: 1px -2px 6px #ccc;
background: #00C853;
z-index: 9999;
}


input[type="checkbox"] + label .customCheckbox1:after {
content: "";
position: absolute;
border-radius: 10px;
top: 50%;
left: 50%;
width: 0;
height: 0;
background-color: #454545;
transition: all 0.25s;
}

input[type=checkbox]:checked+label .customCheckbox1:after {
top: -1px;
left: 5px;
width: 15px;
height: 15px;
background: transparent;
border-radius: 0px;
content: '✔';color: #fff;
}

.container-fluid {
margin-top: 80px;
margin-bottom: 80px
}

.card {
background-color: #424242;
padding: 40px 10px
}

.text-grey {
color: #9E9E9E
}
.tz p {
font-weight: bolder;
}

.fa {
font-size: 25px;
cursor: pointer
}



input:focus,
select:focus {

box-shadow: inherit !important;
border-bottom: 1px solid transparent;
outline-width: 0
}

select option {
  background-color: #54ab1a;
  color: #fff;
  font-family: 'Open Sans';
  font-size: 16px;
  border: 0;
  font-weight: 600;
}
.form-group .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #454545;
  font-weight: 500; position: relative; z-index: 99;
}
.form-group .table tr td {
  border: 0;
}
select option:focus {
background-color: #00C853 !important
}

::placeholder {
color: #fff;
opacity: 1
}

:-ms-input-placeholder {
color: #fff
}

::-ms-input-placeholder {
color: #fff
}

button:focus {
-moz-box-shadow: none !important;
-webkit-box-shadow: none !important;
box-shadow: none !important;
outline-width: 0
}

.btn {
border-radius: 50px;
width: 120px
}

.exit {
border: 1px solid #9E9E9E;
color: #9E9E9E;
background-color: transparent
}

.exit:hover {
border: 1px solid #9E9E9E;
color: #454545 !important;
background-color: #9E9E9E
}

@media screen and (max-width: 768px) {
.mob {
    width: 70%
}

select.mob {
    width: 50%
}
}

.business_hours td {
padding: 0 20px;
}
.business_hours td select {
padding: 10px;
}
.form-select {
display: block;
width: 100%;
padding: .375rem 2.25rem .375rem .75rem;
-moz-padding-start: calc(0.75rem - 3px);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-repeat: no-repeat;
background-position: right .75rem center;
background-size: 16px 12px;
border: 1px solid #ced4da;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
.w-100 {
width: 100%;
}

#add {
margin: 0;
}
.form-box .bs-stepper-content {
/* background-image: url(temp.png); */
padding: 0 20px 20px;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
position: relative;
z-index: 99999999;
margin: 3em auto 0;
display: block;border-radius: 5px;
}
.form-group i {
position: absolute;
left: 15px;
top: auto;
bottom: 13px;
color: #8c8989f7;
z-index: 9999;
font-size: 20px; display: none;
}
.form-group i.fa.fa-user {
left: 26px;
}
.form-box .form-group { position: relative; }
.form-group i.fa.fa-globe { left: 24px; bottom: 11px;  }
.form-group i.fas, .form-group i.fab {
left: 26px;
font-size: 18px;
bottom: 13px;
}
.form-box .form-group i.fa.fa-institution, .form-box .form-group i.fa.fa-address-card {
left: 25px;
}

.loader1 {
border: 5px solid #f3f3f3;
border-top: 5px solid #54ab1a;
border-radius: 50%;
width: 25px;
height: 25px;
margin: auto;
animation: spin 2s linear infinite
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.react-datetime-picker__clock.react-datetime-picker__clock--open {
display: none !important;
}

.get-in-touch input#example-datepicker{
border: 0px !important;
}
.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
background: #fff !important;
border-radius: 8px !important;
}
.react-datetime-picker__wrapper {
border: none !important;
}
.pref-time{
margin-top: 15px !important;
  text-align: left !important;
}

.react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled {
background: #fff !important;
}
#error {
display: none;
color: red;
padding: 10px;
background: #fff;
width: 50%;
margin: 0 auto;
margin-bottom: 20px;
border-radius: 20px;
}

.payment_secure {
text-align: center;
margin: 20px;
}

.step-form .MuiCard-root {
margin: 0 auto !important;
overflow: unset;
background: transparent;
box-shadow: unset !important;
transition: none !important;
border: 0;
}
.step-form .MuiCardContent-root.jss2 {
box-shadow: unset;
padding:10px;
}
.payment_info{
font-size: 10px;
text-align: center;
margin-bottom: 0;

}
.conform-detail {
margin: 20px -15px;
}
select.form-select.btn.btn-primary:hover, select option:hover {
  background: #54ab1a !important;
  color: #fff !important;
}
input:focus, select:focus {outline:none; color: #54ab1a !important;border: 0 !important;}

.step-form .btn-primary:focus{
outline:none;  border: 0 !important;

}
.updated {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: green;
  font-weight: bold;
  font-size: 14px;
}
div#pages p {
  font-size: 14px;
  font-weight: 600;
}

.react-tel-input .country-list .country {
display: block;
}
.react-tel-input .form-control {
width: 100% !important;
height: 47px !important;
color: black;
border-radius: 30px !important;

}
.react-tel-input {
border-radius: 30px;
box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
margin-bottom: 20px !important;
}

.flag-dropdown, .flag-dropdown:hover{
border-top-left-radius: 24px  !important;
border-bottom-left-radius: 24px  !important;
}
.selected-flag.open, .selected-flag.open:hover {
border-top-left-radius: 24px !important;
border-bottom-left-radius: 24px !important;
z-index: 9999;
}
#update-message {
text-align: center;
  font-size: 20px;
  margin: 20px;
  color: #54ab1a;
}
#image-upload-wrap-1, #image-upload-wrap-2 {
margin-top: 20px;
padding: 0px 0;
border: 1px solid #ccc;
position: relative; z-index: 99; display: block;
}
#image-upload-wrap {

}
.step-form .form-group label {
font-size: 16px;
color: #54ab1a;
font-weight: bolder;
}




.addreplace-2 #drag-text-new-2 {
font-size: 30px;
background: #54ab1a;
display: inline-block;
padding: 1px 5px;
border-radius: 50%;
color: #fff; width: 27px;
line-height: 0.8; font-weight: 700; display: none;
}





#file-upload-content-2, #file-upload-content-1 {
position: relative;
width: 50%;
}

#file-upload-image, #file-upload-image-team {
opacity: 1;
display: block;
width: 100%;
height: auto;
transition: .5s ease;
backface-visibility: hidden;
}

#middle, #middle-1 {
transition: .5s ease;
opacity: 0;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
}

#file-upload-content-2:hover #file-upload-image, #file-upload-content-1:hover #file-upload-image-team{
opacity: 0.3;
}

#file-upload-content-2:hover #middle, #file-upload-content-1:hover #middle-1 {
opacity: 1;
}

.text {
background-color: #04AA6D;
color: white;
font-size: 16px;
padding: 16px 32px;
}

label.btn.btn-primary.step-btn.company {
display: block;
width: auto;
margin: 0 auto;
padding: 6px 2em;
border-radius: 20px;
background: #fff !important;
border: 0;
font-size: 16px;
font-weight: 700;
position: relative;
z-index: 999;
color: #54ab1a;
transition: all 0.5s ease-in-out 0s;
border: 2px solid;
overflow: hidden;
}
label.btn.btn-primary.step-btn.company:hover {
color: #54ab1a !important;
}

.mr_0 {
margin: 0 !important;
}
.pd_0 {
padding: 0 !important;
}

#business_tbody td {
min-width: 111px;
}
.outer-table {
display: flex; /* displays flex-items (children) inline */
overflow-x: auto;
}

#topiclinks-text {
display: none;
}

#error-message-user-exists{
margin: 4px;
color: #f00;
display: none;
}

.col-md-2 .form-control-price:focus {
box-shadow: none !important;
color: grey;
}
.col-md-2 .form-control-price, .col-md-2 .form-control-price:active  {
box-shadow: none !important;
color: grey;
}
.date-pic input.react-date-picker__inputGroup__input {
  border: 0px !important;
}
.date-pic input, .date-pic input#example-datepicker {
  border: 1px solid #c1bbbb!important;
  height: auto;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
}
.date-pic .react-date-picker__wrapper {
  border: 1px solid #e2dddd!important;
  box-shadow: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em, rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
  border-radius: 3px;
}
.date-pic {
  color: #54ab1a; }
input#plan {
  background: transparent;color: #454545;font-weight: 700;
}
.date-pic .react-date-picker {
 
  z-index: 99999;
}
.date-pic .react-date-picker__button svg {    
  stroke: #57ab1a;
}
.affiliate-link a {
  background: #57ab1a;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px; font-size: 20px;
}
.affiliate-link {
  text-align: center;
  padding: 4em 1em;
}
input#plan:focus {
  color: #454545 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #ccc;
opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
color: #ccc;
}
.cont-sec {
  padding: 2em 0;
}
.blog h4 {
  padding: 0;
  font-size: 20px;
  color: #54ab1a;
  margin-bottom: 15px; min-height: 52px;
}
.blog .cont-sec h6, .blog .cont-sec p { color: #454545;}
.blog h2 {
  padding: 0; font-weight: 500;
  font-size: 25px;
  color: #54ab1a;
  margin-bottom: 15px;
}
.blog .cont-sec p {
  margin-bottom: 1em;
  font-size: 16px;
  line-height: 24px !important;
}
.blog .cont-sec h6 a, .blog .cont-sec p a {
  color: #156bf1fa;
}


.blinking{
animation: blinking 0.4s infinite;
}
@keyframes blinkingText{
0% { background-color: #ffa844;}  
50% { background-color: #54ab1a;}  
100% { background-color:  #ffa844;}
}
@keyframes blinking {
0% {opacity: 1}
49%{opacity: 1}
50% {opacity: 1; background: #f90;}
}

.users tr td:first-child:before
{
counter-increment: Serial;      
content: counter(Serial);
  }
  body
{
 counter-reset: Serial;          
  }
table.cost-table.users i {
    font-size: 18px !important;
    vertical-align: middle;
    color: #6b6868;
}
.title-right a.btn-home {
  padding: 10px;
  font-size: 15px;
}
.wrapper-home .title-left h1 {
font-size: 25px;
margin: 24px 0 0 0;color: #454545;
}
.report-lists table.cost-table.users th {
background:#57ab1a;
padding: 8px 10px;
color: #fff;
}
.report-lists table.users tr:nth-child(even), .report-lists table.users tr:nth-child(odd) {
background: transparent;
}
table.cost-table.users tr {
border-bottom: 1px solid #ccc;
}
table.cost-table.users tr td {
padding: 10px;
}

.react-responsive-modal-modal {
  position: relative;
    top: 50%;
    transform: translateY(-50%);
    min-width: 800px;
}

.RRUfwb36E3adDkhFDNDa .choose-file h3 {
  background: #57ab1a;
  color: #fff;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 10px 30px;
}
.RRUfwb36E3adDkhFDNDa button.btn.btn-primary.step-btn,.RRUfwb36E3adDkhFDNDa #add,.RRUfwb36E3adDkhFDNDa button.btn.btn-primary,.RRUfwb36E3adDkhFDNDa .form-select.btn-primary,.RRUfwb36E3adDkhFDNDa .cta-link {
  background: #ffa844 !important;
  color: #fff;
}

.RRUfwb36E3adDkhFDNDa button.btn.btn-primary.step-btn::before,.RRUfwb36E3adDkhFDNDa .cta-link::before {
  background: #5ebeeb !important;
  color: #fff;

}

.form-bg.contact-upload.p-3 {
  padding: 3em;
}


.customeTheme {
	 color: #fff !important;
	 background-color: #57ab1a !important;
   font-size: 20px;
}
 .customeTheme.place-top:after {
	 border-top-color: #57ab1a !important;
	 border-top-style: solid !important;
	 border-top-width: 6px !important;
}
 

.ques {
  border-radius: 50% !important;
    border: 1px solid #64ab1a !important;
    padding: 1px 5px !important;
}
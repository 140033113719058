.banner-bg33{
  background: #54ab1a;
  position: relative;
  z-index: 222222;
  max-width: 75%;
  margin: 2.5em auto 0;
}
.uploadCsv button.btn.btn-primary.step-btn.company {
  background: #ffa844 !important;
  color: #fff;
}
.logo{
  width:100px;
}
.social-link{
  width:40px;
  padding:5px;
}
.menu-bar{
  display:flex;
  align-items:center;
}
.social-logo{
  display:flex;
  justify-content:end;
  align-items: center;
}
.banner-heading{
  border-left: 2px solid #fff;
  padding-left: 5px;
  color: #fff;
  font-size: 40px;
  font-weight: 700;
}
.form-bg{
  background-color:transparent;
  width:80%
}
.form-control{
  border-radius: 0px;
}
.form-btn{
  border-radius: 40px;
  padding: 8px 40px 8px 40px;
  background-color: #cccc00;
  border: #cccc00;
  color: #000;
  font-weight: 600;
}
.form-align{
  display: flex;
  justify-content: end;
}
.clr-img{
  position:relative;
  height: 120px;
}
.clearfil{
  width:55%;
  position:absolute;
  top:-130px;
}
.clear-text{
  width: 60%;
  font-size: 20px;
  margin: auto;
  font-weight: 500;
}
.footer-link{
  background-color:#4096d7;
}
.link-icon img{
  width:20px;
}
.link-icon{
  background: #183d69;;
  border-radius: 4px;
  padding: 7px 10px 8px 10px;
}
.link-icon-txt{
  padding-left:10px;
}
.link-icon-txt h6{
  color: #183d69;;
  font-weight: 600;
}
.link-icon-txt p{
  color: #fff;
  font-weight: 600;
}
.foot-content{
  display:flex;
  align-items:center;
  justify-content:center;
}
.footer-2{
  background-color:#000;
}
.form-bg.contact-upload.p-3 {
  width: 100%;
  max-width: 93%;
  margin: 0 auto;
  padding: 5em 0 0;
}
.choose-file {
  text-align: center;
}
.form-bg.contact-upload.p-3 h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}
.form-bg.contact-upload.p-3 p {
  color: #fff;
  font-weight: 600;
}
.form-bg.contact-upload.p-3 .uploadCsv {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 4px;
  border: 1px solid #ccc;margin: 1em 0 0;
}
.choose-file h3 i {
  font-size: 20px;
}


@media only screen and (max-width: 767px) {
  .res-pl{
    display:flex;
    justify-content:center;
  }
  .form-bg{
    width:100%;
  }
  .clear-text{
    width: 100%;
  }
  .foot-content{
    justify-content:start;
  }
  .banner-heading{
    border-left: none;
  }
  .clearfil{
    width:100%;
    position:relative;
    top:0px;
  }
}



.root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadCsv {
  width: 35%;
  margin: auto;
}
#csv-upload {
  margin-top: 20px;
  position: relative; z-index: 99;
  padding: 0px 0;
  position: relative; z-index: 99; display: block;
}
#csv-uploaded {
  position: relative;
  width: 100%;
  text-align: center;
}
#csv-uploaded:hover #file-upload-image{
  opacity: 0.3;
}
#csv-uploaded p:first-child {
  margin-top: 10px;
}
#csv-uploaded p {
  padding: 5px 25px;
}
.choose-file  h3{
   text-align: center;
  text-transform: capitalize;
  color: #454545;
  font-size: 20px;
  padding: 10px 0;
  background: #57ab1a;
  color: #fff;
  font-weight: 500;
  }
  
.upload-info {
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bolder;
}

.css-26l3qy-menu {
  z-index: 99999;
}
.form-control1 .css-1s2u09g-control, .form-control1 .css-1s2u09g-control:hover, .form-control1 .css-1s2u09g-control:focus, .form-control1 .css-1pahdxg-control{
  border-color: transparent !important;
}
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body { font-family: 'Open Sans', sans-serif !important; margin: 0; padding: 0; width: 100%; background: #fff !important;
  scroll-behavior: smooth;
  background-size: cover; position: relative; color: #454545 !important; font-size: 16px !important;
}
* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
.onboarding-form .bs-stepper-content::before {
content: none;
background: none;
}
header.jsx-3979001018 {
position: relative;
position: -webkit-sticky;
position: sticky;
top: 0;
background-color: #ff4040;
width: 100%;
height: auto;
height: var(--header-height,auto);
padding: 0 7%;
padding: 0 var(--header-navigation-compact-px,7%);
padding-top: 30px;
margin: 0 auto;
z-index: 100;
}
button.step-trigger .bs-stepper-circle {

  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  padding: .5em 0;
  margin: 0;
  line-height: 1em;
  color: #fff;
  background-color: transparent;
  border-radius: 1em;
  font-size: 15px;

}


.container.jsx-3979001018 {
max-width: 1290px;
margin: 0 auto;
position: relative;
}
.container.jsx-2180139827 {
display: flex;
}

.logo-link.jsx-96647813 {
display: block;
}
a {
color: #3047ec;
-webkit-text-decoration: none;
text-decoration: none;
}
.horizontal_spacer.jsx-2180139827 {
flex-grow: 1;
}
.cta-wrapper.jsx-2180139827:not(:last-child) {
margin-right: 5px;
}
@media (max-width: 1439px){

.cta.jsx-4163279887 .email-form {
  max-width: 278px;
}
}

.email-form.jsx-403594908 {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: center;
}
span.jsx-75452321 {
-webkit-clip: rect(1px,1px,1px,1px);
clip: rect(1px,1px,1px,1px);
-webkit-clip-path: inset(50%);
clip-path: inset(50%);
height: 1px;
width: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
}
.email-form__input-wrapper.jsx-403594908 {
flex: 999 0;
width: 100%;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
  min-width: auto !important;
}
}
.email-form__input.jsx-403594908 {
width: 100%;
outline: 0;
border: 2px solid #0a2351;
color: #181818;
border-radius: 6px 0px 0 6px;
font-size: 15px;
line-height: unset;
}
.email-form__input.jsx-403594908 {
line-height: 1.4;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
input {
font: inherit;
margin: 0;
padding: 0;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
  min-width: auto !important;
}
}
.email-form__submit--black-fill.jsx-403594908 {
background-color: #ff4040;
color: #ffffff;
}
.email-form__submit.jsx-403594908 {
display: inline-block;
border-radius: 0px 6px 6px 0px;
padding: 12px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
transition: all 200ms;
cursor: pointer;
flex-shrink: 0;
font-weight: bold;
position: relative;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
button {
border: none;
margin: 0;
padding: 0;
text-align: inherit;
background: transparent;
color: inherit;
font: inherit;
line-height: normal;
-webkit-font-smoothing: inherit;
-moz-osx-font-smoothing: inherit;
-webkit-appearance: none;
}

span.jsx-148622701, div.jsx-148622701 {
font-size: 15px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
line-height: 1.4;
}

@media (min-width: 1024px) {
span.jsx-3289410788, div.jsx-3289410788 {
  font-size: 26px;
}
.link.jsx-277724099 {
  margin-right: -7px;
}
}
.link.jsx-277724099 {
  display: inline-block;
  padding: 7px 12px;
  position: relative;
  width: auto;
  color: rgb(24, 24, 24);
  transition: all 250ms ease 0s;
}

main {
  overflow: hidden;
  outline: 0;
}
@media (min-width: 768px) {

.cta-wrapper.jsx-360272744 {
  justify-self: unset;
}
span.jsx-3289410788, div.jsx-3289410788 {
  line-height: 1.4;
  -webkit-letter-spacing: unset;
  -moz-letter-spacing: unset;
  -ms-letter-spacing: unset;
  letter-spacing: unset;
  font-size: 22px;
}
.subheading.jsx-2340029290 {
  margin: var(--margin);
  margin-bottom: 36px;
  max-width: 800px;
}
.headline.jsx-2340029290 {
  margin-bottom: 24px;
}
.content.jsx-3751704983 {
  grid-column: left-content;
}
.two-columns.jsx-3751704983 .content.jsx-3751704983 {
  grid-column: left-content / span 3;
}
.two-columns.jsx-3751704983 .hero.jsx-3751704983 {
  grid-template-columns: 1fr [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr 1fr;
}
section.jsx-618185480 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.email-form__input.jsx-403594908 {
  min-width: 210px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  line-height: 1.4;
}

.primary.jsx-360272744 {
  margin: 0 0px 22px 0;
}
.cta.jsx-360272744 {
  display: inline-block;
}
.email-form__submit.jsx-403594908 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.hero-wrapper.two-columns.jsx-3751704983 {
  grid-template-columns: [content] 14fr;
}
  .hero-wrapper.jsx-3751704983 {
    padding: 50px 0;
  }
}

.hero-wrapper.jsx-3751704983 {
  display: grid;
  grid-template-columns: [left-gutter] 1fr [content] 12fr [right-gutter] 1fr;
  justify-content: center;
  padding: 50px 0;
}
.hero-wrapper--white {
  background-color: #ffffff;
}


.hero.jsx-3751704983 {
  grid-column: content;
  position: relative;
  display: grid;
  grid-template-columns: [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr;
  grid-column-gap: 24px;
  align-content: center;
  justify-items: center;
}

.content.jsx-3751704983 {
  grid-column: left-content / span 3;
  order: undefined;
}
.content.jsx-3751704983 {
  display: grid;
  text-align: center;
  justify-items: center;
  align-content: center;
  z-index: 1;
  position: relative;
}


.headline.jsx-2340029290 {
  max-width: 1024px;
  margin-bottom: 16px;
  margin-top: 0;
}
@media (min-width: 990px) {

span.jsx-1631410664, div.jsx-1631410664 {
  font-size: 60px;
  font-weight: 600;
}
}

span.jsx-1631410664, div.jsx-1631410664 {
  font-size: 60px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}

.subheading.jsx-2340029290 {
  margin-bottom: 21px;
}


span.jsx-3289410788, div.jsx-3289410788 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
}


.cta-wrapper.jsx-360272744 {
  justify-self: stretch;
}


.cta.jsx-360272744 {
  margin: 0 0 22px 0;
}

.plan-column-group {
margin: 0 auto;
width: 100%;
max-width: 1290px;
padding-top: 24px;
}
.plan-column-group__header {
margin-bottom: 20px;
text-align: center;
font-family: Graphik,Helvetica,Arial,sans-serif;
}
.rich-text p:last-of-type {
margin-bottom: 0;
}

.rich-text p {
margin-bottom: 1em;
line-height: 1.7;
}
p {
margin: 0;
}
.plan-column-group__children {
display: flex;
justify-content: center;
}
@media (min-width: 768px){
.plan-column-group__header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.plan-column-group-wrapper--tan {
  background-color: #f4f0eb;
}

main {
  padding-top: 70px;
}
}
.plan-column-group-wrapper {
padding: 0 24px 20px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper:first-child {
  align-items: flex-end;
}
}

.plan-column-group__children>* {
  margin: 0 10px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper {
  margin-bottom: 0px;
}
}
.plan-columns-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid #f4f0eb;
  flex: 1 1;
}
@media (min-width: 1024px) {

.plan-columns {
  min-width: 400px;
}
}

.plan-columns {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

@media (min-width: 768px) {

.plan-columns>*:last-child {
  border-bottom-right-radius: 8px;
}
}

@media (min-width: 768px) {

.plan-columns>*:first-child {
  border-bottom-left-radius: 8px;
}
}
.pricing-tier-card.pricing-tier-card--rounded {
  border-radius: 6px;
}
@media (min-width: 665px) {

.plan-columns>* {
  border-top: 2px solid #f4f0eb;
}
}
.plan-columns>* {
  flex-grow: 1;
}
.pricing-tier-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 395px;
  width: 100%;
}

@media (min-width: 768px) {

.pricing-tier-card__section:first-child {
  border-bottom: 2px solid #f4f0eb;
}
}

.pricing-tier-card__section {
  padding: 22px 28px 10px 28px;
}

.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
@media (min-width: 665px) {

.pricing-tier-card__badge-wrapper--hide-on-mobile {
  display: block;
}
}
.pricing-tier-card__badge-wrapper--hide-on-mobile {
display: none;
}
.pricing-tier-card__badge-wrapper {
height: 45px;
margin-top: -5px;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

.pricing-tier-card__category {
padding-bottom: 0 !important;
}

@media (min-width: 1024px) {

span.jsx-3980862901, div.jsx-3980862901 {
  font-size: 36px;
}
}
@media (min-width: 990px) {

span.jsx-3980862901, div.jsx-3980862901 {
  font-size: 30px;
  line-height: 1.2;
}
}
span.jsx-3980862901, div.jsx-3980862901 {
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
}

.pricing-tier-card__title.graphik-font span {
font-family: Graphik,Helvetica,Arial,sans-serif;
}
@media (min-width: 768px) {

span.jsx-4108527491, div.jsx-4108527491 {
  font-size: 36px;
  line-height: 1.2;
}
}
span.jsx-4108527491, div.jsx-4108527491 {
font-size: 36px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
line-height: 1.2;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.pricing-tier-card__description {
height: 75px;
max-width: 520px;
}
span.jsx-166584131, div.jsx-166584131 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.cta-link--black-outline {
    border: 2px solid #0a2351;
    background-color: #0a2351;
    color: white !important;
}
.cta-link--wide {
width: 100%;
}
.cta-link {
display: inline-block;
border-radius: 6px;
padding: 12px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
transition: all 200ms;
cursor: pointer;
}
span.jsx-4266488777, div.jsx-4266488777 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

@media (min-width: 768px) {

.pricing-tier-card__price-for-period {
  font-size: 20px;
}
}

@media (min-width: 1270px) {

.pricing-tier-card__show-on-desktop {
  display: block;
}
}

.pricing-tier-card__show-on-desktop {
  display: none;
}
.pricing-tier-card__price-for-period--dollar-amount {
font-family: Graphik,Helvetica,Arial,sans-serif;
font-weight: bold;
line-height: 1.4;
}
.pricing-tier-card__price-for-period-subtext {
height: 17px;
}
.copy-right {
position: relative; font-weight: 700;
width: 100%;
background: #6a6a6a;
padding: 20px 0px;
text-align: center;
color: #fff;
font-family: 'Open Sans', sans-serif;
border-top: 0px solid #ccc; font-size: 16px;
}
.copy-right ul li a {
color: #fff;
}

.copy-right ul {
margin: 0;
list-style: none;
color: #fff;
padding: 0;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}
.signup__teams__sidebar__product-box-title-text {
font-weight: 700;
}
.signup__teams__container * {
box-sizing: border-box;
}
.flex-row.gap-4>:last-child {
margin-right: 0;
}
*, ::after, ::before {
border-width: 0;
border-style: solid;
border-color: rgba(0,0,0,.1);
}
.flex-col {
flex-direction: column;
}
.flex {
display: flex;
}
.application__component {
color: #222;
background-color: #f1f1f1;
height: 100%;
}

.signup__teams__body {
font-family: 'Open Sans', sans-serif;
font-size: 15px;
font-weight: 400;
letter-spacing: .01em;
}
.justify-between {
justify-content: space-between;
}
.signup__teams__sidebar {
background-color: transparent;
width: 420px;
padding: 80px 25px 0;
justify-content: space-between;
overflow: hidden;
}
@media (min-width: 1025px){}
.signup__teams__sidebar__container {
  order: 3;
  flex: 0 0 auto;
}
@media (min-width: 768px){}
.form-box {
margin-bottom: 50px !important;
  margin-top: 50px !important;
  flex: 0 0 auto;
  width: 50%;
}

.email-not-verified {
text-align: center;
font-size: 25px;
margin: 35px;

}
.MuiPaper-root.MuiCard-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
margin: 0vh auto !important;
}
.jsx-1928597095.pricing-tier-card__price-for-period {
text-align: center !important;
font-size: 23px !important;
}

.StripeElement {
margin: 15px auto;
padding: 10px 12px;
color: #32325d;
background-color: white;
border: 1px solid #ccc;
border-radius: 4px;
}

.StripeElement--webkit-autofill {
background-color: #fefde5 !important;
}

.product {
width: 100%;
max-width: 450px;
margin: auto;
box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
border-radius: 10px;
overflow: hidden;
}

.btn-pay {
display: block;
width: 100%;
border: none;
background: linear-gradient(
  135deg,
  rgb(49, 0, 62) 0%,
  rgb(195, 40, 110) 100%
);
color: #fff;
padding: 10px;
font-size: 18px;
cursor: pointer;
}

.product-info {
padding: 0 16px;
}

h3.product-title {
font-size: 28px;
margin-bottom: 15px;
}

h4.product-price {
font-size: 24px;
margin: 0;
margin-bottom: 30px;
color: #777;
font-weight: 500;
}
.step-btn {
width: 100%;
}
.next {
margin-top: 30px;
}
label {
width: 100%;
}
.bs-stepper .step-trigger {
  display: inline-flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: #6c757d !important;
  text-align: center !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
      user-select: none !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: .25rem !important;
  transition: background-color .15s ease-out,color .15s ease-out !important;
  padding-bottom: 31px !important; position: relative; z-index: 99;
}
.form-control1 {
display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}

.signup__teams__sidebar__product-box {
border-top: 10px solid #fbc916;
background-color: #fff;
padding: 24px 24px 30px;
}
.signup__teams__container * {
box-sizing: border-box;
}
p.text.o__muted {
padding: 5px 0;
}

.signup__teams__container * {
box-sizing: border-box;
}

.signup__teams__option-divider {
text-align: center;
position: relative;
z-index: 1;
font-size: 16px;
}
.signup__teams__option-divider:before {
background-color: transparent;
border-top: 1px dotted #454545;
display: block;
content: '';
position: absolute;
width: 100%;
height: 2px;
left: 0;
top: 50%;
z-index: 0;
}
.h-2 {
height: 8px;
}
.h-5 {
height: 20px;
}

body {
scroll-behavior: smooth;
overflow-x: hidden;
}
.mar-bt {
margin-bottom: 6px !important;
margin-top: 6px !important;
}

.step {
position: relative;
}
/*.step.active::before {
background: #ff4040;
}*/
.active .bs-stepper-circle {
  background-color: transparent !important;
}
.bs-stepper
.step .step-trigger {
  background: #54ab1a80 !important;
  display: inline-block !important;
  color: #fff !important;
  padding: 0 10px 8px !important;
  border-radius: 30px !important;
  margin: 0 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;opacity: 1 !important;
}
.bs-stepper .line, .bs-stepper-line { display: none; }
.bs-stepper .step.active .step-trigger::before {
  background: #54ab1a !important;
  transition: all 0.5s ease-in-out 0s;
  right: -21px; opacity: 1;
}
.bs-stepper-header .step:last-child .step-trigger::before {
  content: none;
}
.step-trigger::before {
  position: absolute;
  content: "";
  background: #ecebeb57;
  height: 5px;
  width: 18%;
  right: 0;
  z-index: -1;
  bottom: auto;
  top: 18px; opacity: 0;
  left: auto;
  margin: 0 auto;
  text-align: center;
}
.bs-stepper-header .step:nth-child(1) .step-trigger {
  margin-left: 0;
}
.bot-lp-real-box .new-pricing-plan table tr td h2 {
background: #ff4040;
}
.bs-stepper .step.active .step-trigger {
  color: #fff !important;
  opacity: 1;
  background: #54ab1a !important;
}
.new-pricing-plan table {
max-width: 1140px;
margin: 0 auto;
}
.new-pricing-plan table tr {
display: flex;
}
.new-pricing-plan table tr td:nth-child(3n+1) {
margin-left: 0;
}
.new-pricing-plan table tr td {
float: left;
width: 33%;
margin-left: 0%; box-shadow: unset;
}
.hero-content {
text-align: center;
/* background: #ff4040;*/
background-image: linear-gradient(to right, #fe9365, #feb798) !important;
padding: 5em 0;
}
.hero-content h1 {
color: #fff;
font-size: 28px;
}
.hero-content p {
color: #fff;
font-size: 22px;text-transform: capitalize;
}
.new-pricing-plan {
position: relative; margin-bottom: 3em;
}
.new-pricing-plan::before {
background-image: linear-gradient(to right, #fe9365, #feb798) !important;
content: "";
position: absolute;
left: 0;
right: 0;
width: 100%;
height: 9%;
z-index: -1;
}
.new-pricing-plan table tr td p {
color: #454545;
font-size: 16px;
letter-spacing: 0.3px;
text-align: center; padding: 0 1em;
}
.pricing-title span.price-range {
font-size: 45px;
font-weight: 500;
color: #454545;
font-family: 'Open Sans';
}
.new-pricing-plan table tr td:nth-child(3n+2) p, .new-pricing-plan table tr td:nth-child(3n+2) .pricing-title span.price-range, .new-pricing-plan table tr td:nth-child(3n+2) .starts, .new-pricing-plan table tr td:nth-child(3n+2) .month, .new-pricing-plan table tr td:nth-child(3n+2) .features-lists > p {
  color: #fff;
  border-color: #fff;
}
.new-pricing-plan table tr td:nth-child(3n+2) .cta-link {
  background: #fff !important;
  color: #fe9365 !important;
}
.new-pricing-plan table tr td:nth-child(3n+2) .cta-link:hover{
  background: transparent !important;
  color: #fff !important;
}
.main-area {
  padding-bottom: 3em;
}
.pricing-title {
margin: 0 auto;
text-align: center;
padding: 2em 0 1em;
max-width: 250px;
}
.price-range a {
padding: 7px;
display: block;
margin: 1em auto;
text-align: center;
max-width: 160px;
border-radius: 3px;
}
.new-pricing-plan table tr td h2 {
  background: hsla(0,0%,100%,.3);
  text-align: center;
  font-size: 20px;
  color: #fff;
  padding: 14px 0;
  border-right: 1px solid #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 1em;
}
.new-pricing-plan table tr td:last-child h2 {
border-right: 0;
}
.new-pricing-plan table tr td:hover { cursor: pointer; transition: 0.4s ease 0s; background: #8e8888a3; }
.new-pricing-plan table tr td .features-lists ul li i {
float: left;
vertical-align: middle;
padding: 4px;
background: linear-gradient(to right, #fe9365, #feb798) !important;
border-radius: 100%;
color: #fff;
font-size: 10px;
font-weight: 700;
margin: 4px 5px 0;
}
.price-range.__cta.pricing-tier-card__cta--bottom {
  text-align: center;
  padding: 1em 0;
}
.new-pricing-plan table tr td .features-lists ul li {
list-style: none;
padding: 4px 0; display: block;
}
.new-pricing-plan table tr td .features-lists p {
text-align: left;
}
.new-pricing-plan table tr td .features-lists ul {
padding: 0;
text-align: left;
}
.new-pricing-plan table tr td .features-lists {
padding: 1em 1.5em;
text-align: left;
}
.new-pricing-plan table tr td .features-lists > p {
text-align: left;
border-bottom: 2px solid #696464;
display: inline-block;
margin-bottom: 15px; padding: 0;
}
.starts {
font-size: 15px;
color: #454545;
line-height: 0;
font-style: italic;
text-align: left;
}
.new-pricing-plan table tr td:nth-child(3n+1), .new-pricing-plan table tr td:nth-child(3n+3) {
  margin-left: 0;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.new-pricing-plan table tr td:hover h2, .new-pricing-plan table tr td:hover .starts, .new-pricing-plan table tr td:hover .pricing-title span.price-range, .new-pricing-plan table tr td:hover .month, .new-pricing-plan table tr td:hover .features-lists > p,
.new-pricing-plan table tr td:hover p { color: #fff; }
.new-pricing-plan table tr td:hover .price-range a { background: #454545; }
.logo-wrapper {
margin: 0 auto;
text-align: center;
}
.new-pricing-plan table tr td:nth-child(3n+2) {
  box-shadow: unset;
  border: 0px solid #ccc;
  background: linear-gradient(to right, #fe9365, #feb798) !important;
}
.bs-stepper-label {

font-size: 15px;
}
table#business_hours .form-select {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important;
  padding: 8px 10px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
}
.form-group .table tr td input {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 30px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
p.error-message {
  font-size: 16px;
  padding: 10px 0 0;
  font-weight: 400;
  font-family: system-ui;
}
button.btn.btn-primary.step-btn, #add, button.btn.btn-primary, .form-select.btn-primary{
  display: block;
  width: auto;
  margin: 0 auto;
  padding: 6px 2em;
  border-radius: 20px;
  background: transparent !important;
  border: 0;
  font-size: 16px;
  font-weight: 700;position: relative;
  z-index: 999;
      color: #54ab1a; transition: all 0.5s ease-in-out 0s;
  border: 2px solid; overflow: hidden;

}
.form-group label {
  color: #454545; margin-top: 1em; font-size: 18px;
  font-weight: 400; position: relative; z-index: 999; text-transform: capitalize;
}

.bs-stepper-content .form-control1 {
  font-size: 16px;
  border: 0;
  color: #454545;
  padding: 10px 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans';
  position: relative;
  z-index: 99;
}
.bs-stepper-content input:focus, .bs-stepper-content select:focus {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important; }
button#subscribe {
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  border-radius: 30px;
  margin: 0 auto;
}
.MuiCardContent-root.jss2 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid #ccc;
}
input.jsx-403594908.email-form__input::-webkit-input-placeholder, .bs-stepper-content .form-control1::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #454545;opacity: 0.5;
}
input.jsx-403594908.email-form__input::-moz-placeholder,
.bs-stepper-content .form-control1::-moz-placeholder { /* Firefox 19+ */
color: #454545;opacity: 0.5;
}
input.jsx-403594908.email-form__input:-ms-input-placeholder,
.bs-stepper-content .form-control1:-ms-input-placeholder { /* IE 10+ */
color: #454545;opacity: 0.5;
}
input.jsx-403594908.email-form__input:-moz-placeholder,
.bs-stepper-content .form-control1:-moz-placeholder { /* Firefox 18- */
color: #454545;opacity: 0.5;
}

button.btn.btn-primary.step-btn::before, .cta-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #54ab1a;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; border-radius: 30px;
}
button.btn.btn-primary.step-btn, #add, button.btn.btn-primary, .form-select.btn-primary, .cta-link {
 
  -webkit-transform: perspective(1px) translateZ(0);
 
          transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  transition-property: color;
  transition-duration: 0.3s;
}
button.btn.btn-primary.step-btn:focus {
border: 2px solid #54ab1a !important;
}
.input-group-append .btn-primary.active, .input-group-append .btn-primary:active, .open>.dropdown-toggle.btn-primary {
background: #54ab1a !important; color: #fff !important;
}
.btn-primary:hover { color: #fff !important; }
button.btn.btn-primary.step-btn:hover::before, .cta-link:hover::before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1); color: #fff;
}
.new-pricing-plan table tr td:nth-child(3n+2) .cta-link:hover::before { color: #fff; }
.footer-1{ background: #54ab1a; }
button#coupon_status:hover, button#integrate:hover {
  background: #54ab1a !important; color: #fff;
}
.btn-primary:hover, button.btn.btn-primary.btn-apply::hover {
  color: #fff !important;
  background:#54ab1a !important;
  border-color: transparent !important;
}

span#add:hover {
  background: #54ab1a !important;
}
/*** onboarding styles ***/
.conform-details ul li strong {
  /* background: #cccccc7a; */
  padding: 10px;
  width: 115px;
  display: inline-block;
  font-size: 15px;
  margin: 0 15px 0 0px;
  background: #54ab1a;
  color: #fff;
}
.res .conform-details ul li strong {
  width: 125px;
  color: #fff;
}
.onboarding-form .bs-stepper-header {
  display: block;
}
.onboarding-form .bs-stepper-label, .onboarding-form .bs-stepper-circle { display: none; }
.onboarding-form .bs-stepper .step.active .step-trigger, .onboarding-form .bs-stepper .step .step-trigger { margin: 0; box-shadow: none; background: transparent !important; }
.onboarding-form .step-trigger::before { content: none; }
.onboarding-form .step {
  padding: 0;opacity:1;
}
.onboarding-form .step.active {
  opacity: 1;
}
.onboarding-form .step.active .round::after {
  border: 2px solid #54ab1a;
}
/*.onboarding-form .step:last-child .round:after {
  border: 0;
}*/
.onboarding-form .bs-stepper-content { padding: 0; }

.onboarding-form {
  max-width: 900px;
  margin: 0 auto;
  display: block;
  padding: 1em 0;
}
.onboarding-form .round::before {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 0px solid #6b6969;
  position: absolute;
  content: "";
  background: #54ab1a;
  left: 0;
  top: 0;
  box-shadow: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em, rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
}
.step-content img {
  max-width: 34px;
  vertical-align: -12px;
  padding: 2px;
}
.onboarding-form form {
  padding: 0;
}
.onboarding-form button.btn.btn-primary.step-btn, .onboarding-form #add, .onboarding-form button.btn.btn-primary, .onboarding-form .form-select.btn-primary {
  display: block;
  padding: 3px 25px;
}
.onboarding-form p, .onboarding-form p.fb-noti {
  font-size: 15px; margin: 0; text-transform: none; padding: 5px 0;
}
.onboarding-form .round {
  position: relative;
  padding: 5em 0;
}

.onboarding-form .step .round::after {
  background: #54ab1a;
  position: absolute;
  content: "";
  width: 2px;
  top: 12px;
  left: 8px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #57ab1a;
  height: 100%;
  z-index: -1;
}
.onboarding-form .bs-stepper .content {
  text-align: left !important;
}
.onboarding-form .step:last-child .round::after { content:none; }
.onboarding-form .step:nth-child(5) .round {
  padding: 4em 0;
}
.onboarding-form .step:nth-child(4) .round {
padding: 3em 0;
}
.onboarding-form .step:nth-child(6) .round {
padding: 5em 0;
}
.onboarding-form .step:nth-child(2) .round {
  padding: 11em 0;
}
.onboarding-form .step:nth-child(7) .round {
padding: 11em 0;
}
.step-content {
  min-height: 130px;
  opacity: 1 !important;
  display: block !important;visibility: visible !important;
}
.step-content:nth-child(5) {
  min-height: 104px;
}
.step-content:nth-child(4) {
min-height: 90px;
}
.step-content:nth-child(2) {
  min-height: 273px;
}
.step-content .form-group label {
  font-size: 15px;
  color: #444;
}
.step-content label {
  font-weight: 400;
  padding: 0;
  margin: 0 0 8px;
  font-size: 15px;
}
.onboarding-form .col-md-1 {
  text-align: right;
}
.onboarding-form .step-content p i {
  font-size: 15px;
  padding: 0 4px;
  color: #57ab1a;
}
.onboarding-form .step-content.active {
  opacity: 1 !important;
}
.addmore #drag-text-new, .addmore #drag-text-new-1 {
  font-size: 30px;
  background: #54ab1a;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 50%;
  color: #fff; width: 27px;
  line-height: 0.8; font-weight: 700; display: none;
}
.conform-details ul {
  padding: 0.5em 0 0;
  max-width: 450px;
  margin: 0 auto;
  /* columns: 2; */
}
.conform-details ul li {
  display: block;
  text-align: left;
  padding: 0;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.step-form p {
  font-size: 16px;
}
.step-form .form-group label {font-size: 16px;}
.col-md-1.offer {
  text-align: right;
  padding: 9px 0 0;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  min-width: 400px !important;
  padding: 25px !important;
  text-align: center !important;
}


.updated .form-control1 {
height: auto;
width: 70%;
text-align: left;
float: left;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding: 13px;
  margin: -4px -13px;
  width: 75%;
  border: 0 ;
  font-size: 15px;
  border: 0px solid #ccc;
  border-left: 0;
  border-radius: 0;
}
.updated .conform-details ul li {
text-align: left;
display: inline-block;
width: 100%;
padding: 5px;
margin: 8px 0;
border: 1px solid #ccc;
border-radius: 3px;
box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.updated .conform-details ul li strong {
padding: 10px;
width: 25%;
display: inline-block;
font-size: 15px;
margin: 0 15px 0 0px;
background: #54ab1a;
color: #fff;
float: left; }
.react-datetime-picker__inputGroup {
  border: 1px solid #c1bbbb !important;
}
.form-group .react-datetime-picker__inputGroup input {
  border: 0px solid #c1bbbb !important;
}
.not-found-page .cont-sec h3 {
font-size: 85px;
color: #57ab1a;
font-weight: 700;
font-family: inherit;
margin: 0;
}
.not-found-page .cont-sec h4{
font-size: 55px;
  color: #454545;
  font-weight: 100;
}
.not-found-page .cont-sec {
padding: 5em;
text-align: left;
}
.guarantee-image {
padding-top: 0; margin-top: 2em;
}
.guarantee-image a.btn-home {
background: #ffa844;
  color: #000;
  font-weight: 700; margin-left: auto; margin-right: auto; display: block;
}
.guarantee-image a.btn-home:hover {
background: #57ab1a;
color: #fff;
text-decoration: none;
}
.guarantee-image img  { display: block; margin: 0 auto; }
.guarantee-image.mobile  { display: none; }
.guarantee-image.desktop  { display: block; padding: 0; }
.rotate {
margin: 20px;
width: auto;
height: auto;
position: relative;
  display: inline-block;
  width: 100%;
  z-index: 99;
-webkit-animation-name: spin;
        animation-name: spin;
-webkit-animation-duration: 3000ms;
        animation-duration: 3000ms;
-webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
-webkit-animation-timing-function: linear;
        animation-timing-function: linear;
}
.guarantee-image .static{
width: 150px;
background: #000;
border-radius: 50%;
padding: 15px;
position: absolute;
top: 19px;
left: 0;
right: 0;
margin: 0 auto;
}

@-webkit-keyframes spin {
from {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
}
to {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
}
}

@keyframes spin {
from {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
}
to {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
}
}


/*** animation starts ****/

.blink-image1 { /* Firefox */
-webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */ /* IE */
animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
@keyframes fade {
from { opacity: 1; }
50% { opacity: 0.9; border: 5px solid #54ab1a; }
to { opacity: 1.0; }
}                                                                                                                                                                                                                                  

@-webkit-keyframes fade {
from { opacity: 1.0; }
50% { opacity: 0.9; border: 5px solid #54ab1a; /*background-color: #54ab1a;*/}
to { opacity: 1.0; }
}
.hand {
display: inline-block !important;
vertical-align: -5px;
max-width: 20px;
}
.banner-bg .stamp img {
position: absolute;
top: 24px;
bottom: 0;
display: inline-block;
margin-top: 0 !important;left: 0;
right: 0;
margin: 0 auto;
}
.stamp {
position: relative;
animation:fade 1000ms infinite;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
-webkit-animation:fade 1000ms infinite; border-radius: 50%;width: 200px;
height: 200px; margin: 0 auto;
}

@-webkit-keyframes blink {
0% {
    opacity:0.5;
}
50% {
    opacity:0.8;
}
100% {
    opacity:1; -webkit-transform: scale(1); transform: scale(1);
}
}
/* IE */
/* Opera and prob css3 final iteration */
@keyframes blink {
0% {
    opacity:0.5;
}
50% {
    opacity:0.8;
}
100% {
    opacity:1; -webkit-transform: scale(1); transform: scale(1);
}
}

@-webkit-keyframes fadeIn {
from { opacity: 0; }
}

@keyframes fadeIn {
from { opacity: 0; }
}

.hand {
-webkit-animation: fadeIn 1s infinite alternate;
        animation: fadeIn 1s infinite alternate;
}


/* Firefox old*/

@-webkit-keyframes blink {
0% {
  opacity:1;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
}
/* IE */
/* Opera and prob css3 final iteration */
@keyframes blink {
0% {
  opacity:1;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
}
.blink-image2 { /* Firefox */
-webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */ /* IE */
animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
.main-content h2, .main-content h3, .main-content h4 {
text-transform: uppercase;
margin: 1.5em 0 0.4em;
letter-spacing: 0.1em;
color: #7b8a75;
}
.main-content p {
font-size: 16px !important;
}
.privacy-header {
  font-weight: 900;
  color: #54ab1a;
  text-transform: uppercase;
  margin-top: 40px;
  font-size: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 40px;
  text-align: center;
  font-weight: bolder;
}


/* Gokul */

.afflicate-banner {
background-color: #54ab1a;
text-align: center;
padding: 50px 0;
}

.afflicate-banner h6{
font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #fff;
  font-weight: bolder;
}

.afflicate-banner h2 {
font-size: 4rem;
margin-top: 20px;
font-weight: 700;
line-height: 1.2; color: #fff;
}
.afflicate-banner h2 span {
font-weight: 300 !important;
letter-spacing: -.05em;
}

.text_column{
margin: 45px 0px;
}
.text_column p {
margin-bottom: 20px;
}

.affliate-button{
margin: auto;
padding: 20px 0;
color: #fff;
border-color: #ffa844;
background-color: #ffa844;
font-size: 16px;
padding-top: 18px;
padding-bottom: 18px;
padding-left: 25px;
padding-right: 25px;
border-radius: 5px;
display: inline-block;
margin-bottom: 0;
text-align: center;
vertical-align: middle;
cursor: pointer;
}

a.affliate-button:hover{
text-decoration: none;
}
.affliate-two .row, .affliate-three, .affliate-four {
padding: 45px 0px;
}

.affliate-three h3{
font-size: 3rem;
margin-top: 20px;
font-weight: 700;
line-height: 1.2;
margin: 20px 0px;
}


.affliate-four {
text-align: center;
}
.affliate-four h2 {
line-height: 1.3;
color: #000;
font-size: 3rem;
margin-top: 20px;
font-weight: 700;
line-height: 1.2;
}
.affliate-four h2 span {
font-weight: 300 !important;
letter-spacing: -.05em;
font-size: 34px;
}
.affliate-four .row {
text-align: left;
}
.container-sml {
max-width: 700px;
margin: auto;
  width: 100%;
}

.affiliate-form .main-section {
border-radius: 8px;
border: 0px solid #e6e6e6;
box-shadow: 0 2px 15px 0 rgb(0 0 0 / 7%);
margin: 0 auto;
margin-top: 20px;
margin-bottom: 40px;
padding: 20px;
color: #656565;
}

.affiliate-form .heading:first-child {
margin-top: 20px;
font-weight: 400;
font-size: 42px;
text-align: center;
line-height: 55px;
}

.affiliate-form .heading+.affiliate-form .subheading {
margin-top: 15px;
}
.affiliate-form .subheading {
line-height: 35px;
font-size: 21px;
text-align: center;
font-weight: 300;
}
.affiliate-form .subheading p {
color:  #656565;
font-size: 16px;
  line-height: 1.4;
}
.get-in-touch .form-group {
margin-bottom: 1em;
}
.get-in-touch .form-control, .get-in-touch input#example-datepicker, .get-in-touch .form-group input {
border: 1px solid #c1bbbb !important;
height: auto;
font-size: 16px;
padding: 10px 10px;
border-radius: 5px;
}
.get-in-touch form .form-group .col-md-6 input {
border: 1px solid #c1bbbb !important;
height: auto;
font-size: 16px;
padding: 10px 10px;
border-radius: 5px;
}
.form-group .col-md-6 {
color: #6d6d6d;
text-align: left;
}
input.lp-btns {
width: 24%;
/* background-size: cover !important; */
padding: 8px 0;
text-align: center;cursor: pointer;
}
input#example-datepicker {
width: auto;
max-width: 185px;
}
.get-in-touch textarea { height: 150px !important; }
.get-in-touch input#cc {
width: auto;
/* border-radius: 0; */
max-width: 49px;
}
.form-banner button.lp-btns {
background: #f9a743;
margin: 0;
padding: 5px 20px;
}
button.lp-btns {
background: #54ab1a;
padding: 13px 30px;
display: inline-block;
margin: 1em auto;
color: #fff;
font-weight: 600;
border-radius: 3px;
}
.home.get-in-touch {
text-align: center;
padding: 2em 0;
}
.thankyou-cont {
text-align: center;
}
.event-group form {
  max-width: 400px;
  margin: 0 auto;
}
.event-group form p {
  color: #54ab1a;
  font-weight: 700;
  padding: 1em 0 3px;
}
.event-group form .form-control {
  padding: 9px 10px;
  height: auto;
  border: 1px solid #c7bebe;
  border-radius: 4px;
}
.event-group form button.lp-btns {
  margin-top: 2em !important;
}
.event-group form .form-control::-webkit-input-placeholder {
  opacity: 1;
  color: #454545;
}
.event-group form .form-control:-ms-input-placeholder {
  opacity: 1;
  color: #454545;
}
.event-group form .form-control::placeholder {
  opacity: 1;
  color: #454545;
}
.event-group form .form-control:focus {
  border: 1px solid #ccc !important;
}
.event-group p {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 2em;
}


.navigation ul li ul {
  background: #ffa844; border: 1px solid #ccc;
  top: 40px;
  min-width: 100%;
  position: absolute !important;
  transition: all 0.5s ease;
  margin-top: 0;
  left: 0;
  display: none;
  transition: all 0.5s ease 0s;
  width: 200px;
  padding: 20px 15px;
  z-index: 9999999999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 3px;
}
.navigation ul.nav.navbar-nav li ul li span {
  color: #fff; cursor: pointer;
}
ul.nav.navbar-nav li span i.fa.fa-angle-down {
  font-size: 20px;
  color: #353434; padding: 0 5px;
  vertical-align: middle;
}
.navigation ul.nav.navbar-nav li ul li ul {
  left: 50%; top: 25px;
}
.navigation ul.nav.navbar-nav li ul li {
  margin-top: 0;
  padding: 7px 0;position: relative;
  font-weight: 700;
}

ul li:hover > ul,
ul li ul:hover {
  
  display: block;
}
ul li ul li {
  clear: both;
  width: 100%;
}
.subscrip input#plan::-webkit-input-placeholder {
  opacity: 1;
  color: #454545;
}
.subscrip input#plan:-ms-input-placeholder {
  opacity: 1;
  color: #454545;
}
.subscrip input#plan::placeholder {
  opacity: 1;
  color: #454545;
}
.form-group .col-md-12 { position: relative; z-index: 1;}
.blog .cont-sec ul {
  padding-left: 1em;
}
.blog .cont-sec ul li {
  display: list-item;
  list-style: disc;
}
.footerbar { flex-direction: column; }
/*Responsive Design starts */

.form-group i.fa.fa-trash-alt {
display: none;
}
.rest-signup .signupbtn {
  -webkit-appearance: none;
  appearance: none; border-radius: 3px;
}

@media (max-width: 1300px) {
/* .botlp-grp {width:350px;} */
.guarantee-image a.btn-home {
font-size: 16px;
padding: 10px;
vertical-align: middle;
}
}

@media (max-width: 1024px) {


.botlp-grp {
width: 275px;
}

.new {
color: #888;
font-size: 19px;
text-align: center;
top: 0em;
font-family: 'Open Sans', sans-serif;
}
.demo {
color: #888;
font-size: 25px;
text-align: center;
line-height: 1;
font-family: 'Century Gothic';
padding-bottom: 10px;
}
.message.bot-message {
font-size: 13px;
}
.df-chips-wrapper a,
.df-chips-wrapper a:hover {
font-size: 13px;
}
p.hours strong {
font-size: 40px;
}
}



@media (max-width: 1200px) {

.demo {
font-size: 35px;
}
.ancho-btns-lp ul li {    
padding: 3px 15px; }
.ancho-btns-lp ul li a {  
font-size: 15px; }
.ancho-btns-lp ul { padding: 0; }
.next button.btn.btn-primary.step-btn.company {
  width: auto !important;
}
#business_tbody td {
  min-width: 104px !important;
}
}

@media (min-width: 992px) {

.pricing .card:hover {
margin-top: -.25rem;
margin-bottom: .25rem;
box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
}
.pricing .card:hover .btn {
opacity: 1;
}

}

@media (max-width: 992px) {

.botlp-grp {
width: 275px;
}

.new {
color: #888;
font-size: 19px;
top: 0em;
font-family: 'Open Sans', sans-serif;
}
.demo {
color: #888;
font-size: 25px;
text-align: center;
line-height: 1;
font-family: 'Century Gothic';
padding-bottom: 10px;
}
.free-try .lead {
color: rgb(33, 150, 243);
font-size: 25px;
font-weight: 600;
font-family: 'Open Sans', sans-serif;
}
.message.bot-message {
font-size: 13px;
}
.df-chips-wrapper a,
.df-chips-wrapper a:hover {
font-size: 13px;
}
p.hours strong {
font-size: 40px;
}
.guarantee-image a.btn-home {

font-size: 14px;
padding: 10px; display: inline-block;
vertical-align: middle;
}

}

@media (min-width: 767px) and (max-width: 1024px){
#add{
  display: block;
  width: 20% !important;
}
.navigation ul li ul {
  min-width: 100%;
width: 150px;
}
.stamp {
 
  width: 150px;
  height: 150px;
left: -14px;
}
.banner-bg .stamp img {

top: 18px; }
}

@media only screen and (max-width: 991px) {
  .navigation ul li ul { display: none; }
  .navigation ul.nav.navbar-nav li ul li ul { left: 0;}
#add {
  margin: 0 1em !important;
  display: inline;
 
  text-align: center; }
.col-md-6.form-box, .step-form.test .col-md-4 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;margin: 1em auto !important;
}
.bs-stepper-header
{ text-align: center;
  align-items: center;
  justify-content: center;
}
#topiclinks-text {
  display: block !important;
}
.form-section {
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
}
.form-section .form-control {
width: 100%;
}
.botimage img,
.botimage {
position: relative;
}
.et_pb_et_price:after {  
font-size: 15px; }
.et_pb_sum {
font-size: 50px; }
.et_pb_pricing_content {
padding: 30px 20px; }
.et_pb_pricing li {
font-size: 15px;
}
.iframe-video {
width: 100%; }
.left-video {
text-align: center;
}
.left-video {
text-align: center;
padding-bottom: 2em;
}
.lpbanner {
padding: 6em 0;
}
.lpheader {
border-bottom: 1px solid #fff;
padding: 0;
}
.feature-video iframe {
width: 100%;
}
.ancho-btns-lp > a {
display: inline-block;
margin: 0 auto;
background: transparent;
color: #444;
font-family: 'Open Sans', sans-serif;
font-weight: 700;
font-size: 25px;
position: absolute;
border-radius: 3px;
top: -60px;
right: 35px;
}
.ancho-btns-lp ul {
display: none;
background: #67bb65;
box-shadow: 3px 1px 2px rgb(0 0 0 / 50%);
padding: 1em 0;
}
.ancho-btns-lp ul li a {
font-size: 16px;
}
.ancho-btns-lp ul li {
display: block;
text-align: left;
border-bottom: 1px solid #fff;
padding: 10px 18px !important;
}
.ancho-btns-lp ul li:last-child {
border-bottom: 0;
}
.ancho-btns-lp ul li:last-child a {
background: rgb(33, 150, 243);    
margin-top: 10px;
display: inline-block;
}
.lpheader .container {
padding: 0; max-width: 100%;
}
.ancho-btns-lp ul li a.highlight {
color: #fff;
}
.lpheader .logo {
text-align: left;
margin-bottom: 0;
padding: 10px 0;
margin-left: 2em;
}


}

@media(max-width:1200px){
.guarantee-image .static {
  width: 130px; top:17px; }
  .stamp { width: 180px; height: 180px; }
}
@media(max-width:860px){
.guarantee-image .static {
  width: 105px; top:17px; }
}


@media(max-width: 767px){
  .navigation ul li ul { transition: none; width: 100%; left: 0; right: 0; margin: 0 auto; min-width: auto;}
  .pricecol .card { max-width: 100%;}
.blog .cont-sec img {
    width: 100%;
}
body{
    -webkit-text-size-adjust: none;
}
.banner-bg .stamp img {    
  top: 20px; }
.guarantee-image.mobile  { display: block; }
.guarantee-image.desktop  { display: none; }
.guarantee-image .static{ top: 77px !important;
width: 159px !important; }
.animation-group {
  max-width: 215px;
  margin: 0 auto;
}
.guarantee-image a.btn-home {

  font-size: 16px;
  padding: 10px 50px;
  vertical-align: middle;
}
.guarantee-image {
  padding-top: 1em;margin: 0 auto;
  text-align: center;padding-bottom: 1em;
}
.guarantee-image img {
display: block;
  margin: 0 auto; width: auto;
}
.not-found-page .cont-sec {
  padding: 5em 1em;
 
}
.subscrip { max-width: 100px; }
#topiclinks-text {
display: block !important;
}
.onboarding-form .col-md-1 {
  text-align: left;
  max-width: 15%;
  padding: 0;
}
.onboarding-form .col-md-11 {
max-width: 85%;
}
.onboarding-form .step:before {
background: transparent;
}
.onboarding-form .col-md-11 {
max-width: 100%; }
.onboarding-form .col-md-1 { display: none; }
.conform-details ul li strong {
  /* background: #cccccc7a; */
  padding: 7px;
  width: 80px;
  margin: 0 8px 0 0px;
  font-size: 13px;
 
}
.conform-details ul li {
  font-size: 13px;
}
.test .form-box .bs-stepper-content {
 
  padding: 0 20px 20px; }
form {    
  padding: 25px 0px !important;
}
.form-group button.btn.btn-primary { padding: 6px 1em; }
.pricing-group-lists {
float: none;
width: 100%;
margin: 0 auto;
}

.lpbanner iframe {
width: 100%; }
.left-video p.new {  
font-size: 20px; }
.lpbanner {

text-align: center;
}
.left-video {
text-align: center; padding-bottom: 3em;
}
.lpbanner::before {  
background-image: none; }
.lpfeatures {
padding: 1em 0 0em;
}
.bot-lp-real-data { margin-top: 0; }


.lpfeatures h2 {
margin-bottom: 10px;
}
.et_pb_pricing li {
font-size: 15px;
}
.et_pb_pricing_content {
padding: 30px; }
.et_pb_pricing li {
font-size: 16px;
}
.price-table table tr td {font-size: 15px;}
.price-table {

overflow-x: auto;
}
.step-form.test {
  position: relative;overflow: hidden;
 
}
.form-box .bs-stepper .bs-stepper-header {
  padding-top: 2em;
 
}
.step.active:before {
  background: #ccc;
}
.step:before {
  position: absolute;
  content: "";
  background: hsla(0,0%,80%,.8);
  height: 5px;
  width: 100%;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.step-form.test .form-box {    
  margin-top: 0; margin-bottom: 0;}
.step-form.test .signup__teams__sidebar {
  width: 100%; }
.search-box .cta.jsx-4163279887 .email-form {
  max-width: 100%;
}
.new-pricing-plan table tr {
display: flex;
padding: 0 10px;
}
.new-pricing-plan table tr td {

width: 50%;  
display: flex;
flex: 0 0 auto;
flex-wrap: wrap;
flex-direction: column;
padding: 0;
}
.new-pricing-plan {
overflow-y: hidden;
overflow-x: auto;
}
.new-pricing-plan table tr td p {

font-size: 15px;

padding: 0 1em;
}
.hero-content h1 {
font-size: 24px;
}
.hero-content p {

font-size: 16px;
}
.new-pricing-plan table tr td h2 { background: hsla(0,0%,100%,.3); font-size: 20px !important; }
.form-box .step {
  display: flex;
  width: 50%;
  float: left;
   flex-direction: column;
  /* flex-wrap: initial; */
  /* flex: 1 1 33%; */
  /* margin: 0 auto; */
  /* text-align: center; */
  /* display: inline-block; */
  padding: 10px 0;
  margin-right: 0px;
}
.bs-stepper
.step .step-trigger {    
  padding: 10px 10px 9px!important;
  background: rgba(84,171,26,.5019607843137255) !important;
  border-radius: 0 !important; margin: 0 !important;
  box-shadow: none;

 
}
label p {
 
  font-size: 16px; }
.form-box .bs-stepper-header {
  /* margin: 0 -10px; */
  /* text-align: center; */
  /* display: initial; */
  /* float: none; */
  /* width: 100%; */
  /* flex-direction: row; */
  display: block;
  float: left;
  width: 100%;
  position: relative;
  z-index: 99999;
}
.col-md-6 {
flex: 0 0 auto;
width: 100% !important;
}
.bs-stepper-content {
padding: 0 10px;
}
.bs-stepper-label, button.step-trigger .bs-stepper-circle{
font-size: 14px;color: #fff;
}
.signup__teams__sidebar {
 
padding: 20px 25px 0 !important; }
.bs-stepper-header {
margin: 0 auto !important;
text-align: center;padding: 0 10px;
}
.sidebar__content p.text-sm { padding-bottom: 15% !important; }
span.jsx-1631410664, div.jsx-1631410664 {
  font-size: 24px; }
span.jsx-3289410788, div.jsx-3289410788 {
  font-size: 19px; }
.step-trigger::before { content: none; }
.form-group .table tr td {
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.form-box .makeStyles-content-2 {
  display: flex;
  align-content: center;
  flex-direction: column;
  position: relative;
  z-index: 999999;
  background: #fff;
  overflow: unset;
  top: 0;
  margin: 0 auto;
  text-align: center;
 
}
.form-box .MuiCardContent-root .card-body { padding: 0; }
.col-md-6 label p { font-size: 16px; }
.new-pricing-plan table tr td:last-child h2 {
 
background: hsl(18deg 97% 85%);
color: #fff;
}
.signup__teams__sidebar__product-box-title-text {
 
  font-size: 16px;
}
}

@media only screen and (max-width: 600px) {
.logo {
text-align: center;
margin-bottom: 15px;
}
.float-right {
float: none !important;
margin: 0 auto !important;
text-align: center;
}
.lpbanner .caption {
display: none;
}
.lpbanner,
.lpbanner .col-lg-4 {
height: auto !important;
}
.mobile-title {
display: none !important;
}
.mobile-title .col-lg-12 {
background: #0c2a7590;
padding: 10px 0px;
}
.mobile-title h1 {
text-align: center;
color: #fff;
font-size: 32px;
}
.lp-bot-section h1 {
font-size: 50px;
}
.lp-bot-section h3 {
font-size: 40px;
}
.lp-bot-section h2 {
font-size: 28px;
}

.mt-10 {
margin-top: 0px;
}
.left-video iframe {
width: 100%;
height: 200px;
}
.ancho-btns-lp {
text-align: center;
}
.ancho-btns-lp ul {
padding: 0;
}

.lpbanner h1,
.demo {
font-size: 26px; text-align: center;
}
p.hours strong,
.lead {
font-size: 22px;
}
.right-sec img.img-fluid.bot-img {
max-width: 40px;
}
p.hours {
font-size: 20px;
}
.new {
font-size: 16px;
}
.demo {
font-size: 26px;
padding-top: 1em;
text-align: center;
}
.ancho-btns-lp.fixed ul li:last-child {
border-right: 0;
}
.ancho-btns-lp.fixed ul li {
padding: 14px 19px;
}
.ancho-btns-lp.fixed ul li a {
font-size: 12px;
}
.lpdesign h2 {
font-size: 24px;
}
.lpdesign {
padding: 25px 0px 15px;
}
.lpwelcome p {
font-size: 15px;
font-weight: 500;
}
.lpwelcome h3 {
font-size: 16px;
}


.bot-lp-real-box .nav-tabs {
width: 100%;
}
table th h6.card-price {
font-size: 30px;
}


table tr td {
padding: 10px 5px;
font-size: 15px;
}
.bot-lp-real-box .nav-tabs {
width: 100%;
}
.lpfeatures h2,
.lpfeatures h2 span {
text-align: center;
font-size: 20px !important;
}

.lp-bot-section {
padding: 0px 0px 20px 0px;
}
table th h5.card-title {
margin: 0;
font-size: 13px;
}
.step-btn {
  width: 100% !important;
}

}


@media(max-width: 400px){
.lpbanner iframe, .feature-video iframe {
width: 100%;
height: 270px;
}
}


* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
header.jsx-3979001018 {
position: relative;
position: -webkit-sticky;
position: sticky;
top: 0;
background-color: #ff4040;
width: 100%;
height: auto;
height: var(--header-height,auto);
padding: 0 7%;
padding: 0 var(--header-navigation-compact-px,7%);
padding-top: 30px;
margin: 0 auto;
z-index: 100;
}


.container.jsx-3979001018 {
max-width: 1290px;
margin: 0 auto;
position: relative;
}
.container.jsx-2180139827 {
display: flex;
align-items: center;
}

.logo-link.jsx-96647813 {
display: block;
}
a {
color: #3047ec;
-webkit-text-decoration: none;
text-decoration: none;
}
.horizontal_spacer.jsx-2180139827 {
flex-grow: 1;
}
.cta-wrapper.jsx-2180139827:not(:last-child) {
margin-right: 5px;
}
@media (max-width: 1439px){

.cta.jsx-4163279887 .email-form {
max-width: 335px;
}
}

.email-form.jsx-403594908 {
display: flex;
width: 100%;
position: relative;
flex-direction: row;
align-items: center;
}
span.jsx-75452321 {
-webkit-clip: rect(1px,1px,1px,1px);
clip: rect(1px,1px,1px,1px);
-webkit-clip-path: inset(50%);
clip-path: inset(50%);
height: 1px;
width: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
}
.email-form__input-wrapper.jsx-403594908 {
flex: 999 0;
width: 100%;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
min-width: auto !important;
}
}
.email-form__input.jsx-403594908 {
width: 100%;
outline: 0;
border: 2px solid #ff4040;
color: #181818;
border-radius: 6px 0px 0 6px;
font-size: 15px;
line-height: unset;
}
.email-form__input.jsx-403594908 {
line-height: 1.4;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
input {
font: inherit;
margin: 0;
padding: 0;
}

.email-form--small.jsx-403594908 .email-form__input.jsx-403594908, .email-form--small.jsx-403594908 .email-form__submit.jsx-403594908 {
padding: 6px 14px;
min-width: 186px;
}

@media (max-width: 1439px) {

.cta.jsx-4163279887 .email-form__input, .cta.jsx-4163279887 .email-form__submit {
min-width: auto !important;
}
}
.email-form__submit--black-fill.jsx-403594908 {
background-color: #ff4040;
border: 2px solid #ff4040;
color: #ffffff;
}
.email-form__submit.jsx-403594908 {
display: inline-block;
border-radius: 0px 6px 6px 0px;
padding: 12px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
transition: all 200ms;
cursor: pointer;
flex-shrink: 0;
font-weight: bold;
position: relative;
}
.email-form__input.jsx-403594908, .email-form__submit.jsx-403594908 {
padding: 12px 21px;
}
button {
border: none;
margin: 0;
padding: 0;
text-align: inherit;
background: transparent;
color: inherit;
font: inherit;
line-height: normal;
-webkit-font-smoothing: inherit;
-moz-osx-font-smoothing: inherit;
-webkit-appearance: none;
}

span.jsx-148622701, div.jsx-148622701 {
font-size: 15px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
line-height: 1.4;
}

@media (min-width: 1024px) {
span.jsx-3289410788, div.jsx-3289410788 {
font-size: 26px;
}
.link.jsx-277724099 {
margin-right: -7px;
}
}
.link.jsx-277724099 {
display: inline-block;
padding: 7px 12px;
position: relative;
width: auto;
color: rgb(24, 24, 24);
transition: all 250ms ease 0s;
}

main {
overflow: hidden;
outline: 0;
}
@media (min-width: 768px) {
.beagi h2 { -webkit-transform: translate(0%, 5%); transform: translate(0%, 5%); }

.cta-wrapper.jsx-360272744 {
justify-self: unset;
}
span.jsx-3289410788, div.jsx-3289410788 {
line-height: 1.4;
-webkit-letter-spacing: unset;
-moz-letter-spacing: unset;
-ms-letter-spacing: unset;
letter-spacing: unset;
font-size: 22px;
}
.subheading.jsx-2340029290 {
margin: var(--margin);
margin-bottom: 36px;
max-width: 800px;
}
.headline.jsx-2340029290 {
margin-bottom: 24px;
}
.content.jsx-3751704983 {
grid-column: left-content;
}
.two-columns.jsx-3751704983 .content.jsx-3751704983 {
grid-column: left-content / span 3;
}
.two-columns.jsx-3751704983 .hero.jsx-3751704983 {
grid-template-columns: 1fr [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr 1fr;
}
section.jsx-618185480 {
margin-top: 0px;
margin-bottom: 0px;
}
.email-form__input.jsx-403594908 {
min-width: 210px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border-right: 0;
line-height: 1.4;
}

.primary.jsx-360272744 {
margin: 0 0px 22px 0;
}
.cta.jsx-360272744 {
display: inline-block;
}
.email-form__submit.jsx-403594908 {
border-top-left-radius: 0;
border-bottom-left-radius: 0;
}
.hero-wrapper.two-columns.jsx-3751704983 {
grid-template-columns: [content] 14fr;
}
.hero-wrapper.jsx-3751704983 {
  padding: 50px 0;
}
}

.hero-wrapper.jsx-3751704983 {
display: grid;
grid-template-columns: [left-gutter] 1fr [content] 12fr [right-gutter] 1fr;
justify-content: center;
padding: 50px 0;
}
.hero-wrapper--white {
background-color: #ffffff;
}


.hero.jsx-3751704983 {
grid-column: content;
position: relative;
display: grid;
grid-template-columns: [left-content] 7fr [right-content-sm] 1fr [right-content-lg] 5fr;
grid-column-gap: 24px;
align-content: center;
justify-items: center;
}

.content.jsx-3751704983 {
grid-column: left-content / span 3;
order: undefined;
}
.content.jsx-3751704983 {
display: grid;
text-align: center;
justify-items: center;
align-content: center;
z-index: 1;
position: relative;
}


.headline.jsx-2340029290 {
max-width: 1024px;
margin-bottom: 16px;
margin-top: 0;
}
@media (min-width: 990px) {

span.jsx-1631410664, div.jsx-1631410664 {
font-size: 60px;
font-weight: 600;
}
}

span.jsx-1631410664, div.jsx-1631410664 {
font-size: 60px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
  color: #454545;
  text-transform: capitalize;
}



.subheading.jsx-2340029290 {
margin-bottom: 21px;
}


span.jsx-3289410788, div.jsx-3289410788 {
font-size: 26px;
font-family: 'Open Sans', sans-serif;
line-height: 1.4;
}


.cta-wrapper.jsx-360272744 {
justify-self: stretch;
}


.cta.jsx-360272744 {
margin: 0 0 22px 0;
}

.plan-column-group {
margin: 0 auto;
width: 100%;
max-width: 1290px;
padding-top: 24px;
}
.plan-column-group__header {
margin-bottom: 20px;
text-align: center;
font-family: Graphik,Helvetica,Arial,sans-serif;
}
.rich-text p:last-of-type {
margin-bottom: 0;
}

.rich-text p {
margin-bottom: 1em;
line-height: 1.7;
}
p {
margin: 0;
}
.plan-column-group__children {
display: flex;
justify-content: center;
}
@media (min-width: 768px){
.plan-column-group__header {
height: 60px;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 0;
}
.plan-column-group-wrapper--tan {
background-color: #fff;
}

main {
padding-top: 70px;
}
}
.plan-column-group-wrapper {
padding: 0 24px 20px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper:first-child {
align-items: flex-end;
}
}

.plan-column-group__children>* {
margin: 0 10px;
}
@media (min-width: 1024px) {

.plan-columns-wrapper {
margin-bottom: 0px;
}
}
.plan-columns-wrapper {
margin-bottom: 20px;
display: flex;
flex-direction: column;
border-radius: 8px;
border: 0px solid #f4f0eb;
flex: 1 1;
}
@media (min-width: 1024px) {

.plan-columns {
min-width: 400px;
}
}

.plan-columns {
display: flex;
justify-content: center;
flex-grow: 1;
}

@media (min-width: 768px) {

.plan-columns>*:last-child {
border-bottom-right-radius: 8px;
}
}

@media (min-width: 768px) {

.plan-columns>*:first-child {
border-bottom-left-radius: 8px;
}
}
.pricing-tier-card.pricing-tier-card--rounded {
border-radius: 6px;
border: 1px solid #ccc;
}
@media (min-width: 665px) {

.plan-columns>* {
border-top: 2px solid #f4f0eb;
}
}
.plan-columns>* {
flex-grow: 1;
}
.pricing-tier-card {
background-color: #ffffff;
display: flex;
flex-direction: column;
max-width: 395px;
width: 100%;
}

@media (min-width: 768px) {

.pricing-tier-card__section:first-child {
border-bottom: 0px solid #f4f0eb;
}
}

.pricing-tier-card__section {
padding: 22px 28px 10px 28px;
}

.pricing-tier-card__section:first-child>* {
padding-bottom: 0px;
}
@media (min-width: 665px) {

.pricing-tier-card__badge-wrapper--hide-on-mobile {
display: block;
}
}
.pricing-tier-card__badge-wrapper--hide-on-mobile {
display: none;
}
.pricing-tier-card__badge-wrapper {
height: 45px;
margin-top: -5px;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

.pricing-tier-card__category {
padding-bottom: 0 !important;
}

@media (min-width: 1024px) {

span.jsx-3980862901, div.jsx-3980862901 {
font-size: 36px;
}
}
@media (min-width: 990px) {

span.jsx-3980862901, div.jsx-3980862901 {
font-size: 30px;
line-height: 1.2;
}
}
span.jsx-3980862901, div.jsx-3980862901 {
font-size: 26px;
font-family: 'Open Sans', sans-serif;
line-height: 1.4;
}

.pricing-tier-card__title.graphik-font span {
font-family: Graphik,Helvetica,Arial,sans-serif;
color:black;
}
@media (min-width: 768px) {

span.jsx-4108527491, div.jsx-4108527491 {
font-size: 36px;
line-height: 1.2;
}
}
span.jsx-4108527491, div.jsx-4108527491 {
font-size: 36px;
font-family: 'Open Sans', sans-serif;
font-weight: 500;
line-height: 1.2;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.pricing-tier-card__description {
height: 75px;
max-width: 520px;
}
span.jsx-166584131, div.jsx-166584131 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}
.cta-link--black-outline {
  border: 2px solid #57b555;
  background-color: #57b555;
  color: white !important;
}
.cta-link--wide {
width: 100%;
}
.cta-link {
display: inline-block; width: auto;
border-radius: 30px;
padding: 9px 21px;
-webkit-text-decoration: none;
text-decoration: none;
text-align: center;
transition: all 200ms;
cursor: pointer;
background: #cc7929 !important;
  border: 0;
}
span.jsx-4266488777, div.jsx-4266488777 {
font-size: 16px;
font-family: 'Open Sans', sans-serif;
font-weight: bold;
-webkit-letter-spacing: 0.02em;
-moz-letter-spacing: 0.02em;
-ms-letter-spacing: 0.02em;
letter-spacing: 0.02em;
line-height: 1.4;
}
.pricing-tier-card__section:first-child>* {
padding-bottom: 20px;
}

@media (min-width: 768px) {

.pricing-tier-card__price-for-period {
font-size: 20px;
}
}

@media (min-width: 1270px) {

.pricing-tier-card__show-on-desktop {
display: block;
}
}

.pricing-tier-card__show-on-desktop {
display: none;
}
.pricing-tier-card__price-for-period--dollar-amount {
font-family: Graphik,Helvetica,Arial,sans-serif;
font-weight: bold;
line-height: 1.4;
}
.pricing-tier-card__price-for-period-subtext {
height: 17px;
}
.copy-right {
position: relative; font-weight: 700;
width: 100%;

padding: 15px 0px;
text-align: center;
color: #fff;
font-family: 'Open Sans', sans-serif;
border-top: 0px solid #ccc; font-size: 16px;
}
.copy-right p { margin: 0; color: #fff; }
.copy-right ul li a {
color: #fff;
}

.copy-right ul {
margin: 0;
list-style: none;
color: #fff;
padding: 0;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}
.signup__teams__sidebar__product-box-title-text {
font-weight: 700; font-size: 18px;
}
.signup__teams__container * {
box-sizing: border-box;
}
.flex-row.gap-4>:last-child {
margin-right: 0;
}
*, ::after, ::before {
border-width: 0;
border-style: solid;
border-color: rgba(0,0,0,.1);
}
.flex-col {
flex-direction: column;
}
.flex {
display: flex;
}
.application__component {
color: #222;
background-color: #f1f1f1;
height: 100%;
}

.signup__teams__body {
font-family: 'Open Sans', sans-serif;
font-size: 15px;
font-weight: 400;
letter-spacing: .01em;
}
.justify-between {
justify-content: space-between;
}
.signup__teams__sidebar {
background-color: transparent;
width: 380px;
padding: 80px 25px 0;
justify-content: space-between;
overflow: hidden;
}
@media (min-width: 1025px){}
.signup__teams__sidebar__container {
order: 3;
flex: 0 0 auto;
}
@media (min-width: 768px){}
.col-md-6 {
flex: 0 0 auto;
width: 50%;
}

.email-not-verified {
text-align: center;
font-size: 16px; padding: 1em 10px;
margin: 0px; color: #454545; position: relative; z-index: 99;

}
.MuiPaper-root.MuiCard-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
margin: 0vh auto !important;
}
.jsx-1928597095.pricing-tier-card__price-for-period {
text-align: left !important;
font-size: 23px !important;
}

.StripeElement {
margin: 15px auto;
padding: 10px 12px;
color: #32325d;
background-color: white;
border: 1px solid #ccc;
border-radius: 4px;
}

.StripeElement--webkit-autofill {
background-color: #fefde5 !important;
}

.product {
width: 100%;
max-width: 450px;
margin: auto;
box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
border-radius: 10px;
overflow: hidden;
}

.btn-pay {
display: block;
width: 100%;
border: none;
background: linear-gradient(
135deg,
rgb(49, 0, 62) 0%,
rgb(195, 40, 110) 100%
);
color: #fff;
padding: 10px;
font-size: 18px;
cursor: pointer;
}

.product-info {
padding: 0 16px;
}

h3.product-title {
font-size: 28px;
margin-bottom: 15px;
}

h4.product-price {
font-size: 24px;
margin: 0;
margin-bottom: 30px;
color: #777;
font-weight: 500;
}
.step-btn {
width: 100%;
}
.next {
margin-top: 30px;
position: relative;
  z-index: 9;
}
label {
width: 100%;
}
.bs-stepper .step-trigger {
display: inline-flex !important;
flex-wrap: wrap !important;
align-items: center !important;
justify-content: center !important;
padding: 10px !important;
font-size: 12px !important;
font-weight: 700 !important;
line-height: 1.5 !important;
color: #6c757d !important;
text-align: center !important;
text-decoration: none !important;
white-space: nowrap !important;
vertical-align: middle !important;
-webkit-user-select: none !important;
-ms-user-select: none !important;
    user-select: none !important;
background-color: transparent !important;
border: none !important;
border-radius: .25rem !important;
transition: background-color .15s ease-out,color .15s ease-out !important;
padding-bottom: 31px !important;
}
.form-control1 {
display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.signup__teams__sidebar__product-box-text {
color: #454545;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
}

.signup__teams__sidebar__product-box {
  border-top: 10px solid transparent;
  background-color: #fff;
  padding: 24px 24px 30px; border-radius: 3px;
  background: #54ab1a !important
}
.signup__teams__container * {
box-sizing: border-box;
}
.sidebar__content .sidebar__heading h2 {text-transform: capitalize;
  font-weight: 600; color: #454545; font-size: 22px;
border-bottom: 0;
  padding: 1em 0 0;
}
p.text.o__muted {
padding: 5px 0;
font-size: 17px; color: #fff;
font-weight: 600;text-transform: capitalize;
}
.step-form .col-md-4 > div {
  float: right;
}
.flex.flex-row.justify-between.gap-4 {
  color: #454545;
  font-weight: 600; color: #fff;
}
.sidebar__content p.text-sm {
color: #454545; font-size: 18px;
}

.gap-4 span {

font-weight: 700;color: #fff
}

.signup__teams__container * {
box-sizing: border-box;
}

.signup__teams__option-divider {
text-align: center;
position: relative;
z-index: 1;
font-size: 16px;
}
.signup__teams__option-divider:before {
background-color: transparent;
border-top: 1px solid #fff;
display: block;
content: '';
position: absolute;
width: 100%;
height: 2px;
left: 0;
top: 50%;
z-index: 0;
}
.h-2 {
height: 8px;
}
.h-5 {
height: 20px;
}

body {
scroll-behavior: smooth;
}
header#site-header {
padding-top: 20px;
}
main#main {
padding-top: 0;
}
.pricing-tier-card__features ul {
padding-left: 20px;
}
.jsx-1928597095 .pricing-tier-card__price-for-period--dollar-amount {
font-size: 50px;
color: #241c15;

}
.pricing-tier-card__features ul li i.fa.fa-check {
color: #ff4040;
float: left;
vertical-align: middle;
padding: 3px 7px 0 0;
}
.pricing-tier-card__features ul {
padding-left: 0px;
}
.rich-text {
font-size: 17px;
}
.pricing-tier-card__features ul li div p {
color: #454545;
font-size: 16px;
font-family: 'Open Sans', sans-serif;
}
.tt{
padding:10px;
}
.jsx-1928597095.pricing-tier-card__title.graphik-font {
padding-bottom: 0;
}
header#site-header{
border-bottom: 1px solid #ffffff40;
}
.jsx-1928597095.pricing-tier-card__title.graphik-font span {
font-family: 'Open Sans', sans-serif;
}
.jsx-1928597095 > p {
color: #454545;
border-bottom: 1px solid #454545;
display: inline-block;
}
.jsx-1928597095.pricing-tier-card_section.pricing-tier-cardsection--hidden.pricing-tier-card_section--space-between {
padding-top: 0px !important;
}

.month {
line-height: .8;
font-size: 16px;
color: #454545;
font-style: italic;
}
.pricing-tier-card__description {
height: 54px;
max-width: 520px;
}
.jsx-1928597095.pricing-tier-card_section.pricing-tier-cardsection--hidden.pricing-tier-card_section--space-between {
padding-top: 0 !important;
}


/* For Imag Upload */

.file-upload {
  background-color: #fff;
  margin: 0;
}

.file-upload-btn {
width: 100%;
margin: 0;
color: #fff;
background: #ff4040;
border: none;
padding: 10px;
border-radius: 4px;
border-bottom: 4px solid #15824B;
transition: all .2s ease;
outline: none;
text-transform: uppercase;
font-weight: 700;
}

.file-upload-btn:hover {
background: #1AA059;
color: #ffffff;
transition: all .2s ease;
cursor: pointer;
}

.file-upload-btn:active {
border: 0;
transition: all .2s ease;
}

#file-upload-content {
display: none;
text-align: left;
}
.step.active .bs-stepper-label, .step.active button.step-trigger .bs-stepper-circle {
  color: #fff;
}
.file-upload-input {
position: absolute;
margin: 0;
padding: 0;
width: 100%;
height: 100%;
outline: none;
opacity: 0;
cursor: pointer;
}

#image-upload-wrap, #image-upload-wrap-1 {
margin-top: 20px;
border: 1px solid #ccc;
position: relative; z-index: 99;
}
#test-l-3 .makeStyles-root-1 {
  position: relative;
  overflow: unset;
  margin: 0 auto !important;
  background: none; box-shadow: none;
}
.image-dropping,
#image-upload-wrap:hover, #image-upload-wrap-1:hover {
background-color: transparent;
border: 1px solid #ccc;
}

.image-title-wrap {
padding: 0 15px 15px 15px;
color: #222;
}

.drag-text, .drag-text-doc, .drag-text-team{
text-align: center;
}
.drag-text h3 {
text-transform: capitalize;
color: #454545;
font-size: 20px;
padding: 50px 0;
font-weight: 500;
}
.drag-text-doc  h3, .drag-text-team h3 {
text-transform: capitalize;
color: #454545;
font-size: 20px;
padding: 35px 0;
font-weight: 500;
}


#file-upload-image, #file-upload-image-team {
max-height: 200px;
max-width: 100px;
margin: auto;
padding: 7px 5px;
}

form {
margin: 0;
padding: 25px;
}
fieldset {
margin: 0;
padding: 15px;
background-color: #fff;
z-index: 0;
}
legend {
font-size: 2em;
padding: 0 10px;
}
input[type="checkbox"], input[type="radio"] {
display: none;
z-index: -1;
position: absolute;
}
label {
display: block;
margin: 10px 0;
padding: 2px;
cursor: pointer;
}
label p {
display: inline-block;
font-size: 16px;
margin: 0;
padding: 0;color: #454545;
}
input[type=checkbox]+label .customCheckbox {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 10px -5px 0;
  padding: 0;
  border: 2px solid #57ab1a;
  border-radius: 0px;
  box-shadow: 1px -2px 6px #ccc;
  z-index: 9999;
}


input[type="checkbox"] + label .customCheckbox:after {
content: "";
position: absolute;
border-radius: 10px;
top: 50%;
left: 50%;
width: 0;
height: 0;
background-color: #454545;
transition: all 0.25s;
}

input[type=checkbox]:checked+label .customCheckbox:after {
  top: -2px;
  left: 3px;
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 0px;
  content: '✔';color: #454545;
}


input[type=radio]+label .customradio {
display: inline-block;
box-sizing: border-box;
position: relative;
width: 19px;
height: 20px;
margin: 0 10px -4px 0;
padding: 0;
border: 1px solid #fff;
border-radius: 50%;
box-shadow: 1px -2px 6px #ccc;
z-index: 9999;
}


input[type="radio"] + label .customradio:after {
content: "";
position: absolute;
border-radius: 10px;
top: 50%;
left: 50%;
width: 0;
height: 0;
background-color: #454545;
transition: all 0.25s;
}

input[type=radio]:checked+label .customradio:after {
top: 1px;
left: 1px;
width: 15px;
height: 15px;
content: '';
border-radius: 50%;
background: #54ab1a;
}




input[type=checkbox]+label .customCheckbox1 {
display: inline-block;
box-sizing: border-box;
position: relative;
width: 25px;
height: 24px;
margin: 0 10px -5px 0;
padding: 0;
border: 1px solid #fff;
border-radius: 0px;
box-shadow: 1px -2px 6px #ccc;
background: #00C853;
z-index: 9999;
}


input[type="checkbox"] + label .customCheckbox1:after {
content: "";
position: absolute;
border-radius: 10px;
top: 50%;
left: 50%;
width: 0;
height: 0;
background-color: #454545;
transition: all 0.25s;
}

input[type=checkbox]:checked+label .customCheckbox1:after {
top: -1px;
left: 5px;
width: 15px;
height: 15px;
background: transparent;
border-radius: 0px;
content: '✔';color: #fff;
}

.container-fluid {
margin-top: 80px;
margin-bottom: 80px
}

.card {
background-color: #424242;
padding: 40px 10px
}

.text-grey {
color: #9E9E9E
}
.tz p {
font-weight: bolder;
}

.fa {
font-size: 25px;
cursor: pointer
}



input:focus,
select:focus {

box-shadow: inherit !important;
border-bottom: 1px solid transparent;
outline-width: 0
}

select option {
  background-color: #54ab1a;
  color: #fff;
  font-family: 'Open Sans';
  font-size: 16px;
  border: 0;
  font-weight: 600;
}
.form-group .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #454545;
  font-weight: 500; position: relative; z-index: 99;
}
.form-group .table tr td {
  border: 0;
}
select option:focus {
background-color: #00C853 !important
}

::-webkit-input-placeholder {
color: #fff;
opacity: 1
}

:-ms-input-placeholder {
color: #fff;
opacity: 1
}

::placeholder {
color: #fff;
opacity: 1
}

:-ms-input-placeholder {
color: #fff
}

::-ms-input-placeholder {
color: #fff
}

button:focus {
box-shadow: none !important;
outline-width: 0
}

.btn {
border-radius: 50px;
width: 120px
}

.exit {
border: 1px solid #9E9E9E;
color: #9E9E9E;
background-color: transparent
}

.exit:hover {
border: 1px solid #9E9E9E;
color: #454545 !important;
background-color: #9E9E9E
}

@media screen and (max-width: 768px) {
.mob {
    width: 70%
}

select.mob {
    width: 50%
}
}

.business_hours td {
padding: 0 20px;
}
.business_hours td select {
padding: 10px;
}
.form-select {
display: block;
width: 100%;
padding: .375rem 2.25rem .375rem .75rem;
-moz-padding-start: calc(0.75rem - 3px);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-repeat: no-repeat;
background-position: right .75rem center;
background-size: 16px 12px;
border: 1px solid #ced4da;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
-webkit-appearance: none;
appearance: none;
}
.w-100 {
width: 100%;
}

#add {
margin: 0;
}
.form-box .bs-stepper-content {
/* background-image: url(temp.png); */
padding: 0 20px 20px;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
position: relative;
z-index: 99999999;
margin: 3em auto 0;
display: block;border-radius: 5px;
}
.form-group i {
position: absolute;
left: 15px;
top: auto;
bottom: 13px;
color: #8c8989f7;
z-index: 9999;
font-size: 20px; display: none;
}
.form-group i.fa.fa-user {
left: 26px;
}
.form-box .form-group { position: relative; }
.form-group i.fa.fa-globe { left: 24px; bottom: 11px;  }
.form-group i.fas, .form-group i.fab {
left: 26px;
font-size: 18px;
bottom: 13px;
}
.form-box .form-group i.fa.fa-institution, .form-box .form-group i.fa.fa-address-card {
left: 25px;
}

.loader1 {
border: 5px solid #f3f3f3;
border-top: 5px solid #54ab1a;
border-radius: 50%;
width: 25px;
height: 25px;
margin: auto;
-webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite
}

@keyframes spin {
0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.react-datetime-picker__clock.react-datetime-picker__clock--open {
display: none !important;
}

.get-in-touch input#example-datepicker{
border: 0px !important;
}
.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
background: #fff !important;
border-radius: 8px !important;
}
.react-datetime-picker__wrapper {
border: none !important;
}
.pref-time{
margin-top: 15px !important;
  text-align: left !important;
}

.react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled {
background: #fff !important;
}
#error {
display: none;
color: red;
padding: 10px;
background: #fff;
width: 50%;
margin: 0 auto;
margin-bottom: 20px;
border-radius: 20px;
}

.payment_secure {
text-align: center;
margin: 20px;
}

.step-form .MuiCard-root {
margin: 0 auto !important;
overflow: unset;
background: transparent;
box-shadow: unset !important;
transition: none !important;
border: 0;
}
.step-form .MuiCardContent-root.jss2 {
box-shadow: unset;
padding:10px;
}
.payment_info{
font-size: 10px;
text-align: center;
margin-bottom: 0;

}
.conform-detail {
margin: 20px -15px;
}
select.form-select.btn.btn-primary:hover, select option:hover {
  background: #54ab1a !important;
  color: #fff !important;
}
input:focus, select:focus {outline:none; color: #54ab1a !important;border: 0 !important;}

.step-form .btn-primary:focus{
outline:none;  border: 0 !important;

}
.updated {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: green;
  font-weight: bold;
  font-size: 14px;
}
div#pages p {
  font-size: 14px;
  font-weight: 600;
}

.react-tel-input .country-list .country {
display: block;
}
.react-tel-input .form-control {
width: 100% !important;
height: 47px !important;
color: black;
border-radius: 30px !important;

}
.react-tel-input {
border-radius: 30px;
box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
margin-bottom: 20px !important;
}

.flag-dropdown, .flag-dropdown:hover{
border-top-left-radius: 24px  !important;
border-bottom-left-radius: 24px  !important;
}
.selected-flag.open, .selected-flag.open:hover {
border-top-left-radius: 24px !important;
border-bottom-left-radius: 24px !important;
z-index: 9999;
}
#update-message {
text-align: center;
  font-size: 20px;
  margin: 20px;
  color: #54ab1a;
}
#image-upload-wrap-1, #image-upload-wrap-2 {
margin-top: 20px;
padding: 0px 0;
border: 1px solid #ccc;
position: relative; z-index: 99; display: block;
}
#image-upload-wrap {

}
.step-form .form-group label {
font-size: 16px;
color: #54ab1a;
font-weight: bolder;
}




.addreplace-2 #drag-text-new-2 {
font-size: 30px;
background: #54ab1a;
display: inline-block;
padding: 1px 5px;
border-radius: 50%;
color: #fff; width: 27px;
line-height: 0.8; font-weight: 700; display: none;
}





#file-upload-content-2, #file-upload-content-1 {
position: relative;
width: 50%;
}

#file-upload-image, #file-upload-image-team {
opacity: 1;
display: block;
width: 100%;
height: auto;
transition: .5s ease;
-webkit-backface-visibility: hidden;
        backface-visibility: hidden;
}

#middle, #middle-1 {
transition: .5s ease;
opacity: 0;
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
}

#file-upload-content-2:hover #file-upload-image, #file-upload-content-1:hover #file-upload-image-team{
opacity: 0.3;
}

#file-upload-content-2:hover #middle, #file-upload-content-1:hover #middle-1 {
opacity: 1;
}

.text {
background-color: #04AA6D;
color: white;
font-size: 16px;
padding: 16px 32px;
}

label.btn.btn-primary.step-btn.company {
display: block;
width: auto;
margin: 0 auto;
padding: 6px 2em;
border-radius: 20px;
background: #fff !important;
border: 0;
font-size: 16px;
font-weight: 700;
position: relative;
z-index: 999;
color: #54ab1a;
transition: all 0.5s ease-in-out 0s;
border: 2px solid;
overflow: hidden;
}
label.btn.btn-primary.step-btn.company:hover {
color: #54ab1a !important;
}

.mr_0 {
margin: 0 !important;
}
.pd_0 {
padding: 0 !important;
}

#business_tbody td {
min-width: 111px;
}
.outer-table {
display: flex; /* displays flex-items (children) inline */
overflow-x: auto;
}

#topiclinks-text {
display: none;
}

#error-message-user-exists{
margin: 4px;
color: #f00;
display: none;
}

.col-md-2 .form-control-price:focus {
box-shadow: none !important;
color: grey;
}
.col-md-2 .form-control-price, .col-md-2 .form-control-price:active  {
box-shadow: none !important;
color: grey;
}
.date-pic input.react-date-picker__inputGroup__input {
  border: 0px !important;
}
.date-pic input, .date-pic input#example-datepicker {
  border: 1px solid #c1bbbb!important;
  height: auto;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
}
.date-pic .react-date-picker__wrapper {
  border: 1px solid #e2dddd!important;
  box-shadow: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em, rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
  border-radius: 3px;
}
.date-pic {
  color: #54ab1a; }
input#plan {
  background: transparent;color: #454545;font-weight: 700;
}
.date-pic .react-date-picker {
 
  z-index: 99999;
}
.date-pic .react-date-picker__button svg {    
  stroke: #57ab1a;
}
.affiliate-link a {
  background: #57ab1a;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px; font-size: 20px;
}
.affiliate-link {
  text-align: center;
  padding: 4em 1em;
}
input#plan:focus {
  color: #454545 !important;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #ccc;
opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #ccc;
opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #ccc;
opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
color: #ccc;
}
.cont-sec {
  padding: 2em 0;
}
.blog h4 {
  padding: 0;
  font-size: 20px;
  color: #54ab1a;
  margin-bottom: 15px; min-height: 52px;
}
.blog .cont-sec h6, .blog .cont-sec p { color: #454545;}
.blog h2 {
  padding: 0; font-weight: 500;
  font-size: 25px;
  color: #54ab1a;
  margin-bottom: 15px;
}
.blog .cont-sec p {
  margin-bottom: 1em;
  font-size: 16px;
  line-height: 24px !important;
}
.blog .cont-sec h6 a, .blog .cont-sec p a {
  color: #156bf1fa;
}


.blinking{
-webkit-animation: blinking 0.4s infinite;
        animation: blinking 0.4s infinite;
}
@-webkit-keyframes blinkingText{
0% { background-color: #ffa844;}  
50% { background-color: #54ab1a;}  
100% { background-color:  #ffa844;}
}
@keyframes blinkingText{
0% { background-color: #ffa844;}  
50% { background-color: #54ab1a;}  
100% { background-color:  #ffa844;}
}
@-webkit-keyframes blinking {
0% {opacity: 1}
49%{opacity: 1}
50% {opacity: 1; background: #f90;}
}
@keyframes blinking {
0% {opacity: 1}
49%{opacity: 1}
50% {opacity: 1; background: #f90;}
}

.users tr td:first-child:before
{
counter-increment: Serial;      
content: counter(Serial);
  }
  body
{
 counter-reset: Serial;          
  }
table.cost-table.users i {
    font-size: 18px !important;
    vertical-align: middle;
    color: #6b6868;
}
.title-right a.btn-home {
  padding: 10px;
  font-size: 15px;
}
.wrapper-home .title-left h1 {
font-size: 25px;
margin: 24px 0 0 0;color: #454545;
}
.report-lists table.cost-table.users th {
background:#57ab1a;
padding: 8px 10px;
color: #fff;
}
.report-lists table.users tr:nth-child(even), .report-lists table.users tr:nth-child(odd) {
background: transparent;
}
table.cost-table.users tr {
border-bottom: 1px solid #ccc;
}
table.cost-table.users tr td {
padding: 10px;
}

.react-responsive-modal-modal {
  position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    min-width: 800px;
}

.RRUfwb36E3adDkhFDNDa .choose-file h3 {
  background: #57ab1a;
  color: #fff;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 10px 30px;
}
.RRUfwb36E3adDkhFDNDa button.btn.btn-primary.step-btn,.RRUfwb36E3adDkhFDNDa #add,.RRUfwb36E3adDkhFDNDa button.btn.btn-primary,.RRUfwb36E3adDkhFDNDa .form-select.btn-primary,.RRUfwb36E3adDkhFDNDa .cta-link {
  background: #ffa844 !important;
  color: #fff;
}

.RRUfwb36E3adDkhFDNDa button.btn.btn-primary.step-btn::before,.RRUfwb36E3adDkhFDNDa .cta-link::before {
  background: #5ebeeb !important;
  color: #fff;

}

.form-bg.contact-upload.p-3 {
  padding: 3em;
}


.customeTheme {
	 color: #fff !important;
	 background-color: #57ab1a !important;
   font-size: 20px;
}
 .customeTheme.place-top:after {
	 border-top-color: #57ab1a !important;
	 border-top-style: solid !important;
	 border-top-width: 6px !important;
}
 

.ques {
  border-radius: 50% !important;
    border: 1px solid #64ab1a !important;
    padding: 1px 5px !important;
}
.email-previewer{
    height: 80vh;
}
.rdt_TableHeader .sc-fotOHu.gIaANo {
    background: #54ab1a;
    color: #fff;
}
.rdt_TableCell {
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #454545;
    letter-spacing: 0.1px;
}
#root .rdt_Pagination {
    color: #54ab1a;
    font-size: 15px;
    font-weight: 600;
}
.empty-template h3 {
   
    font-weight: 700;
    font-size: 23px;
    color: #54ab1a;
}
input.form-group.subject::-webkit-input-placeholder {
    color: #454545;
}
input.form-group.subject:-ms-input-placeholder {
    color: #454545;
}
input.form-group.subject::placeholder {
    color: #454545;
}
.react-responsive-modal-modal {
    border: 4px solid #54ab1a;
    border-radius: 2px;
}
.rdt_TableCell input[type="checkbox"], input[type="radio"] {
    display: block;
    z-index: 1;
}
.rdt_TableHead .rdt_TableHeadRow {
    background-color: #54ab1a;
    min-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}
.email-template .subject:focus {
    border: 1px solid hsl(0, 0%, 80%) !important;
    color: #fff !important;
    font-weight: 700;
}
.empty-template{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

*:focus-visible
{
    outline: 0 !important;
}
p.select-template {
    font-size: 18px;
    margin-bottom: 10px;
    color: #54ab1a;
    font-family: 'Open Sans';
    font-weight: 700;
}
div#emailTemplate {
    padding: 1.5em 0;
}
.spamscore {
    padding: 1em 0;
    color: #454545; 
    font-weight: 600;
}
.rdt_TableHead .rdt_TableHeadRow svg {
    fill: #fff;
    font-weight: 700;
}
.rdt_TableHeader div {
    font-weight: 700;
}
.rdt_TableBody .rdt_TableRow {
    background: #ececec36;
    padding: 0;
    min-height: 40px;
}
.nospam {
    color: #454545;
    font-weight: 600;
    font-size: 17px; 
}
input.form-group.subject::-webkit-input-placeholder {
    color: #fff;
}
input.form-group.subject:-ms-input-placeholder {
    color: #fff;
}
input.form-group.subject::placeholder {
    color: #fff;
}
input.form-group.subject { background-color:#54ab1a; color: #fff;
    font-weight: 700; }
.nospam span {color: #54ab1a; font-weight: 700;}
.spamscore span {
    border: 5px solid #ffa844;
    padding: 15px 11px;
    border-radius: 50%;
    color: #54ab1a;
    font-size: 19px;
    font-weight: 700;
}
.sendEmail button{
    padding: 10px;
        background: #ffa844 !important;
        color: #fff;
        border: none;
        border-radius: 8px;
        font-weight: bold;
}
.contact-sendEmail{
    position: relative;
    right: -91%;
    margin-top: 30px;
}

.css-26l3qy-menu, .css-26l3qy-menu div{
    color: #000;
    background: #fff;
}
.email-template .form-group{
    width: 100%;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;    outline: 0!important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 9px;
}

.sendEmail .sendEmail{
    margin-bottom: 20px !important;
}
.title-right {
    float: right;
    width: 37%;
    text-align: right;
}
.service-type, .service-type:focus {
    width: 100% !important;
    padding: 3px  !important;
    margin: 10px 0  !important;
    background: #FFF  !important;
    border: 1px solid #ccc  !important;
}
.report-group.report-lists.services .container {
    padding: 0 !important;
}


html {
  scroll-behavior: smooth; 
}
body
 {
    min-height: auto !important;
  overflow-x: hidden;
}
ul li {display: block;}

.nav {
    display: block;
}
   
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: #54ab1a;
    background-color: #e7e7e714;
    font-weight: 900;
    border-bottom: 3px solid #b1abab;
    margin: 0px auto;padding-bottom: 5px;
    background-color: #ffffff00;
    padding-left: 0px;
    padding-right: 0px;
}
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>span {
   color: #54ab1a;font-weight: 900;font-size: 16px; text-decoration: none; 
}
.navbar-default .navbar-nav>li>span {
    padding: 17px 15px;
    display: inline-block;
    vertical-align: middle; cursor: pointer;
}
h2,li,label{font-family: 'Raleway', sans-serif;}
p,li,button,h6,h5,h2,h3,h4{font-family: 'Oxygen', sans-serif;}
.wrapper-home h1{font-family: 'Oxygen', sans-serif; font-size: 34px; }
.set img {
    width: 100%;
}
.set1{ margin-top: 37px;}
.set1 img {
    width: 73%;  
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{}

/*** Changes */
.flt-lft-line {
    border-right: 2px solid #d1ffb6;
    height: 126px;
    margin-right: 23px;
    margin-left: -43px;
    margin-top: 27px;color:#fff;
}
.banner-bg::before {
    position: absolute;
    content: "";
    background: url(/static/media/light-green.d28cedb4.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 450px;
    top: 2em;
    right: 0;
}
.banner-bg {
    position: relative;padding: 1em 0 3em;
}
.ttttt { text-align: center; }
.address {
    display: flex;
    align-items: center;
}
.footer-logo { text-align: center; }
.funnel-group::before {
   background: url(/static/media/green-bg-right.35b17ba3.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: 0;
    bottom: -13em;
    background-size: 380px;
    left: auto;
    z-index: 22;
    pointer-events: none;
    top: auto;
}
div#feature {
    padding: 4em 0 0;
}
.funnel-group { position: relative; padding: 1em 0 3em;}
.price-list-one h1 {
    max-width: 144px;
    margin: 0 auto;
    /* border-bottom: 2px solid #ffb300; */
    display: block;
    padding-bottom: 10px;
    margin-bottom: 1em;
    font-size: 30px;
}
.price-list-one h1::after {
    content: '';
    display: block;
    position: relative;
    top: 10px;
    width: 45%;
    left: 26%;
    border-top: 3px solid #ffb300;
}
.price-bg-gray::before {
    position: absolute;
    background: url(/static/media/green-bg-left.8c045e20.png);
    content: "";
    width: 100%;
    height: 100%;
    top: -12em;
    left: 0;
    background-repeat: no-repeat;
    background-position: left top;
    bottom: auto;
    background-size: 250px;
}
.table.table.table-striped.feat tbody tr:last-child td {
    border-right: 0;
}
td i { font-size: 25px; }
a.btn-home {
    background: #57ab1a;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    font-family: inherit;
    display: inline-block;
    margin: 1em auto;
    text-align: center;
    width: auto;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
body a {color: #fff; }
body a:hover { color: #6a6a6a; }
.footer ul li a { color: #fff; }
.plan-1 {
    text-align: center;
}
.copy-right p { font-weight: 600; }
.copy-right p a:hover { color: #57ab1a; }
.video-content {
    display: flex;
    align-items: center;
    justify-content: center; margin-left: 1%;
}
.navbar-default .navbar-nav>li:last-child > a {
    color: #fff;
    padding: 15px 45px;
}
.navbar-default .navbar-nav>li:last-child {
    margin-left: 1em;
    margin-top: 5px;
}
ul.nav.navbar-nav li {
    
    margin-top: 1em;
}
.navbar-default .navbar-nav>li:last-child > a:hover {
    background: #ffb300;
    color: #fff;
}
.logo-menu {
    padding: 10px 0;
    float: left;
    width: 100%;
}
.navigation {
    width: 75%;
    float: right; padding: 0;
}
.navigation .navbar-nav {
    display: block;
}
nav.navbar-default.navbar-static-top.fixed {
    background: #fff;
    position: fixed; z-index: 9999999999;
    width: 100%;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out 0s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-bottom: 1px solid #ccc;
}
.navbar-default .navbar-nav>li>a.active {

    border-bottom: 3px solid #6a6a6a73;
    padding-bottom: 4px;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
}
.next button.btn.btn-primary.step-btn.company {
  width: 150px;
}
#video .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    padding: 5px !important;
    width: 100%  !important;
    height: 500px !important;
    overflow: hidden;
}

#video .MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

#video .MuiSvgIcon-root path {
    fill: #ffb300;
}
#video .MuiSvgIcon-root {
  width: 2em;
  height: 2em; background: #fff;
}
.home.get-in-touch h2 { color: #54ab1a; border-bottom: 0;
    padding-bottom: 11px; }
    .home.get-in-touch h2::after {
        content: '';
        display: block;
        position: relative;
        top: 10px;
        width: 15%;
        left: 0; right: 0; margin: 0 auto;
        border-top: 3px solid #ffb300;
    }
.home.get-in-touch::before { background: none; }
/*** Changes **/

/*Mobile Resposive*/
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    border-bottom: none;padding-left: 17px!important;
}

.header-bg-sec {
    height: 297px!important;
    }
.nav {
    padding-left: 0%!important;
}
.main-hd p {
    width: 74%!important;
    text-align: center;
    font-size: 19px;
    line-height: 28px;
    padding-top: 27px;
}
.main-hd h1 {
    font-size: 28px!important;
    margin-top: -61px;width: 71%!important;
    padding-left: 32px!important;
}
.center-box {
    margin-top: 0%!important;
    padding: 18px 3px 181px 3px!important;
}

button.menubtn{margin-left: 18px!important;}

}

/*Mobile Resposive*/


.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #54ab1a;
}
.navbar-default .navbar-toggle {
    border-color: #000;
}
.navbar-toggle{background-color: #54ab1a;}
.header-bg-sec {
    height: 952px;
    background-image: url(/static/media/bg-header.33704613.jpg);
    width: 100%!important;
    background-size: cover;
    background-repeat: no-repeat;margin-top: -7%;
    
}
.set{
    width:100%;
    padding-right: 0px!important;
    padding-left: 0px!important;
    
}
   .main-hd h1 {
    color: #54ab1a;
    font-weight: 900;
    font-size: 30px;
    border-left: 4px solid #ffa844;
    margin-right: 27px;
    padding-left: 5px;
    border-right: 4px solid #ffa844;
    width: 63%;
}
.main-hd p {
    width: 66%;
    text-align: center;
    font-size: 19px;
    line-height: 28px;
}
.main-hd-form {
    margin: auto;
    width: 81%;
    margin-top: 23px;
}
.main-hd {
    padding-left: 58px;
}
.main-hd-form .btn-success {
    margin-right: 20px;
    min-width: 180px;
    min-height: 65px;
    padding-top: 22px;
    padding-bottom: 22px;
}


.center-box {
    margin: auto;
    width: 54%;
    text-align: justify;
    line-height: 26px;
    color: #fff;
    padding: 35px 3px 116px 3px;
    background: linear-gradient(
81deg
, #2c9e08, #7bdf5b);
    border-radius: 20px;
    font-weight: 600;
     margin-top: -19%;
}

.box-two img {
    margin: auto;
    width: 100%;
}
.pd-lft-rght {
    width: 100%;
    padding-right: 0px!important;
    padding-left: 0px!important;
}
.box-two {
    margin-top: 44px;
}
.btn-three {
    color: #000;
    background-color: #ffffff;
    border-color: #ffffff;
    float: left;
    border-radius: 24px;
    
}
button.btn-three {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 18px;
}
.center-box h6 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    font-size: 14px;
}
.main-box-2 p {
    text-align: center;
    background-color: #54ab1a;
    padding-top: 18px;
    padding-bottom: 18px; box-shadow: 0 5px 8px -2px #a7a0a0;
    color: #fff;
    font-size: 17px;
    font-weight: 900;
}
.main-box-3 {
    text-align: center;
}
 .icon-box p {
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 8px;
    padding-top: 8px;
}
.box-sec {
    border-radius: 16px;
    background-color: #53ab19;
    padding: 43px 20px 43px 20px;
    margin: auto;
    width: 66%;
    margin-bottom: 20px;
    
}

.navbar-default {
    background-color: #f8f8f800;
    border-color: #e7e7e700;
    border-top: none; position: relative;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
button.menubtn {
    background-color: #54ab1a;
    margin-top: 4px;
    padding: 7px 45px 7px 45px;
    border: 1px solid #54ab1a;
    border-radius: 7px;
    color: #fff;
    margin-left: 105px;
}

.beagi h1, .banner-section .beagi > h1 {
    justify-content: center;
    color: #54ab1a;
    font-weight: 900;
    font-size: 49px;
    border-left: 4px solid #ffa844;
    border-right: 4px solid #ffa844;
    margin-left: 0;
    margin-right: 0;
    display: inline-block;
    margin: 0;
    line-height: 40px;
    padding: 0 10px; font-family: 'Oxygen', sans-serif;
}
.beagi h2 {
    color: #54ab1a;
    margin-left: 26px;
    margin-bottom: 0;
    font-size: 21px;
    font-family: Arial;
    font-weight: 500;
    border: 0;
    -webkit-transform: translate(-18%, 45%);
            transform: translate(-18%, 45%);
}
.beagi p {
    font-size: 15px;
    text-align: justify;
    text-align-last: justify;
    margin-left: 0%;
    margin-right: 6%;
    padding-top: 6px;
}
.new-update-home .beagi p {
    font-size: 15px;
    text-align: justify;
    text-align-last: center;
    margin-left: 0%;
    margin-right: 0; letter-spacing: 0.2px;
    padding-top: 6px;
}
ul.nine-points li {
  list-style: none;
  font-size: 15px;
  padding-top: 7px;
}
ul.nine-points li:before {
  content: '✓';
  color: #54ab1a;
  font-size: 12px;
  font-weight: 900;
  margin-right: 11px;vertical-align: 3px;
  background-color: #57aa1c;
    color: #fff;
    background: linear-gradient(#00c900, #57aa1c);
    border-radius: 5px;
    display: inline-block;
    margin-left: -41px;
    width: 36px;
    text-align: center;
     width: 2%!important;
    padding-left: 4px;
    padding-right: 15px;

}
.banner-bg img {
    margin-top: -30px !important;
}
.banner-btn a {
    margin-right: 1em;
}
a.button.bkademo-1 {
    padding: 9px 22px;
}
ul.nine-points {
    margin-top: 25px;
    margin-left: 9%;
}
button.bkademo-1 {
    padding-left: 38px;
    padding-right: 38px;
    margin-right: 15px;
}
button.bkademo {
    background-color: #249800;
    border: 2px solid #249800; 
    padding: 5px 21px 6px 21px;
    color: #fff;
    border-radius: 6px;
}
.banner-btn {
    margin-top: 60px;
    margin-left: 0; display: flex; align-items: center;
}

.video {
    margin-top: -2%;
    padding-left: 0px;
    padding-right: 0px;
    background-image: url(/static/media/video-sec.f4ca020d.png);
    width: 100%!important;
    background-size: cover;
    height: 970px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 13em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}
.clms h1 {
    text-align: center;
    color: #54ab1a;
    font-weight: 900;
    padding-bottom: 0px;
}
.clms p {
    text-align: center;
    line-height: 21px;
    width: 85%;
    margin: 0 auto;
}
.price-bg {
    background-color: #f6f6f6;
    width: 100%;
    padding-top: 44px;
    padding-bottom: 93px;
}
.main-box-3 img {
    width: 36%;
}
button.paymnthly
{
    color: #ffffff;
    background-color: #54ab1a;
    border-color: #ccc;
    padding: 7px 25px 7px 25px;
    border-radius: 7px;
    margin-top: 23px;
}
.plan p {
    text-align: left;
}
.plan-bg {
    background-image: url(/static/media/plan-bg.2261c386.png);
    width: 100%!important;
    background-size: cover;
    height: 400px;
    margin-top: -32px;
}
.plan-1 p {
    font-size: 40px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    line-height: 1;
    padding-top: 62px;
    padding-left: 20px;
    padding-right: 20px;
}

.footer {
   background-color: #54ab1a;
    padding-bottom: 102px;
    padding-top: 22px;
}
.footer p {
    color: #fff;
    text-align: justify;
    padding: 0;
    font-size: 17px;
    display: inline-block;
    font-weight: 600;
    margin: 13px 0;
}

.ftr-bx-1 i.fa.fa-phone {
    color: #fff;
    font-size: 22px;
    margin-right: 12px;
}
.footer-bx-2 p {
    color: #fff;
}
.ftr-bx-1 i.fa.fa-map-marker {
    color: #fff;
    font-size: 22px;
    margin-right: 12px;
}
span.addrs-2 {
    color: #fff;
}
.footer-bx-2 p {
    text-align: left;
}
p.addrs {
    color: #fff;
    font-size: 15px;
    padding-top: 9px;
}
 p.addrs-1 
 {  color: #fff;
    font-size: 17px;
    font-weight: bold;
}
footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
img.line {
    width: 76%;
    margin: 0 auto; text-align: center; display: block;
}
.socialicon {
    margin-left: 27px;
    padding-top: 9px;
}
.ftr-bx-1 {
    border-right: 2px solid #d1ffb6;
}

.ftr-cpy p {
    text-align: center;color:#fff;
}
.socialicon img {
    width: 43px;
    padding-left: 20px;
    padding-top: 8px;
}
.ftr-cpy {
    background-color: #6a6a6a;
    padding: 0;
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 12px;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #54ab1a;
  border-radius: 12px;
}

.flip-card-front {
 background-color: #54ab1a;
    color: #fff;
}
.flip-card-back p {
    padding-top: 0;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.flip-card-back {
  background-color: #54ab1a;
  color: #fff;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);display: flex
}
th.tble-logo img {
    width: 33%;
    margin-top: 1px;
    margin-bottom: -8px;
}
.feat thead {
    background-color: #54ab1a;
    text-align: center;
}
.feat th {
    padding-top: 0px;font-size: 20px!important;
}
table { width: 100%; }
.feat.table>thead>tr>th {
    width: 17%;
    color: #fff;
    text-align: center;
    border-bottom: 2px solid #a8d39b;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    line-height: normal;
}
th.tble-logo img {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 41%;
    vertical-align: text-top;
}
table.table.table-striped.feat tbody tr td:nth-child(3) {
    border-right: 0;
}

table.table.table-striped.feat td {
    text-align: center;
    color: #54ab1a;
    font-family: 'Oxygen';
    font-weight: bold;
    border-right: 3px solid #54ab1a;
    padding: 7px 0;
    vertical-align: middle;
    border-top: 0;
    font-size: 20px;
}
/*tr.tble-1 {
    background-color: #95d0848c!important;
}
tr.tble-2 {
    background: #f0f0f0;
}*/
.table-striped tbody tr:nth-of-type(odd) {
    background: rgba(0,0,0,.05);
}
tr.tble-3{background-color: #6a6a6a!important;}
tr.tble-3 td {
    color: #fff!important;
}
.flip-card-front img {
padding-top: 57px;
}
.flip-card-front p {
    font-size: 17px;
    padding-top: 22px;
    color: #fff; font-weight: 700;
}
.space-line {
    padding-bottom: 30px;
}
.space-line-hd 
{
padding-bottom: 50px;
}
.hd-p-1 {
    padding-top: 8px;
}
@media (max-width: 480px) {
  footer {
    flex-direction: column;
  }
  
  footer i{
    order: 2;
  }
}
/*Mobile Resposive*/
@media(max-width: 767px) 
{
    .navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>span {
        font-size: 16px;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
    }
.beagi p 
{
    font-size: 15px;
    text-align: center; text-align-last: center;
    line-height: 25px; margin: 0 auto;
}

.banner-btn {
    margin-top: 20px; display: block;
    margin-left: 0%; text-align: center; margin-bottom: 3em;
}
ul.nine-points {
    margin-top: 0px;
}

.flip-card {
    width: 100%;
    padding-left: 0;
    margin-bottom: 25px; height: 265px;
}
.flip-card-front img {
    padding-top: 36px;
}
.space-line {
    padding-bottom: 0px;
}
button.bkademo-1 {
    padding-left: 41px;
    padding-right: 39px;
    margin-right: 4px;
}
.video{height: auto;
    background: no-repeat;
    padding: 0 0 1em;}
.price-circle{width: 56%!important;}
.ftr-bx-line {
    border-right: none;}
.loght-green-lf-bg{display: none;}
.loght-green-lf-bg1{display: none;}
.circle{margin-left: 0 !important;}
.banner-bg::before { content: none; }
.video-content iframe {
    width: auto;
    height: 288px;
}
.wrapper-home h1 { font-size: 26px;  }
.funnel-group::before { bottom: -10em; background-size: 180px; }
.price-bg-gray::before { background-size: 140px; }
.price-list-one h5 { font-size: 20px; }
h1.plan-one {
    font-size: 15px; }
.table-group {
    overflow-x: auto;
}
.feat th, table.table.table-striped.feat td {
    
    font-size: 15px!important;
}
th.tble-logo img { max-width: 130px; }
.plan-1 p { font-size: 25px; padding: 0; }
.flt-lft-line {
    
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.ftr-bx-line img {
    width: auto;
    
    vertical-align: top;
}
.address {
    display: block;
    
}
.footer p { margin: 8px 0; }


}
@media screen and (min-device-width: 320px) and (max-device-width: 568px)
{
    .ttttt
    {
    width: 100%;
    margin: 0 auto;
    }
    .footer-bx-2 {
    margin: 0 auto;
    padding-top: 0; text-align: center;
}
.flt-lft-line{border-right: none!important;}

.footer img {
    width: auto;
    padding-top: 20px;
    margin-left: 0;
}

}

@media screen and (min-device-width: 320px) and (max-device-width: 667px)
{
    .price-circle {
    width: 53%!important;
}
.ftr-bx-line img {
   margin-left: 0;
}
.plan-bg{height: auto !important;}
.ftr-screen img {
    width: 100%;
    position: relative!important;
    margin-top: 0px!important;
    margin-left: 0;
}
.plan-1 p{padding-top: 0px!important;}
button.planbtn-1{margin-left: 26%!important;margin-top: -4px!important;}


}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) 
{
.flip-card-inner
{
    width: 100%;
    height: 76%;
}
.flip-card-front img 
{
    width: 25%;
    padding-top: 64px;
}


}
li.active {
    padding-right: 22px;
}
button.bkademo i.fa.fa-play, .btn-home i.fa.fa-play{
    background-color: #f0f0f0;
    padding: 10px 7px 11px 14px;
    text-align: left;
    margin-left: -24px;
    margin-top: -12px;
    margin-bottom: -10px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    color: #54ac1a; vertical-align: middle;
}
span.play-btn-1 {
    padding-left: 8px;
}
button.bkademo-1 {
    padding-left: 38px;
    padding-right: 38px;
    margin-right: 15px;
}

.utb-c-btn {
    margin-top: 19%;
    margin-left: -8%;
}
img.bdr-btm-line {
    padding-top: 0px!important;
    width: 37%;
    height: 2px;
}
.trpy img{width: 100%; max-width: 700px; display: block; margin: 0 auto;}
.price-list-one h1,h2 {
   color: #fff;
}
.ftr-bx-line img {
    width: auto;
    max-width: 19px;
    margin-right: 10px;
}
.ftr-bx-line p a
 {
    color: #fff;
    text-decoration: none; font-size: 24px;
}
img.line1 {
    width: 27%;
    margin-left: 37%;
}
.price-list-one h1 {
    color: #54ab1a;
    font-weight: 600;
    text-align: center;
}
.price-list-one h5 {
    text-align: center;
    color: #595a5c;
    font-size: 23px;
    font-weight: 900;margin: 0;
}
.price-list-one p {
    color: #54ab1a;
    text-align: center;
}
.price-circle {
    border: 8px solid #acb5bc;
    width: 33%;
    margin: 0 auto;
    border-radius: 50%;
    padding: 36px 11px 34px 12px;
    text-align: left!important;
    background-color: #54ae0e;
    margin-top: 34px;
}
.price-circle h6 {
    color: #fff;
    font-size: 51px;
    font-weight: bold;
}
span.mnth {
    font-size: 22px;
    padding-left: 37px;
}
.price-circle h4 {
    margin-top: -16px;color: #fff;
}
.price-bg-gray {
    background-color: #f6f6f6;
    padding-bottom: 130px;
    margin-top: 2em;
    position: relative;
    padding: 2em 0 6em;
}
.price-list-one {
    padding-top: 35px;
    padding-bottom: 35px;
}
h1.plan-one {
    font-size: 18px;
    color: #54ab1a;
    font-weight: 800;
    padding-top: 35px;
    padding-bottom: 25px;
}
.ftr-screen img {
    width: 100%;
    position: absolute;
    margin-top: -40px;
}
span.ftr-leads {
    color: #54ab1a;
}
.main-box-3 h1 {
    color: #54ab1a;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0px; display: inline-block;
}
.main-box-3 h1::after {
    content: '';
    display: block;
    position: relative;
    top: 10px;
    width: 80%;
    left: 10%;
    border-top: 3px solid #ffb300;
}
.ttttt img {
   float: left;
    width: auto;
    max-width: 34px;
    vertical-align: middle;
}
span.ftr-phn {
    padding-top: 14px;
    margin-top: 16px;
    position: absolute;
    font-size: 18px;
    margin-left: 0;
    font-weight: 900;
}

span.ftr-phn1 {
    padding-top: 14px;
    margin-top: 16px;
    position: absolute;
    font-size: 14px;
    
}
.ttttt img {
    padding-top: 0;
    margin-left: 12px;
}
span.ftr-youtube a img {
    margin-top: 7px;
}
.trs iframe {
    margin-top: 71%;
    position: absolute;
    margin-left: -65px;
}
.footer ul li a { color: #fff; }
.loght-green-lf-bg {
    background-image: url(/static/media/green-bg-left.8c045e20.png);
    height: 943px;
    width: 495px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: -254px;
    margin-left: -7%;
}

.loght-green-lf-bg1 {
    height: 775px;
    background-image: url(/static/media/green-bg-right.35b17ba3.png);
    background-size: cover;
    float: right!important;
    width: 695px;
    position: absolute;
    margin-left: 52%;
    margin-top: -18%;
}

.block{
    text-align: center;
    vertical-align: middle;
    padding-top: 29px;
}
.circle {
    background: #54ab1a;
    border-radius: 200px;
    color: #fff!important;
    font-weight: 600;
    width: 200px;
    font-size: 63px;
    border: 8px solid #bfb9b9fa;
    font-family: 'Open sans';
    padding: 40px 0 0;
    height: 200px;
    vertical-align: middle;
    align-items: center;
    display: inline-block;
    line-height: 32px;
}
span.dollar {
    font-size: 38px;font-weight: 700;
}
span.mnth-p {
    font-size: 20px;
    margin-left: -30px;
    position: absolute;
    text-align: center!important;
}
span.qutn-mrk {
    color: #fff;
}
.ftr-links {
    margin-top: 2%;
}
.nabar-header {
        width: auto;
    max-width: 72%;
}



@media(max-width: 1200px){
    .navbar-default .navbar-nav>li:last-child { margin-left: 0; }
    .navbar-default .navbar-nav>li>a { font-size: 15px; }
    .beagi h1 { font-size: 40px; }
    .banner-btn a {
    margin: 1em auto;
    }
    .banner-btn {
            flex-direction: column;
    margin-top: 0;
    text-align: left;
    }
    .beagi p { text-align: left; text-align-last: left; }
    .flt-lft-line { height: auto; }
}
@media screen and (min-device-width: 767px) and (max-device-width: 1024px) {
    .navbar-default .navbar-nav>li>a {
        font-size: 14px;
        padding: 16px 10px;
    }
   
}
@media(max-width: 991px){
    .navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>span { color: #fff;}
    .navigation .navbar-nav {
        display: none;
    }
    .flip-card-back p { font-size: 16px; }
    .flip-card-front p {
    font-size: 15px;
    padding-top: 16px; }
    .price-bg-gray {
    margin-top: 0;
    padding: 0em 0 3em;
}
.plan-1 p {
    font-size: 29px; }
    .plan-bg { height: auto; }
    .flt-lft-line {
        border-right: 0; height: 235px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    color: #fff;
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    }
    .footer { padding: 25px 0; }
    .ftr-links {
       max-width: 100%;
    flex: 0 0 100%; text-align: center;
    }
    table.table.table-striped.feat td { font-size: 15px; }
    .footer .col-xs-12.col-sm-12.col-md-4.col-lg-4 {
    display: none;
}
.ftr-bx-line img {
    width: auto;
    
    vertical-align: top;
}

.agilead-pg-set1 {
    border-bottom: 1px solid #efefef;
}
div#navbar {
    display: block;
}
.navbar a { color: red; }

a#topiclinks-text {
    color: #ffb300;
    position: absolute;
    right: 25px;
    top: 30px;
}
nav.navbar-default.navbar-static-top.fixed {
    position: relative;
}
.topics-dropdown {
    position: absolute;
    background: #57ab1a;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    color: #fff; display: none;z-index: 9999999999;
}
.navbar-default .navbar-nav>li>a {
    color: #fff; font-size: 16px;
}
ul.nav.navbar-nav li {
    margin-top: 0;
    padding: 0;border-bottom: 0px solid #ffffff8f;
}
.navbar-default .navbar-nav>li>a.active {
    padding: 15px 15px; color: #fff;border-bottom: 0;
}
.navbar-static-top {
    margin-bottom: 0; 
    border-bottom: 1px solid #ccc9;
}
.navbar-default .navbar-nav>li:last-child {
    margin-left: 0;
    margin-top: 0;
    padding: 0 1em; 
}
.navbar-default .navbar-nav>li:last-child > a {
   
    background: #ffb300;
}
table.table.table-striped.feat td { padding: 4px 28px; }
.beagi h1 { font-size: 30px; }
.banner-bg::before { background-size: 390px; }
.banner-btn {
    flex-direction: column; margin-top: 0;
}
.banner-btn a {
    margin: 1em auto;
    display: block;
}
.container {
    max-width: 100%;
    width: 100%;
}

}
@media(max-width: 767px){ 
    .side-points .col-md-6 {
    display: flex;
    align-items: center;
    justify-content: center;
}
    .form-group .col-sm-10 {
    flex: 0 0 100% !important;
    max-width: 100% !important; width: 100% !important;
}
    .video {
    background: no-repeat;
    height: auto;
    padding: 1em 0;
}
.video-content iframe { width: 100%; height: 280px; }
    .flip-card { margin: 12px 0; }
    .ftr-screen img { position: relative; margin-top: 0; }
    .navbar-default .navbar-nav>li>a.active {
    padding: 10px 15px; color: #fff;border-bottom: 0;
}
ul.nav.navbar-nav li {
    margin-top: 0;
    padding: 0;border-bottom: 1px solid #ffffff8f;
}
    .phone-top{
        float: left !important; 
        margin-left: 30px;
    }
    ul.nav.navbar-nav > li:nth-child(7){ padding: 0 0 10px;}
    .beagi {
        max-width: 285px;
        margin: 0 auto;
    }
    .new-update-home .beagi {
        max-width: 320px;
        margin: 0 auto;
    }
    .new-update-home .beagi h1 {
        text-align: center;
        padding: 0;
        font-size: 18px !important;
    }
    .beagi h1{font-size: 19px!important;display: block;}
.beagi h2{text-align: center!important; font-size: 17px; padding-bottom: 10px; }
.bullet-lists { display: flex; align-items: center; flex-direction: column; }
.banner-btn a {
    margin-right: 0;
    display: block;
}
.price-list-one h5 { font-size: 20px; }
h1.plan-one { font-size: 15px; padding-top: 20px; padding-bottom: 15px; }
.price-bg-gray { padding: 2em 0 3em; }
.footer { padding: 1em 0; }
.form-group {
    display: block;
}




}

/****** Responsive Design Ends ***/



.thankyou h2 {
    color: #454545;
    border: 0;
    padding: 0;
    font-size: 30px;
}
.thankyou {
    text-align: center;
    padding: 2em 0 0;
}
.next button.btn.btn-primary.step-btn.company {
  width: 150px;
}
.navbar-default .navbar-nav>li:last-child a.active {
    padding: 10px 45px;
}


@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-Thin.62252a6e.eot);
    src: local('Geomanist Thin'), local('Geomanist-Thin'),
        url(/static/media/Geomanist-Thin.62252a6e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Thin.8c4fb18c.woff2) format('woff2'),
        url(/static/media/Geomanist-Thin.18da84f4.woff) format('woff'),
        url(/static/media/Geomanist-Thin.3657b7bd.ttf) format('truetype');
    font-weight: 100;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-Black.7a89abaf.eot);
    src: local('Geomanist Black'), local('Geomanist-Black'),
        url(/static/media/Geomanist-Black.7a89abaf.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Black.2e5889f2.woff2) format('woff2'),
        url(/static/media/Geomanist-Black.8819efce.woff) format('woff'),
        url(/static/media/Geomanist-Black.0c305ce6.ttf) format('truetype');
    font-weight: 900;
    font-style: normal; font-display: swap;
} 

@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-Medium.576e6a21.eot);
    src: local('Geomanist Medium'), local('Geomanist-Medium'),
        url(/static/media/Geomanist-Medium.576e6a21.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Medium.3f57faca.woff2) format('woff2'),
        url(/static/media/Geomanist-Medium.0444518f.woff) format('woff'),
        url(/static/media/Geomanist-Medium.4d7ff78f.ttf) format('truetype');
    font-weight: 500;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-Bold.cea30545.eot);
    src: local('Geomanist Bold'), local('Geomanist-Bold'),
        url(/static/media/Geomanist-Bold.cea30545.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Bold.f05682c3.woff2) format('woff2'),
        url(/static/media/Geomanist-Bold.f6f8b315.woff) format('woff'),
        url(/static/media/Geomanist-Bold.a26c7ebb.ttf) format('truetype');
    font-weight: bold;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist Book';
    src: url(/static/media/Geomanist-Book.4637a980.eot);
    src: local('Geomanist Book'), local('Geomanist-Book'),
        url(/static/media/Geomanist-Book.4637a980.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Book.5a1e0983.woff2) format('woff2'),
        url(/static/media/Geomanist-Book.13cd8e5e.woff) format('woff'),
        url(/static/media/Geomanist-Book.8f311931.ttf) format('truetype');
    font-weight: normal;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist Ultra';
    src: url(/static/media/Geomanist-Ultra.6b947cd2.eot);
    src: local('Geomanist Ultra'), local('Geomanist-Ultra'),
        url(/static/media/Geomanist-Ultra.6b947cd2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Ultra.58804aba.woff2) format('woff2'),
        url(/static/media/Geomanist-Ultra.9b07e47c.woff) format('woff'),
        url(/static/media/Geomanist-Ultra.03898025.ttf) format('truetype');
    font-weight: normal;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-Regular.9f05461a.eot);
    src: local('Geomanist Regular'), local('Geomanist-Regular'),
        url(/static/media/Geomanist-Regular.9f05461a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Regular.e675bfea.woff2) format('woff2'),
        url(/static/media/Geomanist-Regular.a22181c4.woff) format('woff'),
        url(/static/media/Geomanist-Regular.a00c8fa5.ttf) format('truetype');
    font-weight: normal;
    font-style: normal; font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-ExtraLight.c9fd5df3.eot);
    src: local('Geomanist ExtraLight'), local('Geomanist-ExtraLight'),
        url(/static/media/Geomanist-ExtraLight.c9fd5df3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-ExtraLight.b4b0baf8.woff2) format('woff2'),
        url(/static/media/Geomanist-ExtraLight.c047740e.woff) format('woff'),
        url(/static/media/Geomanist-ExtraLight.e7ee7336.ttf) format('truetype');
    font-weight: 200;
    font-style: normal; font-display: swap;
} 

@font-face {
    font-family: 'Geomanist';
    src: url(/static/media/Geomanist-Light.97fea92f.eot);
    src: local('Geomanist Light'), local('Geomanist-Light'),
        url(/static/media/Geomanist-Light.97fea92f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Geomanist-Light.996fb927.woff2) format('woff2'),
        url(/static/media/Geomanist-Light.f46865a7.woff) format('woff'),
        url(/static/media/Geomanist-Light.fb407aca.ttf) format('truetype');
    font-weight: 300;
    font-style: normal; font-display: swap;
}


body { font-weight: 500 !important; color: #000 !important;}
html {
    scroll-behavior: smooth;
  }
  .type-common{
    text-transform: uppercase;
    color: #ffa844;
        font-weight: 700;
        font-size: 30px;
    }
p { font-size: 18px; }
.logo-lp img, .logo-header img{ 
    padding: 5px 0; }
.header {background:#54ab1a;box-shadow: 0 5px 8px -2px #a7a0a0;}
.social-profiles ul li a {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 0 10px;
}
.header { padding: 5px 0; }
.social-profiles ul li a i {
    margin-right: 6px;
    font-size: 22px;
}
.social-profiles {
    padding: 0;
    text-align: right;
}
.hero-banner.banner-bg { position: relative; padding: 3em 0; text-align: center; }
.hero-banner::before {
    position: absolute;
    background: url(https://headnurture.com/banner-left.png);
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: auto;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 290px;
    z-index: -1;
    top: -41px;pointer-events: none;
    bottom: auto;
}
.banner-buttons a {
    margin-left: 10px !important;
    margin-right: 10px !important;
    
}
.hero-banner::after {
    position: absolute;
    background: url(https://headnurture.com/banner-right.png);
    content: "";
    width: 100%;
    height: 100%;
    left: auto;
    right: 0;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 400px;
    z-index: -1;
    top: -4em;
    bottom: auto;pointer-events: none;
}
h2 { color: #454545; }
.hero-banner h1 {
    font-weight: 700;
    border: 2px solid #ffaf8d;
    display: inline-block;
    padding: 5px 10px;
}
.hero-banner h2 {
    font-size: 30px;
    font-weight: 700;padding: 0.3em 0; margin: 0;border-bottom: 0;
text-transform: capitalize; color: #454545;
}
.dashboard-image {
    padding-top: 4em;
}
.social-icon ul li {
    display: inline-block;
}
.dashboard-image img {
    max-width: 700px;
    margin: 0 auto 1.5em;
    display: block; width: 100%;
}
a.lp-btns, .lp-btns {
    /*box-shadow: 0px 6px 0px #f5a27d, 0px 3px 15px rgba(0,0,0,.4);*/
    font-size: 18px;
    color: #fff;
    background:#54ab1a;
    padding: 10px 30px;
    border-radius: 30px;
    font-weight: 700;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-position: center bottom !important;
    display: inline-block;
    margin: 2em 0;border: 0 !important;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;overflow: hidden;

}
.lp-btns:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.lp-btns:hover:before{
    -webkit-transform: scaleX(1);
    transform: scaleX(1);background-color: #ffa844;
}
.dentist-issues {
    background: #fff;border-top: 1px solid #ccc;
    padding: 3em 0;
    text-align: center;box-shadow: 0 5px 8px -2px #b7b2b2;position: relative;
}
.all-issues-lists img {
    max-width: 70px; margin: 0 auto;
}
.all-issues-lists .col-md-10 {
    padding: 0;
}
.all-issues-lists h3 {
    text-align: left;
    padding: 0;
    font-weight: 700;
}
.all-issues-lists p {
    font-size: 13px;
    text-align: justify; margin: 0;
}
.all-issues-lists .col-md-2{
	align-items: center;
    justify-items: center;
    display: flex;
}
.all-issues-lists:nth-child(2n+1) {
    margin-left: 2em;
}
.all-issues-lists {
    /*flex: 1 1 48%; margin-left: 4%;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;*/
    padding: 2em 1em 2em 1em;    
    background: #fff; float: left;
    margin: 1em auto;
    border-radius: 100px; max-width: 800px;border: 0px solid #fff; background: #ececec
    
}
.all-issues-lists:nth-child(2), .all-issues-lists:nth-child(4) {
    float: right;
    display: block;padding: 2em 3em 2em 3em;
}
.all-issues {
    text-align: center; max-width: 1000px; margin: 0 auto;padding-top: 1em;
}
.all-issues i {
    color: #57ab1b;
    font-size: 45px;
}


.wrapper h2 {
	font-weight: 700;
    font-size: 34px;
    /*border-bottom: 4px solid#f9a743;*/
    display: inline-block;
    padding-bottom: 20px; color: #54ab1a
}
.dentist-issues h2 {
    color: #54ab1a;
}
.dental-business img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: right;
    float: right;
}
.dental-business p {
    font-size: 16px;
    text-align: justify;
}
.dental-business {
    padding: 4em 0; position: relative;
}
.dental-business iframe {
    border: 9px solid#54ab1a;
    float: right;
    border-radius: 7px;
}
.dentist-issues::before {
    background: url(https://headnurture.com/dentist-issues.png);
    position: absolute;
    content: none;
    width: 100%;
    height: 100%;
    top: -14em;
    background-repeat: no-repeat;
    background-position: right top;
    bottom: auto;
    background-size: 300px;
    left: auto;pointer-events: none;
    right: -50px;
}
.dental-business h3 { 
    font-size: 25px;
    font-weight: 700;
    border-bottom: 4px solid#f9a743;
    padding-bottom: 10px;    
    text-align: center;
    width: auto;
    align-items: center;
    justify-content: center;
    float: none;
    margin: 0 auto 10px;
}
.advantages-group {
    background: #f8f8f8;box-shadow: 0 5px 8px -2px #b7b2b2;
    padding: 3em 0; position: relative;text-align: center;z-index: 999;
}
.advantages-group::before{
    background: url(https://headnurture.com/choice.png);
    position: absolute;
    content: none;
    width: 100%;
    height: 100%;
    top: -4em;
    background-repeat: no-repeat;
    background-position: left top;
    bottom: auto;
    background-size: 300px;
    left: 0;
    right: auto;z-index: -1;pointer-events: none;
}
.advantages-group::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: auto;
    right: -25px;
    background: url(https://headnurture.com/dentist-issues.png);
    background-repeat: no-repeat;
    top: auto;
    bottom: -12em;
    background-position: right bottom;
    background-size: 260px;pointer-events: none;
}
.advantages-list {
    display: flex;
    flex-wrap: wrap;padding: 1.5em 0 0;
}
.advantages-lists:nth-child(3n+1) {
    margin-left: 0;
}
.advantages-lists {
    flex: 1 1 25%;
    margin-left: 4%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; margin-bottom: 3em;
}
.advantages-lists h3 {
    font-size: 20px;
    min-height: 4em;padding: 1em 0 0;
}
.advantages-lists p {
    font-size: 16px;
}

.advantages-lists img {
    
    max-width: 100px;
    
}
.features-group .col-md-2 img {
    background:#54ab1a;
    padding: 15px 10px;
    border-radius: 5px;
    width: 80px;
    height: auto;
    object-fit: cover;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.features-group h3 {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
}
.features-group p {
    font-weight: 400;
    font-size: 16px;
    text-align: left;
}
.features-group .col-md-6 .row {
    padding: 2em 0;
}
.features-group .col-md-6 .col-md-6 img {
    width: auto;
    max-width: 550px;
}
.leadnurture-features {
    text-align: center;
    padding: 2em 0 0;
}
.features-group .col-md-6:nth-child(2) .row:nth-child(2) {
    padding-top: 0;
}

.get-in-touch {
    background: linear-gradient(to bottom,rgba(242,242,242,1) 0,rgba(255,255,255,0) 100%);
    padding: 3em 0 0;
    text-align: center;position: relative; 
}
.social-icon ul li a i {
    font-size: 30px;
}
.social-icon ul li a {
    color: #fff;
    padding: 0 10px;
}
.social-icon {
    text-align: right; 
}
.social-icon ul {
    margin: 0;
}
.get-in-touch .form-group {
    margin-bottom: 1em;
}
.get-in-touch .form-control, .get-in-touch input#example-datepicker, .get-in-touch .form-group input {
    border: 1px solid #c1bbbb !important;
    height: auto;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
}
.get-in-touch form .form-group .col-md-6 input {
    border: 1px solid #c1bbbb !important;
    height: auto;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
}
.form-group .col-md-6 {
    color: #6d6d6d;
    text-align: left;
}
input.lp-btns {
    width: 24%;
    /* background-size: cover !important; */
    padding: 8px 0;
    text-align: center;cursor: pointer;
}
input#example-datepicker {
    width: auto;
    max-width: 185px;
}
.get-in-touch textarea.form-control { height: 150px; }
.get-in-touch input#cc {
    width: auto;
    /* border-radius: 0; */
    max-width: 49px;
}
.get-in-touch::before {
    background: url(https://headnurture.com/choice.png);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: -37px;
    background-repeat: no-repeat;
    background-position: left bottom;
    bottom: auto;
    background-size: 300px;
    left: -100px;
    right: auto;
    z-index: 9;
    pointer-events: none;
}


.thank-you {
    text-align: center;
    padding: 3em 0 0;
}
.thank-you h2{
    border-bottom: 0;
}
.footer-lp {
    text-align: center;
    padding: 1em 0;
    margin: 0;
    font-size: 18px;
    color: #fff;
    background:#54ab1a!important;
}
.col-md-6.copy {
    display: flex;
    align-items: center; 
    justify-content: center;
}
.all-issues-lists:hover {
    background:#54ab1a;
    transition: 0.4s ease-in-out 0s;
}
.all-issues-lists:hover h3, .all-issues-lists:hover p { color: #fff; }
.testimonial-group .carousel.carousel-slider {
    padding: 2em 1em 3em;
    max-width: 750px;
    margin: 0 auto;
}
.testimonial-group {
    padding: 4em 0 3em;
}
.testimonial-lists p { font-size: 17px; color: #fff; font-weight: 600;}
.testimonial-lists h3 { font-size: 18px; color: #fff; font-weight: 900; font-style: italic;}
.testimonial-group .carousel .control-dots .dot{ width: 14px; height: 14px; background:#54ab1a; }
.testimonial-lists {
    border: 0px solid #ffaf8d;border-radius: 4px;
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    text-align: center;
    justify-content: center;
    padding: 3em 8px 1em; background:#54ab1a !important;
}
.testimonial-group .carousel .control-next.control-arrow:before {
    border-left: 15px solid #cf7b32;
}
.testimonial-group .carousel .control-prev.control-arrow:before {
    border-right: 15px solid #cf7b32;
}
.testimonial-group .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}
.testimonial-group .carousel .control-arrow:before, .testimonial-group .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    content: '';
}
.testimonial-group .carousel .control-prev.control-arrow {
    left: -10px;
}
.testimonial-group .carousel .control-next.control-arrow {
    right: -10px;
}


button.learn-more {
    width: auto;
    height: auto;
    flex: 0 1 20%;
}
button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}
button.learn-more .circle-lp {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 5px 0 0;
    width: 3rem;box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 3rem;
    background:#54ab1a;
    border-radius: 1.625rem;
}
button.learn-more .circle-lp .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}
button.learn-more .circle-lp .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}
button.learn-more .circle-lp .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); font-weight: 700;
}
button:hover .circle-lp .icon.arrow {
    background: #fff;
    -webkit-transform: translate(1rem, 0);
            transform: translate(1rem, 0);
}
button:hover .circle-lp {
    width: 90%;
}
button:hover span.button-text a {
    color: #fff;
}
button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 30px;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: left;
    text-transform: uppercase;
}
.banner-buttons { display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.5em 0; }

span.button-text a {
    color: #454545;
    text-transform: capitalize;
    text-align: left;
}


.email-content{
  width: 80%;
  margin: auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h3.email-content-header {
  font-size: 30px;
    font-weight: 600;
    text-align: center;color: #454545;
}
.email-content-para {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0;
    color: #454545;
}
p.elementor-image-box-description {
    margin-bottom: 3em;
}
.client-image img {
    width: 100%;
}
.result-banner h2 {
    color:#57ab1a;
    font-size: 40px;
    padding: 1em 0;
    text-align: center;
}
.result-banner .row h2 {
    font-size: 20px;
}
.result-banner .row h2 strong {
    color: #fb0;
}
.lpage .ttttt {
    text-align: right;
    float: right;
    padding: 0 2em;
}
.lpage .ttttt ul li {
    display: inline-block;
    vertical-align: baseline;
    vertical-align: initial;
}
.hero-banner.banner-bg h2, .hero-banner.banner-bg p {
    color: #000;
}
/*** new lp changes **/
.wrapper .hero-banner.banner-bg h2 {
    border: 0;margin-bottom: 0.5em;color: #5eab1c;
    font-size: 34px;
}
.all-issues .col-md-4 {
    padding: 1em 10px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    border-radius: 3px;
    background: #fff;
    max-width: 32%;
    margin: 2em auto;
}
.all-issues .col-md-6{
    padding: 1em 10px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    border-radius: 3px;
    background: #fff;
    max-width: 48%;
    margin: 2em auto;
}
.form-banner .form-group {
    background: #5eab1c;
    border-radius: 5px;
}
.form-group.row {
    background: transparent;
}
.form-banner h2 {
    font-size: 21px;
    vertical-align: text-top;
    color: #57ab1b;
}
.form-banner button.lp-btns {
    background: #f9a743;
    margin: 0;
    padding: 5px 20px;
}
.form-banner textarea.form-control {
    height: 90px;
    font-size: 16px;
}
.header-sec{
    transition: all 0.5s ease; }

.header-sec.fixed {
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 9999999;
    left: 0;
    right: 0;-webkit-animation: smoothScroll 1s forwards;animation: smoothScroll 1s forwards;
}
@-webkit-keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-40px);
		        transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}
@keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-40px);
		        transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}
.hero-banner {
    background: #57ab1b;
    text-align: center;
    padding: 2em 0;
}
.hero-banner h2, .hero-banner p {
    color: #fff;
    padding: 0;
}
.lists-group .row h2, .customer-management .row h2, .featuress .row h2 {
    font-size: 22px;
    margin: 0  0 10px;
    padding: 0; text-align: left; color: #000;
}
.lists-group img {
    max-width: 200px;
    display: block;
    margin-bottom: 2em;min-height: 12em;
}
.lists-group p, .customer-management p{
    font-size: 15px;
    margin: 0;
    padding: 0; color: #737373;
}
.lists-group h2.heading-title, .customer-management h2, .featuress h2 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    line-height: 35px;display: block;
    letter-spacing: .4px; margin-bottom: 1em;
}
.lists-group {
    
    padding: 2em 0;
}
.lists-group .feature-image {
    min-height: 14em;
}
.customer-management img, .featuress .row .col-md-6 img {
    max-width: 200px;
}
.customer-management .feature-image {
    min-height: 11em;
}
.customer-management {
    background-color: #e7f5ff;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    padding: 50px 0;
}
.personalized-experience  {
    padding: 50px 0;
}
.personalized-experience p{
    color: #737373; font-size: 15px;
}
.personalized-experience img {
    max-width: 200px;
}
.personalized-experience .feature-image {
    min-height: 14em;
}
.flow-image img {
    width: 900px;
}
.hero-banner.banner-bg.lp {
    background: transparent;
}
li span.ftr-youtube a img {
    vertical-align: middle;
    -webkit-transform: translate(0px, -4px);
            transform: translate(0px, -4px);
}
.wrapper .hero-banner.banner-bg .main-content-new h2 {
    font-size: 30px;
    color: #454545;
    font-weight: 600;
}
.main-content-new ul li {
    list-style: disc;
    display: list-item;
    text-align: left;
}
.main-content-new {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 7em 0 0;
}

/**** New Lp Design ****/
.banner-lp {
    background: #09436C;
    padding: 5em 0 4em;
    color: #fff;
}
.button-wrapper a {
    background: #54ab1a;
    padding: 10px 20px;
    display: inline-block;
    font-weight: 600;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    font-size: 18px; border-radius: 4px;
}
.button-wrapper a:hover {
    background: orange;
    border: 1px solid #fff;
    color: #fff;
}
.banner-lp p{
    font-size: 20px;
    font-weight: 700;
    color: #fff;font-style: oblique;
    padding: 1em 0;
}
.live-dentists h2 {
    color: #000;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Geomanist';
}
.live-dentists {
    text-align: center;
    padding: 1em 0;
}
.live-dentists p{
    font-weight: 400;
    color: rgb(133, 139, 151);
    font-family: 'Geomanist';
}
.live-dentists h3 {
    color: #54ab1a;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Geomanist';
    margin: 10px 0 0;
}
.live-dentists .col-md-3 p {
    font-weight: 400;
    font-family: 'Geomanist';
    color: #000;
}
.newlpgroup .container {
    max-width: 1140px;
}
.dental-implants h2 {
    color: #09436C;
    font-size: 33px;
    text-transform: uppercase; font-weight: 900;font-family: 'Oswald';
    text-align: center;
}
.dental-implants {
    padding: 5em 0 2.5em;position: relative;box-shadow: rgb(0 0 0 / 24%) -2px 7px 4px;
}
.dental-implants p {
    font-weight: 900;
    text-align: center;
    font-size: 22px;margin-bottom: 1em; 
}
.dental-implants iframe {
    width: 100%;
    height: 400px;
}
.dental-implants .col-md-8 p {
    margin-bottom: 0;
    text-align: left;
    font-weight: 500;
    line-height: 1.5;
    padding: 1em 0;
    font-size: 16px;color: #000;
}
.form-container-group input:focus {
    border: 1px solid !important;
}
.dental-implants .col-md-8 h4{
    text-align: left;
    font-size: 22px;
    margin-bottom: 10px;
}
.dental-implants .col-md-8 h3 {
    color: #54ab1a;
    font-size: 30px;
    font-weight: 900;text-align: center;
    font-family: 'Oswald';
    margin: 1em 0 0;
}
ul.list-items li {
    font-size: 18px;
    padding: 10px 0; display: flex;
}
ul.list-items li i {
    color: #54ab1a;
    font-size: 30px;
    padding: 0 8px 0 0;
    vertical-align: middle;
}
.live-dentists .container{
    /* box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
    background: #fff;
    padding: 2em 1em;
    margin-top: 0em;
    border-radius: 5px; */
}
.form-container-group {
    background: #fff;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 6px;
}
.marketing-strategy .form-container-group h2 {
    background: #54ab1a;
    font-size: 20px;border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 15px 0;
    color: #fff; text-align: center;
}
.marketing-strategy h2 {
    color: #09436C;
    font-size: 30px;
    font-weight: 700;
}
.marketing-strategy p {
    color: #09436C;
    
}
.marketing-strategy img { width: 100%; }
.form-container-group input:nth-child(3), .form-container-group input:nth-child(2), .form-container-group input:nth-child(4), .form-container-group input:nth-child(5) {
    width: 94%;
}
.form-container-group input {
    background: #fff;
    width: 94%;
    margin: 5px 5px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #868181;
}
.form-container-group .banner_form_submit input {
    background: #09436C;
    width: 20%;
    border: 0;
    color: #fff;
    font-weight: 700;
}
.form-container-group form {
    padding: 1em 10px;
}
.lead-generation.funnel {
    padding-bottom: 4em;
}
.lead-generation { position: relative;padding: 3em 0;}
.lead-generation.funnel::before {
    background: url('http://agileads.co/formtop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 237px;
    bottom: 0;
    pointer-events: none;
    left: 0;
    z-index: -1;
    
    content: "";
}
.marketing-strategy {
    background: #f5f7fc;
    padding: 2em 0;
}
.right-img img {
    width: 55%;
    float: none;
    margin: 0 auto;
    display: block;
}
.footer-section {
    background: #09436C;
    text-align: center;
    padding: 10px 0;
}
.footer-section p { margin: 0;color: #fff; }
.left-banner h1 {
    font-family: 'Oswald' !important;
    font-size: 35px !important; margin: 0;
}
i.fa.fa-phone-square {
    
    display: inline-block;
    margin: 4px;
    vertical-align: middle;
}
.progressive-dental {
    box-shadow: rgb(0 0 0 / 24%) -2px 7px 4px;
    padding: 3em 0;
}
.progressive-dental img {
    width: 100%;
}
.progressive-dental h2 {
    font-size: 35px;
    color: #000;
    font-family: 'Oswald';
}
.progressive-dental ul {
    padding-left: 0em;
}
.progressive-dental ul li i.fa.fa-check {
    color: #54ab1a;
    padding: 3px 10px;
    vertical-align: middle;
}
.progressive-dental p {
    color: #000;
    font-size: 20px;
    margin: 10px 0;
}
.progressive-dental ul li {
    color: #000;
    font-size: 20px;
   
    display: flex;
}
.lead-generation h2 {
    font-size: 52px;
    color: #000;
    font-family: 'Oswald';
    margin-bottom: 0.5em;
}
.lead-generation p {
    font-size: 16px;
    color: #000;   
    margin-bottom: 1em;
}
.lead-generation.funnel img, .lead-generation img {
    width: 100%;
}
.button-wrapper div {
    padding: 0 25px 0 0;
}
.button-wrapper {
    display: flex;
    align-items: center;
}
.emblem-img img {
    width: 200px;
    border-radius: 50%;
}
.newlpgroup .thankyou-cont h2 {
    color: #09436C;
    font-size: 30px;
    margin: 0;
}
.newlpgroup .thankyou-cont{
    padding: 1em 0 0;
}
.banner-lp p strong {
    font-size: 30px;
    vertical-align: -5px;
    color: #57ab1b;
    font-weight: 900;
}

/** Landing Page Design 1 ******/
.landingpage1-group .container {
    max-width: 1200px;
}
.landingpage1-group .header1 .container {
    max-width: 1250px;
}
.banner-section h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
}
.banner-section {
    background: rgb(245, 246, 247);
    text-align: center;
    padding: 1.5em 0;
}
.banner-section h2 {
    text-align: center;
    font-size: 45px;
    color: rgb(32, 32, 32);
    font-weight: 700; padding: 0 20px;
    font-family: 'Geomanist'; margin-bottom: 10px;
}
.banner-section .beagi ul li {
    font-size: 14px;
    display: inline-block;
    padding: 0 8px;
    color: #54ab1a;
    border-right: 1px solid #000;
    line-height: 1.3; font-family: 'Geomanist';
    font-weight: 500;
}
.banner-section .beagi ul li:last-child {
    border-right: 0;
}
.banner-section .beagi ul {
    margin: 0;
    padding: 0em 0 2em;
}
.banner-content-full .left-img img {
    float: left;
    display: block;
    width: 290px;
    background: rgb(245, 246, 247);
    /* background-color: #54ab1a; */
}
.banner-content-full .right-content h3 {
    color: #858b97;
    font-family: 'Geomanist';
    letter-spacing: 0.4px;
    text-align: left; font-weight: 400; font-size: 15px;
}
.banner-content-full p strong { font-weight: 500;}
.banner-content-full p {
    text-align: left;font-family: 'Geomanist';
    font-size: 18px; color: #4A4A4A; font-weight: 300;
    line-height: 1.8;
    margin: 0;
}
.banner-content-full {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; display: flex;
    align-items: center; background-color: #fff; border-radius: 3px;
}
.banner-content-full .right-content {
    padding: 0 35px;
}
.header1 {
    background: #54ab1a;
    padding: 8px 0;
}
.footer-new-section p, .footer-new-section a {
    margin: 0;
    color: #fff;font-family: 'Geomanist';
}

.footer-new-section {
    border-top:1px solid #cccccc94;
    text-align: center;
    padding: 10px 0; background-color: #54ab1a;
}
.signup-section h2 {
    font-size: 42px;
    font-weight: 500;
    color: #000;
    font-family: 'Geomanist';
}
.buttons p {
    font-family: 'Geomanist';
    font-weight: 400;
}
.signup-section {
    background: rgb(245, 246, 247);
    padding: 4em 0;
    text-align: center;
}
.live-dentists h2 span, .frequently-section h2 span, .signup-section h2 span, .implantpatient-section h2 span, .frontoffice-section h2 span {
    color: #54ab1a;
}
.buttons a {
    background: #54ab1a; color: #fff;
    padding: 10px 60px;
    display: inline-block;
    border-radius: 3px;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    font-family: 'Geomanist';
    font-weight: 500; cursor: pointer;
}
.buttons {
    padding: 1em 0;
}
.implantpatient-section {
    text-align: center;
    padding: 3em 0 3em;
}
.frontoffice-section { padding: 2em 0; text-align: center; }
.implantpatient-section h2, .frontoffice-section h2, .frequently-section h2 {
    color: #000;
    font-size: 35px;
    font-family: 'Geomanist';
    font-weight: 500;
}
.implantpatient-section p, .frontoffice-section p {
    color: rgb(133, 139, 151);
    margin-bottom: 2em;font-family: 'Geomanist';
    font-weight: 400;
}
ul.bull-points li i.fa.fa-check {
    color: #54ab1a;
    font-size: 20px;
    padding: 8px;
}
ul.bull-points li i.fa.fa-check {
    color: #54ab1a;
    font-size: 20px;
    padding: 8px;
}
ul.bull-points {
    text-align: left;
}
.frontoffice-section img {
    width: 550px;
    display: block;
    margin: 0 auto;
}
.pricing-section {
    background: rgb(245, 246, 247); padding: 2em 0;
}
.pricing-section-inner table th {
    background: #fff; text-align: center;
    padding: 1em 0;
    color: #57ab1a;
    font-size: 30px;
    font-family: 'Geomanist';
    font-weight: 500;
}
.pricing-section-inner table td {
    padding: 5px 10px;
    color: rgb(38, 40, 45);
    font-size: 18px;
    font-weight: 400;
    font-family: 'Geomanist';vertical-align: baseline;
    width: 33%;
}
.pricing-section-inner table th:nth-child(2) {
    border-right: 1px solid rgb(228, 232, 237);
    border-left: 1px solid rgb(228, 232, 237); 
}
.pricing-section-inner table td:nth-child(2) {
    border-right: 1px solid rgb(228, 232, 237);
    border-left: 1px solid rgb(228, 232, 237);
}
.landingpage1-group .thankyou-cont h2 {
    color: #000;
    font-size: 35px;
}
.landingpage1-group .thankyou-cont p {
    color: #000;
   
}
.pricing-section-inner table td a {
    background: #54ab1a;
    color: #fff !important;
    display: block;
    width: 60%;
    margin: 0 auto 0;
    padding: 8px;
    border-radius: 2px;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s !important;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px; cursor: pointer;
    font-family: Roboto, arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.trial {
    color: rgb(32, 32, 32);
    font-weight: 400;
}
.pricing-section-inner {
    background: #fff;
    text-align: center;
    padding: 0 35px;
    
}
.price-sample {
    box-shadow: rgb(0 0 0 / 12%) 0px 10px 80px;
}
.landingpage1-group {
    background: rgb(245, 246, 247);
}
.disclaimer ul li:nth-child(2) {
    border-left: 2px solid rgb(228, 232, 237);
    border-right: 2px solid rgb(228, 232, 237);
}
.disclaimer ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Geomanist';
    padding: 0 10px;
}
.disclaimer ul { margin: 0; }
.disclaimer {
    background: rgb(248, 248, 248);
    text-align: center;
    padding: 1em 0;
}
.frequently-section h2 {
    text-align: center;
    margin-bottom: 25px;
}
.faq-lists .accordion__button {
    font-size: 22px;
    background: #fff;
    padding: 35px 20px;
    font-family: 'Geomanist';
    color: rgb(38, 40, 45) !important;
    font-weight: 300; position: relative;
}
.faq-lists {
    max-width: 1000px;
    margin: 0 auto;
}
.faq-lists .accordion__item + .accordion__item {
    border-top: 1px solid rgb(228, 232, 237);
}
.faq-lists .accordion {
    
    border-top: 1px solid rgb(228, 232, 237); border-left: 0 ; border-right:0 ;
}
.frequently-section {
    padding: 3em 0; background-color: #fff;
}
.video-contents iframe {
    width: 60%;
    height: 400px;
}
.faq-lists .accordion__item.active .accordion__button {
    background: transparent;
    padding: 25px 13px 0;
    font-weight: 500;
    vertical-align: middle;
    color: #54ab1a !important;
    font-family: 'Geomanist';
}
.faq-lists .accordion__item.active {
    border-left: 3px solid #54ab1a;padding: 0 15px;
}
.lp-emblem img {
    width: auto;
    margin-bottom: 0;
}
.buttons a:hover, .pricing-section-inner table td a:hover {
    background: #ffa844;
    color: #fff;
}
.faq-lists .accordion__panel p {
    font-weight: 100;
    font-size: 20px;
}
.faq-lists .accordion__button:before {
    display: inline-block;
    content: '+';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    -webkit-transform: rotate( 
-0deg
 );
            transform: rotate( 
-0deg
 );
    float: right;
    font-size: 60px;
    top: 10px;
    position: absolute;
    z-index: 99;
    right: 32px;
    color: rgb(155, 155, 155) !important;
    font-weight: 200;
    border-bottom: 0px solid currentColor;
    border-right: 0px solid currentColor;
}
.buttons p img {
    vertical-align: bottom;
}
.faq-lists .accordion__item.active .accordion__button[aria-expanded='true']::before, .faq-lists .accordion__item.active .accordion__button[aria-selected='true']::before {
    -webkit-transform: rotate(
0deg
);
            transform: rotate(
0deg
);
content: '_';
top: -10px;
    right: -10px; display: flex; justify-content: center;
}
.lp1-form h2 {
    display: inline-block;
    color: #000;
    padding: 0;
    margin: 0;
    font-weight: 400;
}
form.lp1 input {
    margin-bottom: 1em;
}
form.lp1 .form-group.row {
    margin-bottom: 0;
}
form.lp1 {
    padding-bottom: 0;
}
.banner-title {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}
.banner-section .beagi h1 {
    font-size: 40px;
    font-family: 'Geomanist';
    font-weight: 500;
    border-left: 4px solid rgb(255, 168, 68);
    display: inline-block;
    padding: 0 10px;
    color: #57ab1a;
    line-height: 1;
    vertical-align: text-bottom;
    border-right: 4px solid rgb(255, 168, 68);
}
.banner-section .right-content h2 {
    text-align: left;
    padding: 0;
    font-size: 27px;
    margin: 0 0 15px; color: #54ab1a;font-family: 'Geomanist';
}
.monthly-text span {
    display: block;
}
.monthly-text {
    text-align: center;
    font-family: 'Geomanist';
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 15px;
}
.monthly-text span {
    display: block;
}
.monthly-text {
    text-align: center;
    font-family: 'Geomanist';
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 15px;
}
.lp-emblem {
    -webkit-transform: rotate(
0deg
);
            transform: rotate(
0deg
);
    position: relative;
    top: -12px;
}
.pricing-section-inner.desktop { 
    display: block;
}

.pricing-section-inner.mobile {
display: none;
}
.faq-lists .accordion__button:focus { outline: none;}
ul.bull-points li {
    list-style: none;font-family: 'Geomanist';display: block;
}

/***** new lp use it lose it ******/
.header2 {
    padding: 15px 0;
}
.banner-section-lp {
    background: #57ab1a;
    padding: 0;
    color: #fff;
}
.banner-title-lp p {
    font-family: 'Poppins';
    text-align: left;
    font-size: 30px;
    line-height: 1.3;
    padding: 20px 30px 0;
    color: #fff;
}
.hippa-img img {
    width: 90%;-webkit-transform: translate(-30px, 0);transform: translate(-30px, 0);
}
.our-process-left { -webkit-transform: translate(0,0); transform: translate(0,0);}
.green-border-bg p {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
}
.header-bottom {
    display: flex;
    align-items: center;
}
.banner-title-lp h1 {
    font-size: 100px;
    font-family: 'Poppins';
    font-weight: 700;
    margin: 0;
}
.banner-title-lp {
    max-width: 1080px;
    margin: 0 auto; padding: 10px 0;
}
.use-form-section-lp h3 {
    color: #57ab1a;
    font-size: 30px; text-align: center;
    font-family: 'Poppins';
}
.use-form-section-lp h2 {
    font-size: 35px;
    font-weight: 600;
    font-family: 'Poppins'; text-align: center;
    line-height: 43px;color: #54ab1a;
}
.thankyou-cont h2 {
    color: #454545;
}
.thankyou-cont {
    color: #454545; padding-bottom: 1em;
}
.use-form-section-lp h2 span {
    font-weight: 500;
    font-size: 33px;
}
.center-sec {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 4em 0;
    flex-direction: column;
}
.use-it-lp1-form h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 25px 0 0;  line-height: 1;
    text-align: center;color: #454545;
}
.dont-lose-revenue h2 {
    border: 2px solid #57ab1a;
    display: inline-block;
    padding: 6px 25px;
    font-family: 'Poppins';
    font-size: 40px;
    border-radius: 7px; color: #454545;
}
.dont-lose-revenue {
    text-align: center;
    padding: 1em 0 2em;
}
.dont-lose-revenue .free-offer img {
    width: 100%;
    max-width: 1000px;
    margin: 15px 0;
}
.dont-lose-revenue p {
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #454545;
    padding: 0.2em 0;
}
.dont-lose-revenue span.mnth-p {
    font-size: 20px;
    margin-left: 0;
    position: absolute;
    text-align: center!important;
    left: 0;
    right: 0;
    font-family: 'Oxygen';
}
.dont-lose-revenue p.circle {
    background: #54ab1a;
    border-radius: 200px;
    color: #fff!important;
    font-weight: 600;
    width: 200px;
    font-size: 90px;
    border: 8px solid #bfb9b9fa;
    font-family: 'Geomanist';
    padding: 65px 0 0;
    height: 200px;
    vertical-align: middle;
    align-items: center;
    display: inline-block;
    line-height: 32px;
}
.dont-lose-revenue h4 {
    font-family: 'Poppins';
    font-size: 32px;
    color: #454545;
    margin: 0;
}
.dont-lose-revenue h4 span {
    color: #57ab1a;
    font-weight: 700;
}

.dont-lose-revenue h4 img {
    max-width: 85px;
    vertical-align: -30px;
}
.lady-img img {
    max-width: 250px;
    vertical-align: text-top;
    margin: 0 auto;
    display: block;
    -webkit-transform: translate(10px, 77px);
            transform: translate(10px, 77px);
}
.lady-img { position: relative; z-index: 999; }
.use-it-lp1-form {
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding: 15px;
    margin-top: 2em; border-radius: 3px;
}
.use-it-lp1-form input.form-control {
    background: #fff;
    padding: 23px 15px;
    color: #454545;
    font-family: 'Poppins';
    border: 1px solid #ccc6;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.use-it-lp1-form h2 {
    font-size: 21px;
    font-weight: 700;
    font-family: 'Poppins';
    margin: 25px 0 0;
    line-height: 1;
    text-align: center;
    color: #57ab1a;
}
.use-it-lp1-form button.lp-btns {
    background: #ffa844;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 45px;
    margin: 1em auto;
    display: block;
    font-family: 'Poppins'; border-radius: 30px;
    font-weight: 700;
}
.free-offer img {
    max-width: 500px;
    vertical-align: baseline;
    vertical-align: initial;
}
.use-form-section-lp .center-sec h2 { font-size: 40px; color: #000; font-weight: 700;}
/* .use-it-lp1-form input.form-control {
    background: #fff;
    padding: 20px 15px;
    border: 0;
    border-radius: 0;
    color: #000;font-family: 'Poppins';
}
.use-it-lp1-form button.lp-btns {
    background: #ffa844;
    color: #000;
    text-transform: uppercase;
    padding: 12px 45px;
    margin: 2em auto;
    display: block;font-family: 'Poppins';
} */
.use-it-lp1-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #454545;
  }
  .use-it-lp1-form input ::-moz-placeholder { /* Firefox 19+ */
    color: #454545;
  }
  .use-it-lp1-form input:-ms-input-placeholder { /* IE 10+ */
    color: #454545;
  }
  .use-it-lp1-form input :-moz-placeholder { /* Firefox 18- */
    color: #454545;
  }
  .footer-new-section.useit {
   padding: 15px 0; border-top: 0;
    background-color: #454545;
}
/* .use-it-form {
    background: #cacaca;
}
.use-form-section-lp {
    border-bottom: 13px solid #57ab1a;
} */
.right-ban p img {
    display: inline-block;
    max-width: 45px;
    vertical-align: -webkit-baseline-middle;
}
.green-border-bg {
    background: #54ab1a;
    padding: 25px 0;
}
.our-steps img {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 800px;
}
.our-process-gp h2 {
    color: #454545;
    font-size: 45px;
    font-family: 'Roboto';
    margin-bottom: 1em;
}
.our-process-gp {
    background: #f8f8f8;
    padding: 0.5em 0;
}
.our-process-left-right img, .our-process-left-right-2 img {
    width: 100%; -webkit-transform: translate(0, 20px); transform: translate(0, 20px);
}
.our-process-left-right {
    max-width: 1000px;
    margin: 4em auto 1em;
    position: relative;
    padding: 0 20px;display: flex; align-items: center;
    
    
}
.our-process-left-right p {
    color: #54ab1a;
    font-family: 'Roboto';
    text-align: justify;
    font-size: 15px;
}
.our-process-left-right-2 p {
    color: #54ab1a;
    font-family: 'Roboto';
    text-align: justify;
    font-size: 15px;
    text-align-last: right;
}
.our-process-left, .our-process-right-2 {
    padding: 0em 0 0;
}
.our-process-left-right h3, .our-process-left-right-2 h3 {
    color: #454545;
    font-size: 35px;
    font-weight: 500;
    font-family: 'Roboto';
}
.our-process-left-right-2 h3 {
    text-align: right;
}
.our-process-left-right::before {
    position: absolute;
    content: "";
    height: 95%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: 2px solid #54ab1a;
    border-radius: 30px; display: block;
}
.our-process-left-right-2 img {
    width: auto;
    max-width: 695px; -webkit-transform: translate(0, 20px); transform: translate(0, 20px);
}
.green-border-large {
    background: #54ab1a;
    padding: 40px 0;
    margin-top: -7em;
}
.our-process-left-right.bg::before {
    background-color: #f0f0f0;
}
.our-process-left-right.bg .our-process-right {
    float: right;
}
.our-process-left-right.bg .our-process-left p {
    max-width: 460px;
    line-height: 1.5;
    padding: 1em 0 0;
}
.our-process-left-right.bg, .our-process-left-right-2 {
    display: flex;
    align-items: center;
}
.our-process-left h3 img {
    max-width: 127px;
    vertical-align: -15px;
}
.our-process-left-right.bg .our-process-left{ padding: 0; }
.des, .use-desk { display: block;}
.mob, .use-mobile{ display: none;}
.use-it-lp1-form input.form-control:focus {
    border: 1px solid#ccc !important;
}
/*** Responsive Design starts ****/
@media(max-width: 991px){
	.features-group .col-md-6 .col-md-6 img {
    width: auto;
    max-width: 370px;
}
.frontoffice-section img {
    width: 450px; }
    .banner-content-full .right-content {
        padding: 20px 35px;
    }
    .use-desk { display: block;}
    .des { display: none;}
    .mob, .use-mobile { display: block; margin: 0 auto;}
.banner-title-lp h1 {
    font-size: 60px; }
.banner-title-lp p {       
        font-size: 21px;       
        padding: 20px 12px 0;
    }
    .use-desk {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    .lady-img img { -webkit-transform: translate(0,0); transform: translate(0,0);}
.free-offer img {    
       
        margin: 0 auto;
        display: block;
    }
    .green-border-large {
   
        margin-top: 0;
    }
    .our-process-left-right.bg, .our-process-left-right-2 {
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;
    }
    .our-process-left-right-2 img {
        width: 100%; }
    .our-process-left-right h3, .our-process-left-right-2 h3 { font-size: 30px; }


}
@media(max-width: 767px){
.email-content{
width: 100%;
    height: auto;
}
    button.learn-more .button-text { margin: 15px 0 15px 30px }
    button.learn-more .circle-lp { margin: 20px 0 0 0; }
    .testimonial-group .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff;
}
.type-common{
    font-size: 20px;
}

.testimonial-group .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
}
.testimonial-lists p { font-size: 16px; }
.testimonial-group .carousel .control-arrow:before, .testimonial-group .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
}
    .testimonial-lists {
    
    margin: 0; display: block}
    .testimonial-group .carousel.carousel-slider {
    padding: 2em 0em 3em; }
	.dashboard-image img, .features-group .col-md-6 .col-md-6 img {
    
    width: 100%;
	}
	.advantages-group::after { content: none; right: 0; } 
	.features-group h3 {    
    text-align: center;padding: 15px 0 0;
	}
	.features-group .col-md-6 .row {
    padding: 10px 0;
}
.copyrights {
    margin-top: 0;
}
.advantages-lists {
    flex: 1 1 100%; margin: 1em auto; }
    .dentist-issues::before { background-size: 200px; right: 0; }
  .hero-banner::before { content: none; }
  .social-profiles {
    padding: 1em 0;
	}
	.hero-banner::after {
    background-position: center bottom;
    right: 0;
    
    background-size: 300px;
    z-index: -1;
    top: auto;
    bottom: 0;
    
}
.hero-banner {
    
    padding: 3em 0 18em;
    
}
.hero-banner h2, .hero-banner h1{
    font-size: 20px; }
    h2 {
   
    font-size: 20px; }
  .all-issues-lists {
    flex: 1 1 100%;
    padding: 1em 2em 2em; margin: 1em auto; text-align: center;
}
 .all-issues, .features-group p, .all-issues-lists h3{   text-align: center;
    display: block; }
    .dental-business h3 {
    font-size: 20px;width: auto;
}
.form-group .col-md-6, .form-group .col-md-5 { margin-bottom: 1em; }
.get-in-touch .form-group {
    margin-bottom: 0;
}
.get-in-touch form {
    padding: 10px 0px !important;
}
.form-group textarea.form-control {
    margin-bottom: 1em;
    margin-top: 1em;
}
input#example-datepicker {
    width: 100%;
    max-width: 100%;
}
input[type="time"] {
    width: 100%;
    margin-top: 1em;
}
.get-in-touch .react-datepicker-wrapper {    
    width: 100%;
}
.advantages-lists h3 {
   
    min-height: 0; }
input.lp-btns {
    width: 50%; }
.form-group .col-md-1 {
    display: none;
}
.dental-business iframe {
    margin-top: 2em;
    width: 100%;
    float: none;
    height: auto;
}
.banner-buttons a {
   
    margin: 10px auto !important;
}
.get-in-touch::before {
    z-index: -1;
}
.col-md-6.copy {
    display: block;
    
}
.footer-lp img { width: 100% !important; }
button.learn-more {
    width: 100%;
    
    display: block;
}
.banner-buttons { display: block; }
/*** lp new changes **/
.flow-image img {
    width: 100%;
}
.all-issues-lists:nth-child(2), .all-issues-lists:nth-child(4) {   
    display: block;
    padding: 3em;
    margin: 0 auto;
}
.all-issues-lists:nth-child(2n+1) {
    margin: 1em auto;
}
.header-sec.fixed {    
    position: relative; }
.form-banner .form-group {      
        padding-left: 6px;
        padding-right: 6px;
 }
 .wrapper .hero-banner.banner-bg h2, .wrapper h2 {
    
    font-size: 22px;
}
.all-issues-lists {
  
    margin: 1em auto;
    border-radius: 5px;
    max-width: 100%;
   
}
.lpage .ttttt {
    text-align: center; } 
.wrapper .hero-banner.banner-bg .main-content-new h2 {
        font-size: 22px;
      
    }
    .newlpgroup iframe {
        width: 100%;
        height: 220px;
    }
    .col-md-6.left-banner h2, .col-md-6.left-banner h1 {
        font-size: 25px !important;
        text-align: center;
    }
    .lpnew-header, .button-wrapper a, .marketing-strategy p, .lead-generation p, .banner-lp p, .dental-implants .col-md-8 p, .progressive-dental p,  { text-align: center;}
    .button-wrapper {    
        flex-direction: column-reverse;
    }
    .button-wrapper div {
        padding: 10px 0;
    }
    .live-dentists h2, .marketing-strategy h2, .lead-generation h2, .progressive-dental h2, .dental-implants h2, .dental-implants .col-md-8 h3{ text-align: center; font-size: 24px; }
    .form-container-group .banner_form_submit input {   
        width: auto; }
        .dental-implants p, .progressive-dental p, .progressive-dental ul li { font-size: 16px; }
    .banner-lp {
        
        padding: 2em 0; }
        .banner-lp p strong { vertical-align: middle;}
        .banner-lp p, .marketing-strategy .form-container-group h2 { font-size: 17px;}
        .live-dentists .container { margin-top: 0;}
        .lpnew-header a img { display: block; margin: 0 auto;} 
        .dental-implants, .lead-generation {
            padding: 2em 0; }
        .progressive-dental p, .marketing-strategy p, .dental-implants .col-md-8 p, .lead-generation p, .button-wrapper a { text-align: center;}
            .lead-generation.funnel {
                padding-bottom: 0em;
            }
        .marketing-strategy img {
                margin-bottom: 1em;
            }
        .progressive-dental img {               
                margin-bottom: 1em;
            }
        .lpnew-header h2 {
                padding: 10px 0 !important;
            }
            .pricing-section-inner {
                overflow-y: hidden;
                overflow-x: scroll;
                width: 100%; padding: 0 10px;
            }
            .frontoffice-section img { width: 100%; }
            .faq-lists .accordion__button:before { font-size: 44px;
                right: 15px;
                top: 18px; }
            .faq-lists .accordion__button, .faq-lists .accordion__panel p {
                font-size: 17px; }
                .video-contents iframe {
                    width: 100% !important;
                    height: 215px !important; 
                }
                .video-contents img {
                    width: 100%;
                }
                .signup-section h2 {
                    font-size: 20px; }
                    .implantpatient-section h2, .frontoffice-section h2, .frequently-section h2 { font-size: 25px;}
.banner-section, .frequently-section { padding: 2em 0;}
.banner-section .beagi ul li {
    font-size: 13px;
    display: inline;
    padding: 0 8px; }
    .banner-content-full { flex-direction: column; }
    .banner-content-full .right-content {
        padding: 35px 10px;
        text-align: center;
    }
.pricing-section {
       
        padding: 1em 0;
    }
    .pricing-section-inner table td {
        padding: 0 3px;
        min-width: 260px;
        font-size: 16px; }
        .pricing-section-inner table th { font-size: 26px !important; }
        .pricing-section-inner table td a { width: 92%; font-size: 16px; }
        .live-dentists p { font-size: 16px; }
        .implantpatient-section {
            
            padding: 1em 0;
        }
        .banner-section h2 {            
            font-size: 30px; 
        }
      
        .header1 img {
            display: block;
            margin: 0 auto;
        }
        .lp-emblem {
            -webkit-transform: rotate(
        0deg
        );
                    transform: rotate(
        0deg
        ); }
        .banner-section .beagi h1 {
            font-size: 22px !important;
            line-height: 30px;
            }
            .trial {
                padding: 10px 0 0;
            }
            .banner-title { flex-direction: column ;}
            .frequently-section h2 { margin-bottom: 0;}

.pricing-section-inner.desktop {
                display: none;
            }

.pricing-section-inner.mobile {
    display: block;
}
.banner-content-full .left-img img {
    width: 100%;
}
/*** Use it lose it ****/
.our-process-left-right.bg, .our-process-left-right-2 { display: block;}
.lady-img img { margin-top: 1em; -webkit-transform: translate(0,0); transform: translate(0,0); max-width: 150px;}
.banner-title-lp h1 { text-align: center; font-size: 70px;}
.banner-title-lp p { font-size: 16px; text-align: center;}
.center-sec { align-items: center; padding: 1em 2em 0;}
.use-form-section-lp h2, .use-form-section-lp h2 span {
    font-size: 23px; text-align: center; margin: 5px 0;
    line-height: 1.2; }
.use-form-section-lp h3 { font-size: 19px; font-weight: 600; margin: 0; text-align: center; }
.dont-lose-revenue {   
    padding: 1em 0;
}
.dont-lose-revenue h2 { font-size: 30px;}
.header2 {
    padding: 10px 0;
    text-align: center;
}
.use-it-form {
    
    padding: 10px 15px;
}
.our-process-gp h2 {    
    font-size: 35px;    
    margin-bottom: 10px;
    text-align: center;
    margin-top: 0;
}
.our-process-left-right { display: block; margin: 1em auto; text-align: center; }
.our-process-left, .our-process-right-2 {
    padding: 5px 15px 15px; text-align: center;
}
.our-process-left-right-2 img, .our-process-left-right img, .our-process-left-right-2 img {
    width: 100%;
    max-width: 100%; margin: 0 auto;
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
}

.our-process-left-right-2 h3, .our-process-left-right-2 p, .our-process-left-right.bg .our-process-left p, .our-process-left-right p { text-align: center; text-align-last: center; }

.our-process-left-right.bg .our-process-left { padding: 10px 0 0;}
.our-process-left-right h3, .our-process-left-right-2 h3 {    
    font-size: 21px;    
    padding: 16px 0 0;
    display: inline-block;
}
.our-process-gp { padding: 1em 0; }

.dont-lose-revenue h4 {    
    font-size: 17px;    
    margin: 25px 0 0;
    display: inline-block;
}
.use-form-section-lp .center-sec h2 {
    font-size: 30px;
    line-height: 25px;
    padding: 6px 0; }
.hippa-img img {
    width: 66%;-webkit-transform: translate(0, 0);transform: translate(0, 0); display: block; margin: 0 auto;
}
.use-form-section-lp {
    padding: 1em 0 0;
}
.header-bottom {
    display: block;
    
}
.free-offer img {
    max-width: unset;
    vertical-align: baseline;
    vertical-align: initial;
    width: 100%;
}


}

.advantages-lists:hover {
    -webkit-transform: scale(1.1) !important;
            transform: scale(1.1) !important;

}

.Typewriter {
    display: inline;
  }
  
  .Typewriter__wrapper {
    font-weight: 700;
      display: inline-block;
      padding: 5px 10px;
      font-size: 2.5rem;
  }
  .type-common {
    font-weight: 700;
      display: block;
      font-size: 2.5rem;
  }
  
  .Typewriter__cursor {
    font-weight: 700;
      display: inline-block;
      font-size: 2.5rem;
  }

  .circl {
      margin: auto;
    max-width: 85px;
      
  }
  .advantages-lists .img1 {
    max-width: 85px;
    background: url(http://headnurture.com/circle-lp.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 15px;
    position: relative;
    z-index: 99999999999;
}
.advantages-lists .img2 {
    max-width: 85px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 15px;
    position: relative;
    z-index: 99999999999;
}
.testimonial-group .carousel.carousel-slider::before {
    content: "";
    background: url(https://headnurture.com/quotes.png);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-position: left top;
    background-size: 75px;
    background-repeat: no-repeat;
    left: 40px;
    top: 10px;
    pointer-events: none;
}



.report-group .title{
    margin: 20px 0;
}

.report-group .card-title {
    text-align: center;
    
    display: block;
    margin-bottom: 2em;
    margin-top: 1em;
}
.report-group .card-title h3 {
    font-size: 25px;
    font-weight: 300;
    display: inline-block;
    border-bottom: 2px solid #57ab1a;
}
.report-group.report-lists .container {
    max-width: 1140px; box-shadow: unset;
    padding: 15px 2em;
    
}

.report-group.report-lists tr td a {
    font-weight: 600;
    color: blue;
}

.report-group.report-lists .container {
    max-width: 1140px; box-shadow: unset;
    padding: 15px 2em;
    
}
.report-lists .cost-table tr td, .report-lists .cost-table th {
    border: 0px solid #ccc;
}
.report-lists table th {
    background: #57ab1a;
    padding: 3px 10px;
    color: #fff;
}
.report-lists table tr:nth-child(odd) {
    background: #c5dcc57a;
}
.report-lists table tr:nth-child(even) {
    background: #f0f0f0ba;
}
.report-lists .cost-table tr td, .cost-table th {
    border: 0px solid #ccc;
}
.report-lists table td {
    padding: 5px 10px;
}
.report-group.report-lists tr td a {
    font-weight: 600;
    color: blue;
}




.checkbox-report{
    position:relative;
    cursor:pointer;
    -webkit-appearance:none;
            appearance:none;
    display: block !important;
    width:80px;
    height:40px;
    border-radius:20px;
    border:2px solid #ccc;
    outline:none;
    transition:0.3s;
  }
  .checkbox-report::before{
    content:"";
    position:absolute;
    height:20px;
    width:20px;
    border-radius:50%;
    background:#ccc;
    top:3px;
    left:4px;
    transition:0.3s ease-in-out;
  }
  .checkbox-report:checked::before{
    -webkit-transform:translateX(37px);
            transform:translateX(37px);
    background:#3399ff;
  }
  .checkbox-report:checked{
    border-color:#3399ff;
  }


.title-right h1 {
    font-size: 20px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* .title-right span {
    font-size: 40px;
    background: #57ab1a;
      padding: 0 10px;
  } */

  .team-title {
    padding: 0 !important;
    margin: 0 !important;
  }

  .team-title h2 {
    color: #000 !important;
    font-size: 19px !important;
  }

  .team-role, .team-role:focus {
    width: 100% !important;
    padding: 7px 14px !important;
    background: #fff !important;
    border: 1px solid #c1bbbb !important;
    border-radius: 4px !important;
  }

  .team-model .MuiDialogActions-root{
    justify-content: space-between;
  }

  .team-model .MuiDialogActions-root .lp-btns{
    border-radius: 7px !important;
    padding: 5px 28px !important;
  }

  .team-role {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
#email-exists{
    display: none;
    font-size: 13px;
    color: red;
    margin-top: 5px;
    text-align: left;
}
#password-exists, #password-exists-edit{
  display: none;
  font-size: 13px;
  color: red;
  margin-top: 5px;
  text-align: left;
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #57ab1a;
    box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
    color: #fff;
}
.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}
.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}

.custom-ui p {
    color: #fff;
}
.report-options input{
  display: inline-block !important;
    z-index: 0 !important;
    position: relative !important;
    top: 11px;
    width: 18px !important;
    right: 10px;
}

.form-control-option {
    display: block;
    width: 100%;
    height: 19px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.user-form-control:focus {
  border: 1px solid #ccc !important;
}

.phone  input.form-control {
  border-radius: 0px !important;
}


.phone .flag-dropdown, .phone .selected-flag {
  border-radius: 1px !important;
}
.phone .react-tel-input {
  box-shadow: none;
}
.phone input.form-control:focus {
  border: 1px solid #CACACA !important;
}
.phone .react-tel-input .country-list {
  text-align: left;
}
.banner-bg33{
  background: #54ab1a;
  position: relative;
  z-index: 222222;
  max-width: 75%;
  margin: 2.5em auto 0;
}
.uploadCsv button.btn.btn-primary.step-btn.company {
  background: #ffa844 !important;
  color: #fff;
}
.logo{
  width:100px;
}
.social-link{
  width:40px;
  padding:5px;
}
.menu-bar{
  display:flex;
  align-items:center;
}
.social-logo{
  display:flex;
  justify-content:end;
  align-items: center;
}
.banner-heading{
  border-left: 2px solid #fff;
  padding-left: 5px;
  color: #fff;
  font-size: 40px;
  font-weight: 700;
}
.form-bg{
  background-color:transparent;
  width:80%
}
.form-control{
  border-radius: 0px;
}
.form-btn{
  border-radius: 40px;
  padding: 8px 40px 8px 40px;
  background-color: #cccc00;
  border: #cccc00;
  color: #000;
  font-weight: 600;
}
.form-align{
  display: flex;
  justify-content: end;
}
.clr-img{
  position:relative;
  height: 120px;
}
.clearfil{
  width:55%;
  position:absolute;
  top:-130px;
}
.clear-text{
  width: 60%;
  font-size: 20px;
  margin: auto;
  font-weight: 500;
}
.footer-link{
  background-color:#4096d7;
}
.link-icon img{
  width:20px;
}
.link-icon{
  background: #183d69;;
  border-radius: 4px;
  padding: 7px 10px 8px 10px;
}
.link-icon-txt{
  padding-left:10px;
}
.link-icon-txt h6{
  color: #183d69;;
  font-weight: 600;
}
.link-icon-txt p{
  color: #fff;
  font-weight: 600;
}
.foot-content{
  display:flex;
  align-items:center;
  justify-content:center;
}
.footer-2{
  background-color:#000;
}
.form-bg.contact-upload.p-3 {
  width: 100%;
  max-width: 93%;
  margin: 0 auto;
  padding: 5em 0 0;
}
.choose-file {
  text-align: center;
}
.form-bg.contact-upload.p-3 h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}
.form-bg.contact-upload.p-3 p {
  color: #fff;
  font-weight: 600;
}
.form-bg.contact-upload.p-3 .uploadCsv {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 4px;
  border: 1px solid #ccc;margin: 1em 0 0;
}
.choose-file h3 i {
  font-size: 20px;
}


@media only screen and (max-width: 767px) {
  .res-pl{
    display:flex;
    justify-content:center;
  }
  .form-bg{
    width:100%;
  }
  .clear-text{
    width: 100%;
  }
  .foot-content{
    justify-content:start;
  }
  .banner-heading{
    border-left: none;
  }
  .clearfil{
    width:100%;
    position:relative;
    top:0px;
  }
}



.root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadCsv {
  width: 35%;
  margin: auto;
}
#csv-upload {
  margin-top: 20px;
  position: relative; z-index: 99;
  padding: 0px 0;
  position: relative; z-index: 99; display: block;
}
#csv-uploaded {
  position: relative;
  width: 100%;
  text-align: center;
}
#csv-uploaded:hover #file-upload-image{
  opacity: 0.3;
}
#csv-uploaded p:first-child {
  margin-top: 10px;
}
#csv-uploaded p {
  padding: 5px 25px;
}
.choose-file  h3{
   text-align: center;
  text-transform: capitalize;
  color: #454545;
  font-size: 20px;
  padding: 10px 0;
  background: #57ab1a;
  color: #fff;
  font-weight: 500;
  }
  
.upload-info {
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bolder;
}

.css-26l3qy-menu {
  z-index: 99999;
}
.form-control1 .css-1s2u09g-control, .form-control1 .css-1s2u09g-control:hover, .form-control1 .css-1s2u09g-control:focus, .form-control1 .css-1pahdxg-control{
  border-color: transparent !important;
}
